import { Order } from './../../../shared/model/order.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-import-table',
  templateUrl: './order-import-table.component.html',
  styleUrls: ['./order-import-table.component.scss'],
})
export class OrderImportTableComponent implements OnInit {
  @Input() ordersMap: Map<number, Order>;
  @Input() title: string;
  @Input() titleColorStyle: string;
  @Input() isCollapsed: boolean;
  @Input() allowCollapse: boolean;
  @Output() collapseEvent = new EventEmitter<boolean>();

  caretDownIcon = faCaretDown;

  constructor() {}

  ngOnInit() {}

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }
}
