import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import * as Sentry from '@sentry/browser';
import * as orderDetailsActions from '../../actions/order-details.action';
import { LoadOrderDetailsAction, LoadOrderDetailsFailAction, LoadOrderDetailsSuccessAction } from '../../actions/order-details.action';
import { OrderDetailsPopupViewComponent } from '../../components/order-details-popup-view/order-details-popup-view.component';
import { OrderDetailsService } from '../../services/order-details/order-details.service';
import { AppState } from './../../../app/reducers/app.state';

@Injectable()
export class OrderDetailsEffect {
  @Effect()
  loadOrderDetails$: Observable<Action> = this.actions.pipe(
    ofType(orderDetailsActions.LOAD_ORDER_DETAILS),
    withLatestFrom(this.store$),
    switchMap(([action, store]: any[]) => this.getOrderDetail(action, store))
  );

  @Effect({ dispatch: false })
  showOrderDetailsPopup$: Observable<Action> = this.actions.pipe(
    ofType(orderDetailsActions.SHOW_ORDER_DETAIL_POPUP),
    tap((action: orderDetailsActions.ShowOrderDetailPopupAction) => {
      this.mixpanel.trackEvent('navigation', { path: '/orderDetails', orderId: action.payload.order.numericalId });

      const componentRef = this.modalService.open(OrderDetailsPopupViewComponent);
      componentRef.componentInstance.order = action.payload.order;
    })
  );

  constructor(
    private actions: Actions,
    private orderDetailsService: OrderDetailsService,
    private store$: Store<AppState>,
    private modalService: NgbModal,
    private mixpanel: MixpanelService
  ) {}

  /**
   * Retrieves the order details from the backend.
   */
  private getOrderDetail(
    action: LoadOrderDetailsAction,
    state: AppState
  ): Observable<LoadOrderDetailsSuccessAction | LoadOrderDetailsFailAction> {
    return this.orderDetailsService.getOrderDetails(state.company.selectedCompany.posToken, action.payload).pipe(
      timeout(state.company.timeout),
      map((companyData) => new LoadOrderDetailsSuccessAction(companyData)),
      catchError((error) => this.handleError(error))
    );
  }

  /**
   * Handle any errorrs that might happen while fetching the order details from the backend.
   */
  private handleError(error): Observable<LoadOrderDetailsFailAction> {
    if (error.name === 'TimeoutError') {
      console.warn('Timeout error while loading order details', error);
    } else {
      const errorMsg = 'Error while loading order details';
      console.error(errorMsg, error);

      const err: any = new Error(errorMsg);
      err.innerException = error;
      err.error = error.error;
      err.headers = error.headers;
      err.JSON = JSON.stringify(error);
      Sentry.captureException(err);
    }

    return observableOf(new LoadOrderDetailsFailAction(error));
  }
}
