import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '../../../shared/model/address.model';

@Pipe({
  name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
  transform(value: Address, args?: any): any {
    return `${value.street}, ${value.number}, ${value.complement}`;
  }
}
