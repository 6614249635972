import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState, creditRewardsData } from './../../../app/reducers/app.state';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { GetCreditRewardsProductsAction } from './../../actions/loyalty-points.action';
import { CreditRewardsData } from './../../model/credit-rewards-data.model';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loyalty-points-view',
  templateUrl: './loyalty-points-view.component.html',
  styleUrls: ['./loyalty-points-view.component.scss'],
})
export class LoyaltyPointsViewComponent implements OnInit, OnDestroy {
  spinnerIcon = faSpinner;
  onDestroy$: Subject<void>;
  data: CreditRewardsData;
  requestStatus = RequestStatus;
  status: RequestStatus;
  errorMessage: string;

  constructor(private store$: Store<AppState>) {}

  ngOnInit() {
    this.onDestroy$ = new Subject<void>();
    this.data = {
      cardBrands: [],
      paymentMethods: [],
      products: [],
    };

    this.store$
      .select(creditRewardsData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.data = result.data;
        this.status = result.status;
        this.errorMessage = result.errorMsg;
      });

    this.store$.dispatch(new GetCreditRewardsProductsAction());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  retry() {
    this.store$.dispatch(new GetCreditRewardsProductsAction());
  }
}
