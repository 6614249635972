import { Observable, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import * as status from '../../../app/model/status.model';
import * as state from '../../../app/reducers/app.state';
import * as sharedModuleFeature from '../../../shared/state/shared.feature';
import { PrintOrderAction } from '../../../shared/actions/connector-service.action';
import * as orderTypes from '../../../shared/model/order-type.model';
import { ResendOrderReadyPushAction, UpdateOrderStatusAction } from './../../../app/actions/order.action';
import { OrderDetails } from './../../../shared/model/order-details.model';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { PaymentDetailType } from './payement-detail-type';

@Component({
  selector: 'app-order-details-popup',
  templateUrl: './order-details-popup.component.html',
  styleUrls: ['./order-details-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsPopupComponent implements OnInit {
  @Input() orderDetails: OrderDetails;
  showPrintButton$: Observable<boolean>;

  paymentCssClassType: string;

  orderDate: Date;
  orderType: string;

  showFullAddress: boolean;
  showPartialAddress: boolean;
  showOrderExtras: boolean;

  exclamationIcon = faExclamationTriangle;
  checkCircleIcon = faCheckCircle;
  showPrint = false;

  constructor(private store: Store<state.AppState>, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.showPrintButton$ = this.store.select(sharedModuleFeature.isConnectorServiceReady);
    this.showPrint = true;
    this.showAddress();
    this.setOrderDate();
    this.parseOrderType();
    this.setVisibilityOrderExtras();
    this.setPaymentCssClassType(this.orderDetails.payments);
  }

  private setVisibilityOrderExtras(): void {
    this.orderDetails.extras && this.orderDetails.extras.length ? (this.showOrderExtras = true) : (this.showOrderExtras = false);
  }

  private showAddress() {
    this.showFullAddress = false;
    this.showPartialAddress = false;

    switch (this.orderDetails.orderType) {
      case orderTypes.ORDER_TYPE_DELIVERY:
        this.showFullAddress = true;
        break;

      case orderTypes.ORDER_TYPE_CURBSIDE:
      case orderTypes.ORDER_TYPE_ROOM_SERVICE:
      case orderTypes.ORDER_TYPE_TRUCK_STOP:
        this.showPartialAddress = true;
        break;
    }
  }

  private setOrderDate() {
    if (this.orderDetails.readyDatetime) {
      this.orderDate = this.orderDetails.readyDatetime;
    } else if (this.orderDetails.posAcceptedDatetime) {
      this.orderDate = this.orderDetails.posAcceptedDatetime;
    } else {
      // If the posAcceptedDatetime is null (in the case of cancelled orders for example), fallback to creationDatetime
      this.orderDate = this.orderDetails.creationDatetime;
    }
  }

  private parseOrderType() {
    switch (this.orderDetails.orderType) {
      case orderTypes.ORDER_TYPE_COUNTER:
        this.orderType = 'balcão';
        break;

      case orderTypes.ORDER_TYPE_TO_GO:
        this.orderType = 'viagem';
        break;

      case orderTypes.ORDER_TYPE_CURBSIDE:
        this.orderType = 'drive thru';
        break;

      case orderTypes.ORDER_TYPE_TABLE:
        this.orderType = 'mesa';
        break;

      case orderTypes.ORDER_TYPE_DELIVERY:
        this.orderType = 'delivery';
        break;

      case orderTypes.ORDER_TYPE_PAYMENT:
        this.orderType = 'pagamento';
        break;

      case orderTypes.ORDER_TYPE_COUPON:
        this.orderType = 'cupom';
        break;

      case orderTypes.ORDER_TYPE_ROOM_SERVICE:
        this.orderType = 'room service';
        break;

      case orderTypes.ORDER_TYPE_PREPAID_PAYMENT:
        this.orderType = 'pontos pré-pagos';
        break;

      case orderTypes.ORDER_TYPE_TRUCK_STOP:
        this.orderType = 'casa do caminhoneiro';
        break;

      default:
        this.orderType = 'balcão';
        break;
    }
  }

  getMessageByPaymentStatus(payments: PaymentDetailType[]): string {
    return !this.isOfflinePaymentType(payments) ? 'Pagamento realizado:' : 'Pagar na entrega:';
  }

  setPaymentCssClassType(payments: PaymentDetailType[]): void {
    const isOfflinePayment = this.isOfflinePaymentType(payments);

    if (isOfflinePayment) {
      this.paymentCssClassType = 'payment_pending';
      return;
    }

    this.paymentCssClassType = 'payment_completed';
  }

  isOfflinePaymentType(payments: PaymentDetailType[]): boolean {
    const regex = /offline/gi;
    return payments.some((payment) => {
      return regex.test(payment.paymentMethod);
    });
  }

  orderErrorButtonClick() {
    this.store.dispatch(new UpdateOrderStatusAction({ nextStatus: status.STATUS_CUSTOMER_ACTION_NEEDED, order: this.orderDetails }));
    this.activeModal.close();
  }

  orderPrintButtonClick() {
    this.store.dispatch(new PrintOrderAction(this.orderDetails));
  }

  resendOrderReadyPushClick() {
    this.store.dispatch(new ResendOrderReadyPushAction(this.orderDetails.numericalId));
  }
}
