export interface LoyaltyPointsError {
  error: string;
  description: string | LoyaltyPointsErrorDescription;
}

export interface LoyaltyPointsErrorDescription {
  points?: Array<string>;
  managerPassword?: Array<string>;
  customerDocument?: Array<string>;
  posToken?: Array<string>;
  nonFieldErrors?: Array<string>;
}

export function isErrorDescriptionString(arg: string | LoyaltyPointsErrorDescription): arg is string {
  return arg instanceof String;
}
