import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout, withLatestFrom } from 'rxjs/operators';

import { AppState, loyaltyPointsTimeout } from './../../../app/reducers/app.state';
import { companyPosTokenSelector } from '../../../app/reducers/app.feature';
import {
  CREDIT_REWARDS,
  CreditRewardsAction,
  CreditRewardsFailAction,
  CreditRewardsSuccessAction,
  GET_CREDIT_REWARDS_PRODUCTS,
  GetCreditRewardsProductsAction,
  GetCreditRewardsProductsFailAction,
  GetCreditRewardsProductsSuccessAction,
} from './../../actions/loyalty-points.action';
import { CreditRewardsService } from './../../services/credit-rewards/credit-rewards.service';

@Injectable()
export class LoyaltyPointsEffect {
  @Effect()
  getCreditRewardsProductsEffect$: Observable<Action> = this.actions.pipe(
    ofType(GET_CREDIT_REWARDS_PRODUCTS),
    withLatestFrom(this.store$.select(companyPosTokenSelector), this.store$.select(loyaltyPointsTimeout)),
    switchMap(([action, token, timeoutValue]: [GetCreditRewardsProductsAction, string, number]) =>
      this.getCreditRewardsProducts(token, timeoutValue)
    )
  );

  @Effect()
  creditRewardsEffect$: Observable<Action> = this.actions.pipe(
    ofType(CREDIT_REWARDS),
    withLatestFrom(this.store$.select(companyPosTokenSelector), this.store$.select(loyaltyPointsTimeout)),
    switchMap(([action, token, timeoutValue]: [CreditRewardsAction, string, number]) => this.creditRewards(action, token, timeoutValue))
  );

  constructor(private actions: Actions, private store$: Store<AppState>, private service: CreditRewardsService) {}

  private getCreditRewardsProducts(
    token: string,
    timeoutValue: number
  ): Observable<GetCreditRewardsProductsSuccessAction | GetCreditRewardsProductsFailAction> {
    if (!token) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return of(new GetCreditRewardsProductsFailAction(new Error('No company selected')));
    }

    return this.service.getRewardsProducts(token).pipe(
      timeout(timeoutValue),
      map((data) => new GetCreditRewardsProductsSuccessAction(data)),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while trying to get credit rewards products');
          return of(new GetCreditRewardsProductsFailAction(new Error('timeout-error')));
        }

        const errorMsg = 'Error while trying to get credit rewards products.';
        console.error(errorMsg, error);

        const err: any = new Error(errorMsg);
        err.innerException = error;
        err.error = error.type;
        err.headers = error.headers;
        err.JSON = JSON.stringify(error);
        Sentry.captureException(err);

        return of(new GetCreditRewardsProductsFailAction(err));
      })
    );
  }

  private creditRewards(
    action: CreditRewardsAction,
    token: string,
    timeoutValue: number
  ): Observable<CreditRewardsSuccessAction | CreditRewardsFailAction> {
    if (!token) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return of(new CreditRewardsFailAction({ error: 'no-company-token', description: 'Não há uma loja configurada' }));
    }

    return this.service.creditRewards(token, action.payload).pipe(
      timeout(timeoutValue),
      map(() => new CreditRewardsSuccessAction()),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while trying to credit loyalty points');
          return of(
            new CreditRewardsFailAction({ error: 'timeout-error', description: 'Tempo limite esgotado. Por favor tente de novo.' })
          );
        }

        const errorMsg = 'Error while trying to credit loyalty points';
        console.error(errorMsg, error);

        const err: any = new Error(errorMsg);
        err.innerException = error;
        err.error = error.error;
        err.headers = error.headers;
        err.JSON = JSON.stringify(error);
        Sentry.captureException(err);

        return of(new CreditRewardsFailAction(error.error));
      })
    );
  }
}
