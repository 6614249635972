import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { OrderStatus } from 'src/app/model/order-status.model';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit {
  @Input() ordersStatus: OrderStatus[];
  @Input() tableTitle: string;
  @Input() titleColorStyle: string;
  @Input() isCollapsed: boolean;
  @Input() allowCollapse: boolean;
  @Output() collapseEvent = new EventEmitter<boolean>();

  caretDownIcon = faCaretDown;

  constructor() {}

  ngOnInit() {}

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }
}
