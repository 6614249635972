import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Observable, asyncScheduler, timer, of, interval, EMPTY } from 'rxjs';
import { catchError, map, switchMap, timeout, withLatestFrom, takeUntil, filter, takeWhile } from 'rxjs/operators';
import {
  LoadOrderLogisticStatusFail,
  LoadOrderLogisticStatusSuccess,
  LOAD_ORDERS_LOGISTIC_STATUS,
  LoadOrdersLogisticStatus,
} from 'src/app/actions/order-logistic-status.action';
import { Company } from 'src/app/model/company.model';
import { AppState } from 'src/app/reducers/app.state';
import { selectedCompanySelector, orderLogisticsStatusPollingIntervalSelector } from 'src/app/reducers/app.feature';
import { OrderLogisticStatusService } from 'src/app/services/order-logistic-status/order-logistic-status.service';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import { logisticIntegrationAvailable } from 'src/shared/state/shared.feature';
import { getCompanyLogisticDataSuccess } from 'src/shared/actions/company-logistic.action';

@Injectable()
export class OrderLogisticStatusEffects {
  loadOrdersLogisticStatus$ = createEffect(() => ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
    this.actions$.pipe(
      ofType(LOAD_ORDERS_LOGISTIC_STATUS),
      withLatestFrom(this.store$.pipe(select(orderLogisticsStatusPollingIntervalSelector))),
      switchMap(([action, pollingInterval]) =>
        timer(0, pollingInterval, scheduler).pipe(
          withLatestFrom(this.store$.pipe(select(selectedCompanySelector)), this.store$.pipe(select(logisticIntegrationAvailable))),
          takeUntil(stopTimer),
          takeWhile(([timerNum, company, isIntegrationAvailable]) => isIntegrationAvailable === true),
          switchMap(([timerNum, company]) => this.loadOrdersLogisticStatus(pollingInterval, company, scheduler))
        )
      )
    )
  );

  logisticCompanyDataLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyLogisticDataSuccess),
      map(() => new LoadOrdersLogisticStatus())
    )
  );

  constructor(private actions$: Actions, private orderLogisticStatusService: OrderLogisticStatusService, private store$: Store<AppState>) {}

  private loadOrdersLogisticStatus(
    pollingInterval: number,
    company: Company,
    scheduler: AsyncScheduler
  ): Observable<LoadOrderLogisticStatusSuccess | LoadOrderLogisticStatusFail> {
    if (!company?.logisticToken) {
      return of(new LoadOrderLogisticStatusFail(new Error('No company selected')));
    }

    return this.orderLogisticStatusService.getOrdersStatus(company.logisticToken).pipe(
      timeout(pollingInterval, scheduler),
      map((result) => {
        const r = new LoadOrderLogisticStatusSuccess(result);
        return r;
      }),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while loadin logistic status service', error);
        } else {
          console.error('Error loading order logistic status', error);
          Sentry.captureException(error);
        }

        return of(new LoadOrderLogisticStatusFail(error));
      })
    );
  }
}
