import { StaffGetMemberState } from './staff-get-member/staff-get-member.reducer';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company/company.reducer';
import { LoginState } from './login/login.reducer';
import { OrdersState } from './order/order.reducer';
import { RouterStateUrl } from './router/router.reducer';
import { OrdersLogisticStatusState } from './order-logistic-status/order-logistic-status.reducer';
import { formatOrders } from './app-feature.helper';

export const routerFeature = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');
export const ordersFeature = createFeatureSelector<OrdersState>('orders');
export const ordersLogisticStatusFeture = createFeatureSelector<OrdersLogisticStatusState>('ordersLogisticStatus');
export const loginFeature = createFeatureSelector<LoginState>('login');
export const companyFeature = createFeatureSelector<CompanyState>('company');
export const staffGetMemberFeature = createFeatureSelector<StaffGetMemberState>('staffGetMember');

export const routerUrlSelector = createSelector(routerFeature, (state: RouterReducerState<RouterStateUrl>) => {
  if (state) {
    return state.state.url;
  } else {
    return undefined;
  }
});
export const ordersPollingIntervalSelector = createSelector(ordersFeature, (state: OrdersState) => state.pollingInterval);
export const preparingOrders = createSelector(ordersFeature, (state: OrdersState) => state.preparingOrders);

export const preparingOrdersStatus = createSelector(
  ordersFeature,
  ordersLogisticStatusFeture,
  (orderState: OrdersState, logisticState: OrdersLogisticStatusState) =>
    formatOrders(orderState.preparingOrders, logisticState.logisticOrdersStatus, false)
);
export const stoppedOrdersStatusSelector = createSelector(
  ordersFeature,
  ordersLogisticStatusFeture,
  (orderState: OrdersState, logisticState: OrdersLogisticStatusState) =>
    formatOrders(orderState.stoppedOrders, logisticState.logisticOrdersStatus, false)
);
export const readyOrdersStatus = createSelector(
  ordersFeature,
  ordersLogisticStatusFeture,
  (orderState: OrdersState, logisticState: OrdersLogisticStatusState) =>
    formatOrders(orderState.readyOrders, logisticState.logisticOrdersStatus, true)
);
export const doneOrdersStatus = createSelector(
  ordersFeature,
  ordersLogisticStatusFeture,
  (orderState: OrdersState, logisticState: OrdersLogisticStatusState) =>
    formatOrders(orderState.doneOrders, logisticState.logisticOrdersStatus, true)
);
export const cancelledOrdersStatus = createSelector(
  ordersFeature,
  ordersLogisticStatusFeture,
  (orderState: OrdersState, logisticState: OrdersLogisticStatusState) =>
    formatOrders(orderState.cancelledOrders, logisticState.logisticOrdersStatus, false)
);
export const hasNewOrders = createSelector(ordersFeature, (state: OrdersState) => state.hasNewOrders);

export const orderUpdateStatusSelector = createSelector(ordersFeature, (state: OrdersState) => state.orderUpdateStatus);
export const orderUpdateErrorMsgSelector = createSelector(ordersFeature, (state: OrdersState) => state.orderUpdateErrorMsg);
export const orderUpdateTimeout = createSelector(ordersFeature, (state: OrdersState) => state.orderUpdateTimeout);

export const orderLogisticsStatusPollingIntervalSelector = createSelector(
  ordersLogisticStatusFeture,
  (state: OrdersLogisticStatusState) => state.pollingInterval
);

export const loginTokenSelector = createSelector(loginFeature, (state: LoginState) => state.user.token);
export const authenticatedSelector = createSelector(loginFeature, (state: LoginState) => state.authenticated);
export const loginErrorMsg = createSelector(loginFeature, (state: LoginState) => state.errorMsg);
export const isOnyoca = createSelector(loginFeature, (state: LoginState) => state.user.isOnyoca);

export const companyTimeoutSelector = createSelector(companyFeature, (state: CompanyState) => state.timeout);
export const companyListSelector = createSelector(companyFeature, (state: CompanyState) => state.companies);
export const companyErrorMsgSelector = createSelector(companyFeature, (state: CompanyState) => state.errorMsg);
export const companyStatusSelector = createSelector(companyFeature, (state: CompanyState) => state.status);
export const selectedCompanySelector = createSelector(companyFeature, (state: CompanyState) => state.selectedCompany);
export const isSelectedCompanyOnlineSelector = createSelector(companyFeature, (state: CompanyState) => state.isSelectedCompanyOnline);
export const isCompanyOnlinePollingIntervalSelector = createSelector(
  companyFeature,
  (state: CompanyState) => state.checkCompanyOnlinePollingInterval
);
export const companyPosTokenSelector = createSelector(companyFeature, (state: CompanyState) => {
  if (state.selectedCompany) {
    return state.selectedCompany.posToken;
  } else {
    return undefined;
  }
});
export const companyLogisticTokenSelector = createSelector(companyFeature, (state: CompanyState) => state.selectedCompany?.logisticToken);
export const companyNameSelector = createSelector(companyFeature, (state: CompanyState) => {
  if (state.selectedCompany) {
    return state.selectedCompany.name;
  } else {
    return '';
  }
});

export const staffGetMemberTimeoutSelector = createSelector(staffGetMemberFeature, (state: StaffGetMemberState) => state.timeout);
export const staffGetMemberRequestStatusSelector = createSelector(
  staffGetMemberFeature,
  (state: StaffGetMemberState) => state.requestStatus
);
