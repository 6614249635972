import { Action } from '@ngrx/store';

import { OrderDetails } from './../../shared/model/order-details.model';
import { OrderDetailsPopupActionPayload } from './../model/order-details-popup-action-payload.model';

export const SHOW_ORDER_DETAIL_POPUP = 'show-order-detail-popup';
export const LOAD_ORDER_DETAILS = 'load-order-details';
export const LOAD_ORDER_DETAILS_SUCCESS = 'load-order-details-success';
export const LOAD_ORDER_DETAILS_FAIL = 'load-order-details-fail';
export const SET_LOAD_ORDER_DETAILS_TIMEOUT = 'set-load-order-details-timeout';

/**
 * Shows an order detail popup.
 * Payload: Order.
 */
export class ShowOrderDetailPopupAction implements Action {
  readonly type = SHOW_ORDER_DETAIL_POPUP;
  constructor(public payload: OrderDetailsPopupActionPayload) {}
}

/**
 * Loads the details for one particular order.
 * Payload number - order id.
 */
export class LoadOrderDetailsAction implements Action {
  readonly type = LOAD_ORDER_DETAILS;
  constructor(public payload: number) {}
}

/**
 * This action is called when the order details are successfully loaded.
 * Payload OrderDetail - order details.
 */
export class LoadOrderDetailsSuccessAction implements Action {
  readonly type = LOAD_ORDER_DETAILS_SUCCESS;
  constructor(public payload: OrderDetails) {}
}

/**
 * This action is called when there is a problem loading the order details.
 * Payload error
 */
export class LoadOrderDetailsFailAction implements Action {
  readonly type = LOAD_ORDER_DETAILS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * Sets the timeout for the load order details operation.
 * Payload - number - timeout.
 */
export class SetLoadOrderDetailsTimeoutAction implements Action {
  readonly type = SET_LOAD_ORDER_DETAILS_TIMEOUT;
  constructor(public payload: number) {}
}

export type Actions =
  | ShowOrderDetailPopupAction
  | LoadOrderDetailsAction
  | LoadOrderDetailsSuccessAction
  | LoadOrderDetailsFailAction
  | SetLoadOrderDetailsTimeoutAction;
