import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

/**
 * This service retrieves the order data directly from the backend API.
 */
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves data for all orders.
   */
  public getAllOrders(
    authToken: string,
    includeOrderType: string[],
    excludeOrderType: string[],
    acceptNewOrders: boolean
  ): Observable<any> {
    const url = `${environment.apiUrl}/v3/connector/order`;

    const headers = new HttpHeaders({ Authorization: authToken });

    const params: any = {};

    const includeOrderTypeParam = this.createOrderTypeFilterParam(includeOrderType);
    if (includeOrderTypeParam !== '') {
      params.order_type = includeOrderTypeParam;
    }

    const excludeOrderTypeParam = this.createOrderTypeFilterParam(excludeOrderType);
    if (excludeOrderTypeParam !== '') {
      params.ignore_order_type = excludeOrderTypeParam;
    }

    if (acceptNewOrders) {
      params.online = true;
    }

    if (!environment.enableDebugDate) {
      params.latest = environment.latest;
    } else {
      // DEBUG
      const date = new Date('2017-09-10');
      params.since = date.toISOString().substring(0, 19);
    }

    return this.http.get(url, { params, headers }).pipe(map((response: any) => response.data));
  }

  public updateOrderStatus(authToken: string, orderId: number, currentStatus: number, newStatus: number): Observable<any> {
    const url = `${environment.apiUrl}/v3/connector/order/${orderId}`;

    const headers = new HttpHeaders({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });

    const body = {
      status: newStatus,
      currentStatus,
    };

    return this.http.put(url, body, { headers });
  }

  /**
   * Concatenates orderTypes array values separating them by ',' or returns an empty string.
   */
  private createOrderTypeFilterParam(orderTypes: string[]): string {
    let orderTypesParam = '';
    let i: number;

    // Ignore empty arrays.
    if (!orderTypes) {
      return '';
    }

    for (i = 0; i < orderTypes.length; i++) {
      if (orderTypesParam === '') {
        orderTypesParam = orderTypes[i];
      } else {
        orderTypesParam = orderTypesParam + ',' + orderTypes[i];
      }
    }

    return orderTypesParam;
  }
}
