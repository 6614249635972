import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { AppState, filteredUnavailableProductsListSelector } from '../../../app/reducers/app.state';
import { MobileDetectService } from '../../../shared/services/mobile-detect/mobile-detect.service';
import { Product } from '../../model/product.model';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-unavailable-products-list',
  templateUrl: './unavailable-products-list.component.html',
  styleUrls: ['./unavailable-products-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnavailableProductsListComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, { static: true }) scrollComponent: PerfectScrollbarComponent;
  @Input() unavailableProducts: Array<Product>;
  chevronUpIcon = faChevronUp;
  chevronDownIcon = faChevronDown;

  isMobileDevice = false;

  private subscription: Subscription;

  constructor(private store: Store<AppState>, private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.isMobileDevice = this.mobileDetectService.isMobileDevice();

    // Reset when the unavailable products change and update the scrollbar.
    this.subscription = this.store.select(filteredUnavailableProductsListSelector).subscribe(() => {
      this.scrollComponent.directiveRef.update();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  scrollUp(event: Event) {
    event.stopPropagation();

    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y - 350;

    if (newScrollPosition < 0) {
      newScrollPosition = 0;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }

  scrollDown(event: Event) {
    event.stopPropagation();
    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y + 350;

    if (newScrollPosition > geom.h) {
      newScrollPosition = geom.h;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }
}
