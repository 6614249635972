import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared/shared.module';
import { OrderImportTableComponent } from './components/order-import-table/order-import-table.component';
import { OrderImportViewComponent } from './components/order-import-view/order-import-view.component';
import { OrderImportComponent } from './components/order-import/order-import.component';
import { OrderImportEffect } from './effects/order-import/order-import.effect';
import { IntegrationComponent } from './integration.component';
import { orderImportReducer } from './reducers/order-import/order-import.reducer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    PerfectScrollbarModule,
    StoreModule.forFeature('importOrders', orderImportReducer),
    EffectsModule.forFeature([OrderImportEffect]),
    FontAwesomeModule,
  ],
  declarations: [IntegrationComponent, OrderImportComponent, OrderImportTableComponent, OrderImportViewComponent],
  exports: [IntegrationComponent],
})
export class IntegrationModule {}
