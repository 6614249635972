<div class="unavailable-products-component m-2">
  <div class="title-text text-center p-2 mr-3 ml-3">Indisponíveis</div>

  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronUpIcon" size="lg" class="arrow-up-button" (click)="scrollUp($event)"></fa-icon>
  <perfect-scrollbar [class.unavailable-products-list-scrollbar]="!isMobileDevice">
    <div
      class="unavailable-products-list"
      [class.list-padding]="!isMobileDevice"
      [class.unavailable-products-list-height]="!isMobileDevice"
    >
      <div class="product-list">
        <div *ngFor="let product of unavailableProducts" class="product p-2 mr-3 ml-3">
          <app-unavailable-product [product]="product"></app-unavailable-product>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronDownIcon" class="arrow-down-button" (click)="scrollDown($event)"></fa-icon>
</div>
