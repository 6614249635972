import { RequestStatus } from '../../../shared/model/request-status.model';
import { OrderLogisticStatus } from 'src/app/model/order-logistic-status.model';
import { environment } from '../../../environments/environment';
import * as actions from '../../actions/order-logistic-status.action';

export interface OrdersLogisticStatusState {
  logisticOrdersStatus: OrderLogisticStatus;
  pollingInterval: number;
  errorMsg: string;
  status: RequestStatus;
}

const initialState: OrdersLogisticStatusState = {
  logisticOrdersStatus: null,
  pollingInterval: environment.defaultPollingInterval,
  errorMsg: undefined,
  status: undefined,
};

export function orderLogisticStatusReducer(state: OrdersLogisticStatusState = initialState, action: actions.Actions) {
  switch (action.type) {
    case actions.LOAD_ORDERS_LOGISTIC_STATUS:
      return {
        ...state,
        status: RequestStatus.LOADING,
      };

    case actions.LOAD_ORDERS_LOGISTIC_STATUS_SUCCESS:
      return {
        ...state,
        status: RequestStatus.SUCCESS,
        logisticOrdersStatus: action.payload,
      };

    case actions.LOAD_ORDERS_LOGISTIC_STATUS_FAIL:
      return {
        ...state,
        status: RequestStatus.FAIL,
        errorMsg: 'Erro ao obter o status da logística dos pedidos',
      };

    default:
      return state;
  }
}
