<div class="footer container-fluid d-flex justify-content-end align-items-center">
  <div class="p-1 mr-auto footer-text">{{ companyName$ | async }}</div>

  <div class="p-1 footer-text">Internet:</div>
  <div class="p-1">
    <fa-icon id="footer-app-online" [icon]="checkCircleIcon" class="check-circle" [hidden]="!appOnline"></fa-icon>
    <fa-icon id="footer-app-offline" [icon]="exclamationCircleIcon" class="exclamation-circle" [hidden]="appOnline"></fa-icon>
  </div>
  <div id="footer-app-online-offline-text" class="p-1 footer-text online-offline-text">{{ appOnlineOfflineText }}</div>

  <div class="p-1 footer-text">Loja:</div>
  <div class="p-1">
    <fa-icon id="footer-store-online" [icon]="checkCircleIcon" class="check-circle" [hidden]="!storeOnline"></fa-icon>
    <fa-icon id="footer-store-offline" [icon]="exclamationCircleIcon" class="exclamation-circle" [hidden]="storeOnline"></fa-icon>
  </div>
  <div id="footer-store-online-offline-text" class="p-1 footer-text online-offline-text">{{ storeOnlineOfflineText }}</div>
</div>
