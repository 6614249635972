import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  public loginUser(username: string, password: string): Observable<string> {
    const url = `${environment.apiUrl}/v1/connector/login`;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const loginData = {
      email: username,
      password,
    };

    return this.http.post(url, loginData, { headers }).pipe(map((response: any) => response.data));
  }
}
