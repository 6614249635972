import { Action } from '@ngrx/store';
import { OrderLogisticStatus } from '../model/order-logistic-status.model';

export const LOAD_ORDERS_LOGISTIC_STATUS = 'load-order-logistic-status';
export const LOAD_ORDERS_LOGISTIC_STATUS_SUCCESS = 'load-order-logistic-status-success';
export const LOAD_ORDERS_LOGISTIC_STATUS_FAIL = 'load-order-logistic-status-fail';

export class LoadOrdersLogisticStatus implements Action {
  readonly type = LOAD_ORDERS_LOGISTIC_STATUS;
}

export class LoadOrderLogisticStatusSuccess implements Action {
  readonly type = LOAD_ORDERS_LOGISTIC_STATUS_SUCCESS;
  constructor(public payload: OrderLogisticStatus) {}
}

export class LoadOrderLogisticStatusFail implements Action {
  readonly type = LOAD_ORDERS_LOGISTIC_STATUS_FAIL;
  constructor(public error: Error) {}
}

export type Actions = LoadOrdersLogisticStatus | LoadOrderLogisticStatusSuccess | LoadOrderLogisticStatusFail;
