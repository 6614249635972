import { Action } from '@ngrx/store';

export const SEND_CELLPHONE_NUMBER = 'send-cellphone-number';
export const SEND_CELLPHONE_NUMBER_SUCCESS = 'send-cellphone-number-sucess';
export const SEND_CELLPHONE_NUMBER_FAIL = 'send-cellphone-fail';

/**
 * This is called to send a new user's cellphone number to the backend, so that the user can receive
 * a download link of the Onyo app.
 * Payload: phone number - string
 */
export class SendCellphoneNumberAction implements Action {
  readonly type = SEND_CELLPHONE_NUMBER;
  constructor(public payload: string) {}
}

/**
 * This action is called when we sucessfully send the cellphone number to the backend.
 */
export class SendCellphoneNumberSuccessAction implements Action {
  readonly type = SEND_CELLPHONE_NUMBER_SUCCESS;
  constructor() {}
}

/**
 * This action is called when we fail to send the cellphone number to the backend.
 */
export class SendCellphoneNumberFailAction implements Action {
  readonly type = SEND_CELLPHONE_NUMBER_FAIL;
  constructor(public payload: Error) {}
}

export type Actions = SendCellphoneNumberAction | SendCellphoneNumberSuccessAction | SendCellphoneNumberFailAction;
