import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToArray',
})
export class MapToArrayPipe implements PipeTransform {
  transform(value: Map<any, any>, reverse: boolean = false): Array<any> {
    if (reverse) {
      return Array.from(value.values()).reverse();
    }

    return Array.from(value.values());
  }
}
