import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { faCheckSquare, faTimesCircle as regularFaTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCartPlus,
  faGhost,
  faPlusCircle,
  faShippingFast,
  faTimes,
  faTimesCircle,
  faTruckLoading,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import { Action, Store } from '@ngrx/store';
import { LogisticOrder } from 'src/shared/model/logistic-order';
import {
  LOGISTIC_STATUS_CANCELED,
  LOGISTIC_STATUS_CREATED,
  LOGISTIC_STATUS_DELIVERED,
  LOGISTIC_STATUS_DELIVERY_FAILED,
  LOGISTIC_STATUS_EN_ROUTE_TO_DELIVERY,
  LOGISTIC_STATUS_PARTNER_DENIED,
  LOGISTIC_STATUS_UNKNOWN,
  LOGISTIC_STATUS_WAITING_FOR_PICK_UP,
  LOGISTIC_STATUS_SENDING_TO_PARTNER,
  LOGISTIC_STATUS_PARTNER_CREATED,
} from 'src/shared/model/logistic-status';
import * as orderTypes from '../../../shared/model/order-type.model';
import { MobileDetectService } from '../../../shared/services/mobile-detect/mobile-detect.service';
import { UpdateOrderStatusAction } from '../../actions/order.action';
import * as status from '../../model/status.model';
import * as appState from '../../reducers/app.state';
import { ShowOrderDetailPopupAction } from './../../../order-details/actions/order-details.action';
import { OrderDetailsPopupActionPayload } from './../../../order-details/model/order-details-popup-action-payload.model';
import { Order } from './../../../shared/model/order.model';
import { OpenOrderActionPopupAction, ResendOrderReadyPushAction } from './../../actions/order.action';
import { OpenOrderActionPopupModel } from './../../model/open-order-action-popup.model';

/**
 * This component is used to display the data for a single order.
 */
@Component({
  selector: '[appOrder]', // tslint:disable-line component-selector
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderComponent implements OnInit, OnChanges {
  @Input() order: Order;
  @Input() logistic: LogisticOrder;

  plusIcon = faPlusCircle;
  logisticIcon = null;
  logisticIconClass = '';
  tooltipText = '';

  orderDate: Date;
  orderDateDiff: Date;
  orderNumber: string;

  showActionButton: boolean;
  actionButtonText: string;
  action: Action;

  showDateDiff = true;

  constructor(private store: Store<appState.AppState>, private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.setOrderDate();
    this.configureActionButton();

    if (this.order.tableReference && this.order.tableReference !== '') {
      this.orderNumber = `${this.order.displayCode} - M${this.order.tableReference}`;
    } else {
      this.orderNumber = this.order.displayCode;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: no-string-literal
    const value: LogisticOrder = changes['logistic'].currentValue;
    switch (value?.last_status?.status) {
      case LOGISTIC_STATUS_CANCELED:
        this.logisticIcon = regularFaTimesCircle;
        this.logisticIconClass = 'logisticOrderCancelled';
        this.tooltipText = 'Cancelado';
        break;

      case LOGISTIC_STATUS_PARTNER_DENIED:
        this.logisticIcon = faTimes;
        this.logisticIconClass = 'logisticOrderCancelled';
        this.tooltipText = 'Negado';
        break;

      case LOGISTIC_STATUS_DELIVERY_FAILED:
        this.logisticIcon = faTimesCircle;
        this.logisticIconClass = 'logisticOrderCancelled';
        this.tooltipText = 'Falha na Entrega';
        break;

      case LOGISTIC_STATUS_CREATED:
      case LOGISTIC_STATUS_SENDING_TO_PARTNER:
        this.logisticIcon = faCertificate;
        this.logisticIconClass = 'logisticOrderCreating';
        this.tooltipText = 'Criando pedido';
        break;

      case LOGISTIC_STATUS_PARTNER_CREATED:
        this.logisticIcon = faCartPlus;
        this.logisticIconClass = 'logisticOrderCreated';
        this.tooltipText = 'Pedido criado';
        break;

      case LOGISTIC_STATUS_WAITING_FOR_PICK_UP:
        this.logisticIcon = faTruckLoading;
        this.logisticIconClass = 'logisticOrderWatingForPickUp';
        this.tooltipText = 'Aguardando Retirada';
        break;

      case LOGISTIC_STATUS_EN_ROUTE_TO_DELIVERY:
        this.logisticIcon = faShippingFast;
        this.logisticIconClass = 'logisticOrderEnRouteToDelivery';
        this.tooltipText = 'Enviado';
        break;

      case LOGISTIC_STATUS_DELIVERED:
        this.logisticIcon = faCheckSquare;
        this.logisticIconClass = 'logisticOrderDelivered';
        this.tooltipText = 'Entregue';
        break;

      case LOGISTIC_STATUS_UNKNOWN:
        this.logisticIcon = faGhost;
        this.logisticIconClass = '';
        this.tooltipText = 'Desconhecido';
        break;

      default:
        this.logisticIcon = null;
        this.logisticIconClass = '';
    }
  }

  showOrderPopup() {
    const payload = new OrderDetailsPopupActionPayload();
    payload.order = this.order;
    this.store.dispatch(new ShowOrderDetailPopupAction(payload));
  }

  /**
   * Allow the user to open the order details popup by clicking anywhere, if he is in a mobile device.
   */
  tableCellClickHandler() {
    if (this.mobileDetectService.isMobileDevice()) {
      this.showOrderPopup();
    }
  }

  actionButtonClick() {
    this.store.dispatch(this.action);
  }

  private setOrderDate() {
    if (this.order.readyDatetime) {
      this.orderDate = this.order.readyDatetime;
      this.orderDateDiff = this.order.posAcceptedDatetime;
    } else if (this.order.posAcceptedDatetime) {
      this.orderDate = this.order.posAcceptedDatetime;
      this.orderDateDiff = null;
    } else {
      // If the posAcceptedDatetime is null (i.e. cancelled orders), fallback to creationDatetime and don't show the date diff
      this.orderDate = this.order.creationDatetime;
      this.orderDateDiff = null;
      this.showDateDiff = false;
    }
  }

  private configureActionButton() {
    let payload: OpenOrderActionPopupModel;

    switch (this.order.status) {
      case status.STATUS_POS_ACCEPTED:
      case status.STATUS_PREPARING:
        this.showActionButton = true;

        // Delivery, room service and table orders
        if (
          this.order.orderType === orderTypes.ORDER_TYPE_DELIVERY ||
          this.order.orderType === orderTypes.ORDER_TYPE_ROOM_SERVICE ||
          this.order.orderType === orderTypes.ORDER_TYPE_TABLE ||
          this.order.orderType === orderTypes.ORDER_TYPE_TRUCK_STOP
        ) {
          this.actionButtonText = 'Enviado';
          this.action = new UpdateOrderStatusAction({ nextStatus: status.STATUS_DISPATCHED, order: this.order });
        } else {
          // Counter orders
          this.actionButtonText = 'Chamar';

          payload = {
            order: this.order,
            leftButtonText: 'Imprevisto',
            leftButtonAction: new UpdateOrderStatusAction({ nextStatus: status.STATUS_CUSTOMER_ACTION_NEEDED, order: this.order }),
            rightButtonText: 'Tá Pronto',
            rightButtonAction: new UpdateOrderStatusAction({ nextStatus: status.STATUS_READY, order: this.order }),
          };
          this.action = new OpenOrderActionPopupAction(payload);
        }

        break;

      case status.STATUS_DISPATCHED:
      case status.STATUS_READY:
        this.showActionButton = true;
        if (
          this.order.orderType === orderTypes.ORDER_TYPE_DELIVERY ||
          this.order.orderType === orderTypes.ORDER_TYPE_ROOM_SERVICE ||
          this.order.orderType === orderTypes.ORDER_TYPE_TABLE ||
          this.order.orderType === orderTypes.ORDER_TYPE_TRUCK_STOP
        ) {
          this.actionButtonText = 'Entregue';
          this.action = new UpdateOrderStatusAction({ nextStatus: status.STATUS_DELIVERED, order: this.order });
        } else {
          this.actionButtonText = 'Atualizar';

          payload = {
            order: this.order,
            leftButtonText: 'Rechamar',
            leftButtonAction: new ResendOrderReadyPushAction(this.order.numericalId),
            rightButtonText: 'Entregue',
            rightButtonAction: new UpdateOrderStatusAction({ nextStatus: status.STATUS_DELIVERED, order: this.order }),
          };

          this.action = new OpenOrderActionPopupAction(payload);
        }
        break;

      case status.STATUS_PRODUCT_UNAVAILABLE:
      case status.STATUS_CUSTOMER_ACTION_NEEDED:
        this.showActionButton = true;
        this.actionButtonText = 'Preparando';
        this.action = new UpdateOrderStatusAction({ nextStatus: status.STATUS_PREPARING, order: this.order });
        break;

      default:
        this.showActionButton = false;
        this.actionButtonText = '';
        break;
    }
  }
}
