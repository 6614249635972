import { Component, Input, OnInit } from '@angular/core';

import { OrderItem } from './../../../shared/model/order-item.model';

@Component({
  selector: 'app-order-details-popup-item',
  templateUrl: './order-details-popup-item.component.html',
  styleUrls: ['./order-details-popup-item.component.scss'],
})
export class OrderDetailsPopupItemComponent implements OnInit {
  @Input() item: OrderItem;

  productType: string;
  showPoints: boolean;
  price: number;

  constructor() {}

  ngOnInit() {
    this.formatItem();
  }

  private formatItem() {
    if (this.item.product_type === 'choosable') {
      this.productType = 'choosable';
    } else if (this.item.redeem_id) {
      this.productType = 'redeem';
    } else {
      this.productType = 'normal';
    }

    const price = Number(this.item.unit_price);
    if (price === 0) {
      this.price = undefined;
    } else {
      this.price = price;
    }
  }
}
