import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { MobileDetectService } from '../../../shared/services/mobile-detect/mobile-detect.service';
import { ReportData } from '../../model/report-data.model';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportTableComponent implements OnInit {
  @ViewChild(PerfectScrollbarComponent, { static: true }) scrollComponent: PerfectScrollbarComponent;
  @Input() data: ReportData;

  chevronUpIcon = faChevronUp;
  chevronDownIcon = faChevronDown;

  isMobileDevice: boolean;

  constructor(private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.isMobileDevice = this.mobileDetectService.isMobileDevice();
  }

  scrollUp(event: Event) {
    event.stopPropagation();

    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y - 350;

    if (newScrollPosition < 0) {
      newScrollPosition = 0;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }

  scrollDown(event: Event) {
    event.stopPropagation();
    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y + 350;

    if (newScrollPosition > geom.h) {
      newScrollPosition = geom.h;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }
}
