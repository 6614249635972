<div class="loyalty-points p-2">
  <!-- Loading Spinner -->
  <div *ngIf="reqStatus === status.LOADING" class="text-center">
    <fa-icon id="spinner" [icon]="faSpinner" size="3x" [pulse]="true"></fa-icon>
  </div>

  <!-- Loyalty Points Form -->
  <div *ngIf="reqStatus !== status.LOADING" class="loyalty-points-form">
    <form [formGroup]="loyaltyPointsForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="cpf-input">CPF:</label>
        <input
          id="cpf-input"
          type="tel"
          class="form-control"
          formControlName="cpf"
          [ngClass]="{ 'is-invalid': (controls.cpf.dirty || controls.cpf.touched) && controls.cpf.invalid }"
        />
        <div>
          <small class="form-text text-muted">Somente números</small>
        </div>
        <div *ngIf="(controls.cpf.dirty || controls.cpf.touched) && controls.cpf.invalid" class="invalid-feedback">
          <div *ngIf="controls.cpf.errors.required" class="required-error">Esse campo é obrigatório</div>
          <div *ngIf="controls.cpf.errors.invalidCPF" class="invalid-cpf">CPF inválido</div>
        </div>
      </div>

      <div class="form-group">
        <label for="points-select">Pontos:</label>
        <select
          id="points-select"
          class="form-control"
          formControlName="points"
          [ngClass]="{ 'is-invalid': (controls.points.dirty || controls.points.touched) && controls.points.invalid }"
        >
          <option *ngFor="let product of data.products" [ngValue]="product">{{ product.name }}</option>
        </select>
        <div *ngIf="(controls.points.dirty || controls.points.touched) && controls.points.invalid" class="invalid-feedback">
          <div *ngIf="controls.points.errors.required" class="required-points">Esse campo é obrigatório</div>
        </div>
      </div>

      <div class="form-group">
        <label for="payment-methods-select">Meio de Pagamento:</label>
        <select
          id="payment-methods-select"
          class="form-control"
          formControlName="paymentMethods"
          [ngClass]="{
            'is-invalid': (controls.paymentMethods.dirty || controls.paymentMethods.touched) && controls.paymentMethods.invalid
          }"
        >
          <option *ngFor="let payment of data.paymentMethods" [ngValue]="payment.id">{{ payment.name }}</option>
        </select>
        <div
          *ngIf="(controls.paymentMethods.dirty || controls.paymentMethods.touched) && controls.paymentMethods.invalid"
          class="invalid-feedback"
        >
          <div *ngIf="controls.paymentMethods.errors.required" class="required-payment-method">Esse campo é obrigatório</div>
        </div>
      </div>

      <div [hidden]="hideCardBrands" class="form-group card-brand-form-group">
        <label for="card-brands-select">Bandeira do Cartão:</label>
        <select
          id="card-brands-select"
          class="form-control"
          formControlName="cardBrands"
          [ngClass]="{
            'is-invalid': (loyaltyPointsForm.dirty || loyaltyPointsForm.touched) && loyaltyPointsForm.hasError('invalidCardBrand')
          }"
        >
          <option *ngFor="let brand of data.cardBrands" [ngValue]="brand.id">{{ brand.name }}</option>
        </select>
        <div
          *ngIf="(loyaltyPointsForm.dirty || loyaltyPointsForm.touched) && loyaltyPointsForm.hasError('invalidCardBrand')"
          class="invalid-feedback"
        >
          <div *ngIf="loyaltyPointsForm.hasError('invalidCardBrand')" class="invalid-card-brand">
            Bandeira inválida para esse meio de pagamento
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="password-input">Senha do Gerente:</label>
        <input
          id="password-input"
          type="password"
          class="form-control"
          formControlName="password"
          [ngClass]="{ 'is-invalid': (controls.password.dirty || controls.password.touched) && controls.password.invalid }"
        />
        <div *ngIf="(controls.password.dirty || controls.password.touched) && controls.password.invalid" class="invalid-feedback">
          <div *ngIf="controls.password.errors.required" class="required-error">Esse campo é obrigatório</div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-secondary submit-button" type="submit">Enviar</button>
      </div>
    </form>

    <div class="pt-2">
      <div *ngIf="reqStatus === status.FAIL" class="alert-danger result-text">
        {{ reqErrorMessage$ | async }}
      </div>
      <div *ngIf="reqStatus === status.SUCCESS" class="alert-success result-text">
        Compra de pontos realizada com sucesso.
      </div>
    </div>
  </div>
</div>
