import { environment } from '../../../environments/environment';
import * as ordersActions from '../../actions/order.action';
import * as statusGroup from '../../model/status-group.model';
import { Order } from './../../../shared/model/order.model';
import { RequestStatus } from './../../../shared/model/request-status.model';
import difference from 'lodash/difference';

export interface OrdersState {
  preparingOrders: Map<number, Order>; // Status Group 1
  stoppedOrders: Map<number, Order>; // Status Group 2
  readyOrders: Map<number, Order>; // Status Group 3
  doneOrders: Map<number, Order>; // Status Group 4
  cancelledOrders: Map<number, Order>; // Status Group 5
  hasNewOrders: boolean;

  pollingInterval: number;
  errorMsg: string;

  orderUpdateTimeout: number;
  orderUpdateErrorMsg: string;
  orderUpdateStatus: RequestStatus;
}

const initialState: OrdersState = {
  preparingOrders: new Map<number, Order>(),
  stoppedOrders: new Map<number, Order>(),
  readyOrders: new Map<number, Order>(),
  doneOrders: new Map<number, Order>(),
  cancelledOrders: new Map<number, Order>(),
  hasNewOrders: false,

  pollingInterval: environment.defaultPollingInterval,
  errorMsg: undefined,

  orderUpdateTimeout: environment.defaultTimeout,
  orderUpdateErrorMsg: undefined,
  orderUpdateStatus: undefined,
};

export function ordersReducer(state: OrdersState = initialState, action: ordersActions.Actions): OrdersState {
  switch (action.type) {
    case ordersActions.LOAD_ALL_ORDERS:
      return state;

    case ordersActions.LOAD_ALL_ORDERS_COMPLETE:
      const hasNewOrders = handleChange(state.preparingOrders, action.payload[statusGroup.PREPARING].items);
      handleChange(state.stoppedOrders, action.payload[statusGroup.STOPPED].items);
      handleChange(state.readyOrders, action.payload[statusGroup.READY].items);
      handleChange(state.doneOrders, action.payload[statusGroup.DONE].items);
      handleChange(state.cancelledOrders, action.payload[statusGroup.CANCELLED].items);
      return {
        ...state,
        hasNewOrders,
        errorMsg: '',
      };

    case ordersActions.LOAD_ALL_ORDERS_FAIL:
      return Object.assign({}, state, {
        errorMsg: 'Erro ao obter os pedidos.',
      });

    case ordersActions.UPDATE_ORDER_STATUS:
      return Object.assign({}, state, {
        orderUpdateErrorMsg: undefined,
        orderUpdateStatus: RequestStatus.LOADING,
      });

    case ordersActions.UPDATE_ORDER_STATUS_SUCCESS:
      if (statusGroup.PREPARING_ORDERS.has(action.payload.status)) {
        handleOrderStatusChange(action.payload, state.preparingOrders, state.stoppedOrders);
      } else if (statusGroup.STOPPED_ORDERS.has(action.payload.status)) {
        handleOrderStatusChange(action.payload, state.stoppedOrders, state.preparingOrders);
      } else if (statusGroup.READY_ORDERS.has(action.payload.status)) {
        handleOrderStatusChange(action.payload, state.readyOrders, state.preparingOrders);
      } else if (statusGroup.DONE_ORDERS.has(action.payload.status)) {
        handleOrderStatusChange(action.payload, state.doneOrders, state.readyOrders);
      }

      return {
        ...state,
        orderUpdateStatus: RequestStatus.SUCCESS,
        orderUpdateErrorMsg: '',
      };

    case ordersActions.UPDATE_ORDER_STATUS_FAIL:
      // Create a user friendly error message.
      let errorMsg: string;
      if (action.payload.message.includes('order.invalid-current-status')) {
        errorMsg = 'O pedido está com um status diferente do atual.\nAguarde o carregamento do novo status.';
      } else if (action.payload.message.includes('timeout')) {
        errorMsg = 'Tempo limite para atualização do pedido atingido.\nPor favor tente novamente.';
      } else {
        errorMsg = 'Não foi possível atualizar o pedido.\nPor favor tente novamente.';
      }

      return Object.assign({}, state, {
        orderUpdateErrorMsg: errorMsg,
        orderUpdateStatus: RequestStatus.FAIL,
      });

    case ordersActions.SET_POLLING_INTERVAL:
      return Object.assign({}, state, {
        pollingInterval: action.payload,
      });

    default:
      return state;
  }
}

function handleChange(orders: Map<number, Order>, data: any) {
  const dataMap = new Map<number, Order>(data);
  let hasNewOrders = false;

  const valuesToAdd = difference(Array.from(dataMap.keys()), Array.from(orders.keys()));
  for (const key of valuesToAdd) {
    orders.set(key, dataMap.get(key));
    hasNewOrders = true;
  }

  const valuesToRemove = difference(Array.from(orders.keys()), Array.from(dataMap.keys()));
  for (const key of valuesToRemove) {
    orders.delete(key);
  }

  return hasNewOrders;
}

function handleOrderStatusChange(order: Order, newStatusMap: Map<number, Order>, oldStatusMap: Map<number, Order>) {
  newStatusMap.set(order.numericalId, order);
  oldStatusMap.delete(order.numericalId);
}
