import { environment } from '../../../environments/environment';
import { RequestStatus } from './../../../shared/model/request-status.model';
import * as staffGetMemberActions from '../../actions/staff-get-member.action';

export interface StaffGetMemberState {
  requestStatus: RequestStatus;
  timeout: number;
}

const initialState: StaffGetMemberState = {
  requestStatus: undefined,
  timeout: environment.defaultTimeout,
};

export function staffGetMemberReducer(
  state: StaffGetMemberState = initialState,
  action: staffGetMemberActions.Actions
): StaffGetMemberState {
  switch (action.type) {
    case staffGetMemberActions.SEND_CELLPHONE_NUMBER:
      return Object.assign({}, state, { requestStatus: RequestStatus.LOADING });

    case staffGetMemberActions.SEND_CELLPHONE_NUMBER_SUCCESS:
      return Object.assign({}, state, { requestStatus: RequestStatus.SUCCESS });

    case staffGetMemberActions.SEND_CELLPHONE_NUMBER_FAIL:
      return Object.assign({}, state, { requestStatus: RequestStatus.FAIL });

    default:
      return state;
  }
}
