import { of as observableOf, Observable } from 'rxjs';
import { catchError, map, timeout, withLatestFrom, switchMap, concatMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as appState from '../../../app/reducers/app.state';
import * as routerFeatureSelector from '../../../app/reducers/app.feature';
import * as Sentry from '@sentry/browser';
import { AppState } from '../../../app/reducers/app.state';
import {
  CHANGE_PRODUCTS_AVAILABILITY_FAIL,
  CHANGE_PRODUCTS_AVAILABILITY_SUCCESS,
  ChangeProductsAvailabilityFailAction,
  ChangeProductsAvailabilitySuccessAction,
  LOAD_PRODUCTS,
  LoadProductsAction,
  LoadProductsFailAction,
  LoadProductsSuccessAction,
  SET_PRODUCT_AVAILABLE,
  SET_PRODUCT_UNAVAILABLE,
  SetProductAvailableAction,
  SetProductUnavailableAction,
} from '../../actions/unavailable-product.action';
import { ProductAvailability } from '../../model/product-availability.model';
import { Product } from '../../model/product.model';
import { ProductListService } from '../../services/product-list/product-list.service';

@Injectable()
export class UnavailableProductEffect {
  @Effect()
  loadProductList$: Observable<Action> = this.actions.pipe(
    ofType(LOAD_PRODUCTS),
    withLatestFrom(
      this.store$.select(routerFeatureSelector.companyPosTokenSelector),
      this.store$.select(appState.unavailableProductsTimeoutSelector)
    ),
    switchMap(([action, token, timeoutValue]: [LoadProductsAction, string, number]) => this.loadProducts(action, token, timeoutValue))
  );

  @Effect()
  setProductUnavailable$: Observable<Action> = this.actions.pipe(
    ofType(SET_PRODUCT_UNAVAILABLE),
    withLatestFrom(
      this.store$.select(routerFeatureSelector.companyPosTokenSelector),
      this.store$.select(appState.unavailableProductsTimeoutSelector),
      this.store$.select(appState.availableProductsListSelector)
    ),
    concatMap(([action, token, timeoutValue, availableProducts]: any[]) =>
      this.changeProductAvailability(action, token, timeoutValue, availableProducts, true)
    )
  );

  @Effect()
  setProductAvailable$: Observable<Action> = this.actions.pipe(
    ofType(SET_PRODUCT_AVAILABLE),
    withLatestFrom(
      this.store$.select(routerFeatureSelector.companyPosTokenSelector),
      this.store$.select(appState.unavailableProductsTimeoutSelector),
      this.store$.select(appState.unavailableProductsListSelector)
    ),
    concatMap(([action, token, timeouValue, unavailableProducts]: any[]) =>
      this.changeProductAvailability(action, token, timeouValue, unavailableProducts, false)
    )
  );

  @Effect({ dispatch: false })
  changeProductAvailabilitySuccess$: Observable<Action> = this.actions.pipe(
    ofType(CHANGE_PRODUCTS_AVAILABILITY_SUCCESS),
    tap((action: ChangeProductsAvailabilitySuccessAction) => {
      this.toastr.success('Os produtos tiveram a sua disponibilidade alterada com sucesso.');
    })
  );

  @Effect({ dispatch: false })
  changeProductAvailabilityFail$: Observable<Action> = this.actions.pipe(
    ofType(CHANGE_PRODUCTS_AVAILABILITY_FAIL),
    tap((action: ChangeProductsAvailabilityFailAction) => {
      this.toastr.error('Erro ao alterar a disponibilidade dos produtos.');
    })
  );

  constructor(
    private actions: Actions,
    private productListService: ProductListService,
    private store$: Store<AppState>,
    private toastr: ToastrService
  ) {}

  /**
   * Loads all of the available products for a given company from the API.
   */
  private loadProducts(
    action: LoadProductsAction,
    token: string,
    timeoutValue: number
  ): Observable<LoadProductsSuccessAction | LoadProductsFailAction> {
    if (token) {
      return this.productListService.getProductList(token).pipe(
        timeout(timeoutValue),
        map((productData) => new LoadProductsSuccessAction(productData)),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.warn('Timeout error while loading the product list', error);
          } else {
            const errorMsg = 'Error while loading the product list';
            console.error(errorMsg, error);

            const err: any = new Error(errorMsg);
            err.innerException = error;
            err.error = error.error;
            err.headers = error.headers;
            err.JSON = JSON.stringify(error);
            Sentry.captureException(err);
          }

          return observableOf(new LoadProductsFailAction(error));
        })
      );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new LoadProductsFailAction(new Error(errorMsg)));
    }
  }

  /**
   * Changes the availability of a given product and all other products that have the same name.
   */
  private changeProductAvailability(
    action: SetProductUnavailableAction | SetProductAvailableAction,
    token: string,
    timeoutValue: number,
    availableProducts: Map<number, Product>,
    availability: boolean
  ): Observable<ChangeProductsAvailabilitySuccessAction | ChangeProductsAvailabilityFailAction> {
    let productAvailability: ProductAvailability;
    const productAvailabilityArray = new Array<ProductAvailability>();

    if (!token) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new ChangeProductsAvailabilityFailAction(new Error(errorMsg)));
    }

    // Change the availability of the current product
    productAvailability = new ProductAvailability(action.payload);
    productAvailability.unavailable = availability;
    productAvailabilityArray.push(productAvailability);

    // Retrieve products with similar name and add change their availability also.
    const similarProducts = this.getProductsWithSimilarNames(action.payload, availableProducts);
    for (const product of similarProducts) {
      productAvailability = new ProductAvailability(product);
      productAvailability.unavailable = availability;
      productAvailabilityArray.push(productAvailability);
    }

    // Change the availability.
    return this.productListService.setProductAvailability(token, productAvailabilityArray).pipe(
      timeout(timeoutValue),
      map((result: ProductAvailability[]) => new ChangeProductsAvailabilitySuccessAction(result)),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while changing a product availability', error);
        } else {
          const errorMsg = 'Error while changing a product availability';
          console.error(errorMsg, error);

          const err: any = new Error(errorMsg);
          err.innerException = error;
          err.error = error.error;
          err.headers = error.headers;
          err.JSON = JSON.stringify(error);
          Sentry.captureException(err);
        }

        return observableOf(new ChangeProductsAvailabilityFailAction(error));
      })
    );
  }

  /**
   * Iterates overt the product map to find any products that have the same name.
   */
  private getProductsWithSimilarNames(product: Product, productMap: Map<number, Product>): Product[] {
    const productArray = new Array<Product>();

    for (const c of Array.from(productMap.values())) {
      if (c.name.toLowerCase() === product.name.toLocaleLowerCase() && c.numericalId !== product.numericalId) {
        productArray.push(c);
      }
    }

    return productArray;
  }
}
