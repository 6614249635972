export const environment = {
  version: require('../../package.json').version,
  production: true,
  apiUrl: 'https://api.onyo.com',
  logisticApiUrl: 'https://lgs.onyo.com',
  ravenUrl: 'https://21e97707f0d0410a8e6afed5c47b9519@sentry.monitor.onyo.com/11',
  connectorWebsocketUrl: 'wss://localhost:12125/api',
  connectorFallbackWebsocketUrl: 'ws://localhost:12126/api-fallback',
  mixpanelUrl: 'https://api.mixpanel.com',
  mixpanelToken: 'c4b77075024606c51cc0a1aae3c4e863',

  defaultTimeout: 60000,
  defaultPollingInterval: 20000,
  latest: '8h',

  enableRouteTracing: false,
  enableDebugDate: false,
  neopboUrl: 'https://chat.b1app.com.br/?organization=e2f403f9-5699-405b-b4bb-e26ef7888ce7',

  features: {
    importProducts: true,
    unavailableProducts: true,
    printOrder: true,
    printReport: true,
  },
};
