import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of as observableOf, timer as observableTimer, EMPTY, of, asyncScheduler } from 'rxjs';
import { catchError, map, switchMap, timeout, withLatestFrom, tap, filter, takeUntil } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import {
  GET_COMPANIES,
  GetCompaniesAction,
  GetCompaniesFailAction,
  GetCompaniesSuccessAction,
  IS_COMPANY_ONLINE,
  IsCompanyOnlineAction,
  IsCompanyOnlineFailAction,
  IsCompanyOnlineSuccessAction,
  LOAD_COMPANY_FROM_COOKIE,
  LoadCompanyFromCookieAction,
  LoadCompanyFromCookieFailAction,
  LoadCompanyFromCookieSuccessAction,
  SELECT_COMPANY,
  SELECT_ONLY_COMPANY,
  LOAD_COMPANY_FROM_COOKIE_SUCCESS,
  UPDATE_COMPANY_CONFIG,
  UpdateCompanyConfigSuccess,
  UpdateCompanyConfigFail,
  UpdateCompanyConfig,
  UPDATE_COMPANY_CONFIG_SUCCESS,
  LOAD_COMPANY_FROM_CONNECTOR_SERVICE,
} from '../../actions/company.action';
import { LoadAllOrdersAction } from '../../actions/order.action';
import { Company } from '../../model/company.model';
import { COMPANY_COOKIE } from '../../model/cookies.model';
import * as features from '../../reducers/app.feature';
import { AppState } from '../../reducers/app.state';
import { CompanyService } from '../../services/company/company.service';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionPopupComponent } from '../../components/interaction-popup/interaction-popup.component';
import { getCompanyLogisticData } from 'src/shared/actions/company-logistic.action';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';

@Injectable()
export class CompanyEffect {
  getCompanies$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) =>
    this.actions.pipe(
      ofType(GET_COMPANIES),
      withLatestFrom(this.store),
      switchMap(([action, appState]: any[]) => this.getCompaniesAction(action, appState, scheduler))
    )
  );

  storeCompanyInCookie$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SELECT_COMPANY, SELECT_ONLY_COMPANY, UPDATE_COMPANY_CONFIG_SUCCESS),
      withLatestFrom(this.store.select(features.selectedCompanySelector)),
      map(([action, selectedCompany]: [Action, Company]) => {
        this.storeCompanyInCookie(selectedCompany);
        return new LoadAllOrdersAction();
      })
    )
  );

  loadCompanyFromCookie$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_COMPANY_FROM_COOKIE),
      switchMap((action: LoadCompanyFromCookieAction) => this.loadCompanyFromCookie(action))
    )
  );

  isCompanyOnline$ = createEffect(() => ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
    this.actions.pipe(
      ofType(IS_COMPANY_ONLINE),
      withLatestFrom(this.store.select(features.isCompanyOnlinePollingIntervalSelector)),
      switchMap(([action, pollingInterval]: any[]) => this.checkCompanyOnline(action, pollingInterval, scheduler, stopTimer))
    )
  );

  // Show a popup to force the user to interact with the page and enable the auto-play (https://goo.gl/xX8pDD)
  showInteractionPopup$: Observable<[Action, Company]> = createEffect(
    () =>
      this.actions.pipe(
        ofType(SELECT_COMPANY, SELECT_ONLY_COMPANY, LOAD_COMPANY_FROM_COOKIE_SUCCESS),
        withLatestFrom(this.store.select(features.selectedCompanySelector)),
        filter(([action, company]: [Action, Company]) => company.acceptNewOrders),
        tap(([action, company]: [Action, Company]) => {
          this.modalService.open(InteractionPopupComponent, { backdrop: 'static' });
        })
      ),
    {
      dispatch: false,
    }
  );

  updateCompanyConfig$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) =>
    this.actions.pipe(
      ofType(LOAD_COMPANY_FROM_COOKIE_SUCCESS),
      withLatestFrom(this.store.pipe(select(features.selectedCompanySelector)), this.store.pipe(select(features.companyTimeoutSelector))),
      filter(([action, company, timeoutValue]) => company?.posToken && company.posToken !== ''),
      switchMap(([action, company, timeoutValue]) =>
        this.companyService.getConfig(company.posToken, company.numericalId).pipe(
          timeout(timeoutValue, scheduler),
          map((result) => new UpdateCompanyConfigSuccess(result)),
          catchError((err) => {
            if (err.name !== 'TimeoutError') {
              console.error('Error updating company information', err);
              Sentry.captureException(err);
            } else {
              console.warn('Timeout while updating company information');
            }

            return of(new UpdateCompanyConfigFail(err));
          })
        )
      )
    )
  );

  loadCompanyLogisticConfig$ = createEffect(() =>
    this.actions.pipe(
      ofType(SELECT_COMPANY, SELECT_ONLY_COMPANY, LOAD_COMPANY_FROM_COOKIE_SUCCESS, LOAD_COMPANY_FROM_CONNECTOR_SERVICE),
      map(() => getCompanyLogisticData())
    )
  );

  constructor(
    private actions: Actions,
    private companyService: CompanyService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private mixpanel: MixpanelService,
    private modalService: NgbModal
  ) {}

  /**
   * Retrieves all available companies from the backend.
   */
  private getCompaniesAction(
    action: GetCompaniesAction,
    appState: AppState,
    scheduler: AsyncScheduler
  ): Observable<GetCompaniesSuccessAction | GetCompaniesFailAction> {
    return this.companyService.getCompanies(appState.login.user.token).pipe(
      timeout(appState.company.timeout, scheduler),
      map((companyData) => {
        return new GetCompaniesSuccessAction(companyData);
      }),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout when retrieving companies', error);
        } else {
          const errorMsg = 'Error while retrieving companies';
          console.error(errorMsg, error);
          Sentry.captureException(error);
        }

        return observableOf(new GetCompaniesFailAction(error));
      })
    );
  }

  private checkCompanyOnline(
    action: IsCompanyOnlineAction,
    pollingInterval: number,
    scheduler: AsyncScheduler,
    stopTimer: Observable<any>
  ): Observable<IsCompanyOnlineSuccessAction | IsCompanyOnlineFailAction> {
    return observableTimer(0, pollingInterval, scheduler).pipe(
      takeUntil(stopTimer),
      withLatestFrom(this.store.select(features.selectedCompanySelector)),
      switchMap(([timerNumber, selectedCompany]: [number, Company]) => {
        if (!selectedCompany) {
          return observableOf(new IsCompanyOnlineFailAction(new Error('No company selected')));
        }

        return this.companyService.isOnline(selectedCompany.numericalId).pipe(
          timeout(pollingInterval, scheduler),
          map((isOnline: boolean) => {
            return new IsCompanyOnlineSuccessAction(isOnline);
          }),
          catchError((error) => {
            if (error.name === 'TimeoutError') {
              console.warn('Timeout when checking if company is online', error);
            } else {
              const errorMsg = 'Error while checking if company is online';
              console.error(errorMsg, error);
              Sentry.captureException(error);
            }

            return observableOf(new IsCompanyOnlineFailAction(error));
          })
        );
      })
    );
  }

  /**
   * Stores the selected company in a cookie.
   * Note the cookie is stored as a JSON object encoded in BASE 64.
   */
  private storeCompanyInCookie(company: Company) {
    const cookie = btoa(JSON.stringify(company));
    this.cookieService.set(COMPANY_COOKIE, cookie, new Date('2038-01-01'));

    Sentry.configureScope((scope) => {
      scope.setUser({ id: company.numericalId.toString(), name: company.name });
    });
    this.mixpanel.setCompany(company);
  }

  /**
   * Tries to retrieve the last selected company from a cookie. If successfull returns a
   * LoadCompanyFromCookieSuccessAction, otherwise returns a LoadCompanyFromCookieFailAction.
   */
  private loadCompanyFromCookie(
    action: LoadCompanyFromCookieAction
  ): Observable<LoadCompanyFromCookieSuccessAction | LoadCompanyFromCookieFailAction> {
    const cookieExists = this.cookieService.check(COMPANY_COOKIE);
    if (cookieExists) {
      const cookie = this.cookieService.get(COMPANY_COOKIE);
      const company: Company = JSON.parse(atob(cookie));

      Sentry.configureScope((scope) => {
        scope.setUser({ id: company.numericalId.toString(), name: company.name });
      });
      this.mixpanel.setCompany(company);

      return observableOf(new LoadCompanyFromCookieSuccessAction(company));
    } else {
      const errorMsg = `The cookie: ${COMPANY_COOKIE} does not exist`;

      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'cookie',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new LoadCompanyFromCookieFailAction(new Error(errorMsg)));
    }
  }
}
