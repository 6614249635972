import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store } from '@ngrx/store';

import { Order } from '../../../shared/model/order.model';
import { AppState } from '../../reducers/app.state';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-action-popup',
  templateUrl: './order-action-popup.component.html',
  styleUrls: ['./order-action-popup.component.scss'],
})
export class OrderActionPopupComponent implements OnInit {
  timesIcon = faTimesCircle;

  order: Order;
  leftButtonText: string;
  leftButtonAction: Action;
  rightButtonText: string;
  rightButtonAction: Action;

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {}

  ngOnInit() {}

  onPrimaryButtonClick() {
    this.store.dispatch(this.leftButtonAction);
    this.activeModal.close();
  }

  onSecondaryButtonClick() {
    this.store.dispatch(this.rightButtonAction);
    this.activeModal.close();
  }
}
