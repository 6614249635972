import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves the detail data for 1 specific order.
   */
  public getOrderDetails(authToken: string, orderId: number): Observable<any> {
    const url = `${environment.apiUrl}/v3/connector/order/${orderId}`;

    const headers = new HttpHeaders({ Authorization: authToken });

    return this.http.get(url, { headers });
  }
}
