import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import * as state from '../../reducers/app.state';
import * as routerFeatureSelector from '../../reducers/app.feature';
import * as sharedModuleFeature from '../../../shared/state/shared.feature';
import { COMPANY_NO_INTEGRATION } from './../../model/company-settings.model';
import { Company } from './../../model/company.model';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  barsIcon = faBars;

  connectorServiceAvailable$: Observable<boolean>;

  urlStateSubscription: Subscription;
  companySubscription: Subscription;

  ordersLinkActive = false;
  importLinkActive = false;
  unavailableLinkActive = false;
  loyaltyPointsLinkActive = false;
  configurationLinkActive = false;
  reportLinkActive = false;

  show = false;

  displayConfigLink: boolean;
  displayImportLink: boolean;
  displayUnavailableLink: boolean;
  displayLoyaltyPointsLink: boolean;

  constructor(private store: Store<state.AppState>, private mixpanel: MixpanelService) {}

  ngOnInit() {
    this.connectorServiceAvailable$ = this.store.select(sharedModuleFeature.isConnectorServiceReady);
    this.displayImportLink = false;
    this.displayUnavailableLink = environment.features.unavailableProducts;
    this.displayLoyaltyPointsLink = false;

    this.urlStateSubscription = this.store.select(routerFeatureSelector.routerUrlSelector).subscribe(this.setNavbarActiveLink.bind(this));

    this.companySubscription = this.store.select(routerFeatureSelector.selectedCompanySelector).subscribe((company: Company) => {
      if (company && company.settings) {
        this.displayImportLink = environment.features.importProducts && company.settings.integration !== COMPANY_NO_INTEGRATION;
        this.displayLoyaltyPointsLink = company.settings.allowCreditRewards;
      } else {
        this.displayImportLink = false;
        this.displayLoyaltyPointsLink = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.urlStateSubscription) {
      this.urlStateSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  collapse() {
    this.show = false;
  }

  private setNavbarActiveLink(url: string) {
    this.mixpanel.trackEvent('navigation', { path: url });

    this.setAllLinksAsInactive();
    switch (url) {
      case '/login':
      case '/configuration':
        this.configurationLinkActive = true;
        break;

      case '/orders':
        this.ordersLinkActive = true;
        break;

      case '/integration':
        this.importLinkActive = true;
        break;

      case '/loyalty-points':
        this.loyaltyPointsLinkActive = true;
        break;

      case '/unavailable-products':
        this.unavailableLinkActive = true;
        break;

      case '/report':
        this.reportLinkActive = true;
        break;
    }
  }

  private setAllLinksAsInactive() {
    this.ordersLinkActive = false;
    this.importLinkActive = false;
    this.unavailableLinkActive = false;
    this.loyaltyPointsLinkActive = false;
    this.configurationLinkActive = false;
    this.reportLinkActive = false;
  }
}
