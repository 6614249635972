import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrderLogisticStatus } from '../../model/order-logistic-status.model';

@Injectable({
  providedIn: 'root',
})
export class OrderLogisticStatusService {
  constructor(private http: HttpClient) {}

  public getOrdersStatus(authToken: string): Observable<OrderLogisticStatus> {
    const url = `${environment.logisticApiUrl}/api/v1/orders/status`;
    const params = {
      last_hours: environment.latest.replace('h', ''),
      group_by: 'onyo_id',
    };

    const headers = new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    });

    return this.http.get(url, { params, headers }).pipe(map((response: OrderLogisticStatus) => response));
  }
}
