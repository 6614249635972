import { Order } from './order.model';
import { ReportPayment } from './../../report/model/report-payment.model';

export enum AstiMsgTypes {
  LOAD_COMPANY_CONFIG = 'asti-msg-load-company-config',
  PRINT_ORDER = 'asti-msg-print-order',
  PRINT_REPORT = 'asti-msg-print-report',
  REIMPORT_ORDER = 'asti-msg-reimport-order',
  SHOW_TOAST_SUCCESS = 'asti-msg-show-toastr-success',
  SHOW_TOAST_ERROR = 'asti-msg-show-toastr-error',
  SHOW_TOAST_WARN = 'asti-msg-show-toastr-warn',
}

export interface AstiMsg {
  type: AstiMsgTypes;
  payload?: any;
}

export interface AstiPrintOrderMSg extends AstiMsg {
  payload: Order;
}

export interface AstiPrintReportMsg extends AstiMsg {
  payload: {
    startDate: string;
    endDate: string;
    total: ReportPayment;
    payments: ReportPayment[];
  };
}

export interface AstiReimportOrderMsg extends AstiMsg {
  payload: {
    orderId: number;
  };
}

export interface AstiShowToastrMsg extends AstiMsg {
  payload: {
    title: string;
    message: string;
  };
}
