import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as appState from '../../../app/reducers/app.state';
import { UpdateOrderImportStatusAction } from '../../actions/order-import.action';
import * as importStatus from '../../model/import-status.model';
import { ShowOrderDetailPopupAction } from './../../../order-details/actions/order-details.action';
import { OrderDetailsPopupActionPayload } from './../../../order-details/model/order-details-popup-action-payload.model';
import { ReimportOrderAction } from './../../../shared/actions/connector-service.action';
import { OrderError } from './../../../shared/model/order-error.model';
import { Order } from './../../../shared/model/order.model';
import { MobileDetectService } from './../../../shared/services/mobile-detect/mobile-detect.service';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: '[appOrderImport]', // tslint:disable-line component-selector
  templateUrl: './order-import.component.html',
  styleUrls: ['./order-import.component.scss'],
})
export class OrderImportComponent implements OnInit {
  @Input() order: Order;

  plusIcon = faPlusCircle;

  orderDate: Date;
  orderNumber: string;

  showActionButtons: boolean;
  primaryActionButtonText: string;
  secondaryActionButtonText: string;

  nextStatus: number;

  constructor(private store: Store<appState.AppState>, private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.setOrderDate();
    this.configureActionButton();

    if (this.order.tableReference && this.order.tableReference !== '') {
      this.orderNumber = `${this.order.displayCode} - M${this.order.tableReference}`;
    } else {
      this.orderNumber = this.order.displayCode;
    }
  }

  showOrderPopup() {
    const payload = new OrderDetailsPopupActionPayload();
    payload.order = this.order;
    this.store.dispatch(new ShowOrderDetailPopupAction(payload));
  }

  /**
   * Allow the user to open the order details popup by clicking anywhere, if he is in a mobile device.
   */
  tableCellClickHandler() {
    if (this.mobileDetectService.isMobileDevice()) {
      this.showOrderPopup();
    }
  }

  primaryActionButtonClick() {
    this.store.dispatch(new ReimportOrderAction(this.order));
  }

  secondaryActionButtonClick() {
    const orderError = new OrderError();
    orderError.type = 'warn-order-manually-imported';
    orderError.message = 'Warning: this order was set to manually imported by the user';

    this.store.dispatch(new UpdateOrderImportStatusAction({ nextImportStatus: this.nextStatus, order: this.order, orderError }));
  }

  private setOrderDate() {
    if (this.order.readyDatetime) {
      this.orderDate = this.order.readyDatetime;
    } else if (this.order.posAcceptedDatetime) {
      this.orderDate = this.order.posAcceptedDatetime;
    } else {
      // If the posAcceptedDatetime is null (i.e. cancelled orders), fallback to creationDatetime and don't show the date diff
      this.orderDate = this.order.creationDatetime;
    }
  }

  private configureActionButton() {
    switch (this.order.posImportStatus) {
      case importStatus.IMPORT_ERROR:
        this.showActionButtons = true;
        this.primaryActionButtonText = 'Reimportar';
        this.secondaryActionButtonText = 'Digitado';
        this.nextStatus = importStatus.MANUALLY_IMPORTED;
        break;

      default:
        this.showActionButtons = false;
        this.primaryActionButtonText = '';
        this.secondaryActionButtonText = '';
        this.nextStatus = -1;
        break;
    }
  }
}
