// Below are all of the status an order can have.

export const STATUS_CANCELED = 0;
export const STATUS_BACKEND_RECEIVED = 1;
export const STATUS_PAYMENT_AUTHORIZED = 2;
export const STATUS_POS_RECEIVED = 3;
export const STATUS_POS_ACCEPTED = 4;
export const STATUS_POS_DENIED = 5;
export const STATUS_READY = 6;
export const STATUS_DISPATCHED = 7;
export const STATUS_DELIVERED = 8;
export const STATUS_RECEIVED = 9;
export const STATUS_CONSUMED = 10;
export const STATUS_POS_IMPORTED = 11;
export const STATUS_POS_IMPORT_ERROR = 12;
export const STATUS_PREPARING = 13;
export const STATUS_POS_ANALYSING = 14;
export const STATUS_PRODUCT_UNAVAILABLE = 15;
export const STATUS_CUSTOMER_ACTION_NEEDED = 16;
