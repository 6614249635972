import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs';

import { MobileDetectService } from '../../../shared/services/mobile-detect/mobile-detect.service';
import * as state from '../../reducers/app.state';
import * as features from '../../reducers/app.feature';
import { OrdersState } from '../../reducers/order/order.reducer';
import { Company } from './../../model/company.model';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { OrderStatus } from 'src/app/model/order-status.model';

/**
 * This component contains all of the tables for all of the orders.
 */
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderViewComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, { static: true }) scrollComponent: PerfectScrollbarComponent;

  chevronUpIcon = faChevronUp;
  chevronDownIcon = faChevronDown;

  isMobileDevice = false;

  ggrCompanies: Set<number>;
  companySubscription: Subscription;

  ordersState$: Observable<OrdersState>;
  ordersStateSubscription: Subscription;

  preparingOrders$: Observable<OrderStatus[]>;
  stoppedOrders$: Observable<OrderStatus[]>;
  readyOrders$: Observable<OrderStatus[]>;
  doneOrders$: Observable<OrderStatus[]>;
  cancelledOrders$: Observable<OrderStatus[]>;

  constructor(private store: Store<state.AppState>, private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.isMobileDevice = this.mobileDetectService.isMobileDevice();

    // Listen to all changes to the OrdersState, so that the scroll component can be updated.
    this.ordersStateSubscription = this.store.select(features.hasNewOrders).subscribe((hasNewOrders) => {
      if (hasNewOrders) {
        this.scrollComponent.directiveRef.update();
      }
    });

    this.preparingOrders$ = this.store.select(features.preparingOrdersStatus);
    this.stoppedOrders$ = this.store.select(features.stoppedOrdersStatusSelector);
    this.readyOrders$ = this.store.select(features.readyOrdersStatus);
    this.doneOrders$ = this.store.select(features.doneOrdersStatus);
    this.cancelledOrders$ = this.store.select(features.cancelledOrdersStatus);
  }

  ngOnDestroy() {
    if (this.ordersStateSubscription) {
      this.ordersStateSubscription.unsubscribe();
    }

    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  onOrderTableCollapse(event: Event) {
    this.scrollComponent.directiveRef.update();
  }

  onScrollUp(event: Event) {
    event.stopPropagation();

    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y - 350;

    if (newScrollPosition < 0) {
      newScrollPosition = 0;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }

  onScrollDown(event: Event) {
    event.stopPropagation();
    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y + 350;

    if (newScrollPosition > geom.h) {
      newScrollPosition = geom.h;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }
}
