import { Order } from 'src/shared/model/order.model';
import { OrderLogisticStatus } from '../model/order-logistic-status.model';
import { OrderStatus } from '../model/order-status.model';

export function formatOrders(ordersMap: Map<number, Order>, logistics: OrderLogisticStatus, reverseOrder: boolean): OrderStatus[] {
  const orderStatus: OrderStatus[] = [];

  for (const order of ordersMap.values()) {
    orderStatus.push({
      order,
      logistic: logistics ? logistics[order.numericalId] : undefined,
    });
  }

  if (reverseOrder) {
    return orderStatus.reverse();
  }

  return orderStatus;
}
