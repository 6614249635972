import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedModule } from './../shared/shared.module';
import { OrderDetailsPopupItemComponent } from './components/order-details-popup-item/order-details-popup-item.component';
import { OrderDetailsPopupViewComponent } from './components/order-details-popup-view/order-details-popup-view.component';
import { OrderDetailsPopupComponent } from './components/order-details-popup/order-details-popup.component';
import { OrderDetailsEffect } from './effects/order-details/order-details.effect';
import { FormatAddressPipe } from './pipes/format-address/format-address.pipe';
import { FormatCpfPipe } from './pipes/format-cpf/format-cpf.pipe';
import { FormatPhoneNumberPipe } from './pipes/format-phone-number/format-phone-number.pipe';
import { orderDetailsReducer } from './reducers/order-details/order-details.reducer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateFormatPipe } from 'src/app/date-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    PerfectScrollbarModule,
    StoreModule.forFeature('orderDetails', orderDetailsReducer),
    EffectsModule.forFeature([OrderDetailsEffect]),
    FontAwesomeModule,
  ],
  declarations: [
    OrderDetailsPopupComponent,
    OrderDetailsPopupItemComponent,
    OrderDetailsPopupViewComponent,
    FormatAddressPipe,
    FormatCpfPipe,
    FormatPhoneNumberPipe,
    DateFormatPipe,
  ],
  entryComponents: [OrderDetailsPopupViewComponent],
})
export class OrderDetailsModule {}
