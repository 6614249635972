import * as Sentry from '@sentry/browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor() {}

  /**
   * Fires an event that notifies the Onyo browser extension that a new order is available.
   */
  public notifyBrowserExtension() {
    const evt = new CustomEvent('newOrderEvent');
    document.dispatchEvent(evt);
  }

  /**
   * Plays the notification audio
   */
  public playNotificationSound() {
    const audio = new Audio('assets/sound/notification.mp3');
    audio.play();
  }

  /**
   * Shows a push notification whith the desired text and returns it.
   * Note: if notifications are not supported, returns null.
   */
  public showNotification(title: string, text: string): Notification {
    // Check if notifications are supported
    const notificationOptions = {
      body: text,
      icon: 'assets/img/logo_onyo-simples.png',
      badge: 'assets/img/logo_onyo-simples.png',
      vibrate: [200, 100, 200],
      requireInteraction: false,
    };

    try {
      // Throws an exception if the browser doesn't support a notification.
      const notification = new Notification(title, notificationOptions);
      notification.onclick = (e: any) => {
        window.focus();
        notification.close();
      };

      return notification;
    } catch (e) {
      if (e.name === 'TypeError') {
        console.warn('This browser does not support notifications', e);
      } else {
        Sentry.captureException(e);
      }
    }
  }

  public requestPermission() {
    try {
      Notification.requestPermission();
    } catch (e) {
      if (e.name === 'TypeError') {
        console.warn('This browser does not support notifications', e);
      } else {
        Sentry.captureException(e);
      }
    }
  }
}
