import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

/**
 * This service formats the dates as dd/mm/yyyy in the ngbDatepicker.
 */
@Injectable()
export class NgbBRDateParserService extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  public parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');

      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return {
          year: this.toInteger(dateParts[0]),
          month: null,
          day: null,
        };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return {
          year: this.toInteger(dateParts[1]),
          month: this.toInteger(dateParts[0]),
          day: null,
        };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return {
          year: this.toInteger(dateParts[2]),
          month: this.toInteger(dateParts[1]),
          day: this.toInteger(dateParts[0]),
        };
      }
    }

    return null;
  }

  public format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '/';
      stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '/';
      stringDate += date.year;
    }

    return stringDate;
  }

  private toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  private isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  private padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}
