<td class="table-cell align-middle">
  <fa-icon [icon]="plusIcon" size="2x" class="order-details-button" (click)="showOrderPopup()"></fa-icon>
</td>

<td id="displayCode" class="table-cell align-middle" (click)="tableCellClickHandler()">
  {{ orderNumber }}
</td>

<td id="customerName" class="table-cell align-middle" (click)="tableCellClickHandler()">
  {{ order.customerName }}
</td>

<td id="orderDate" class="table-cell align-middle" (click)="tableCellClickHandler()">
  {{ orderDate | date: 'HH:mm' }}
</td>

<td class="table-cell align-middle">
  <button
    *ngIf="showActionButtons"
    id="primary-action-button"
    type="button"
    class="btn btn-secondary action-button"
    (click)="primaryActionButtonClick()"
  >
    {{ primaryActionButtonText }}
  </button>
  <button
    *ngIf="showActionButtons"
    id="secondary-action-button"
    type="button"
    class="btn btn-secondary action-button"
    (click)="secondaryActionButtonClick()"
  >
    {{ secondaryActionButtonText }}
  </button>
</td>
