import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { SelectCompanyAction } from './../../../app/actions/company.action';
import { AppState } from './../../../app/reducers/app.state';
import { AstilectronReadyAction } from './../../actions/astlectron.action';
import { AstiMsg, AstiMsgTypes, AstiShowToastrMsg } from './../../model/astilectron-message.model';

@Injectable({
  providedIn: 'root',
})
export class AstilectronService {
  private isReady: boolean;

  constructor(private store: Store<AppState>, private toastrService: ToastrService) {
    this.isReady = false;

    document.addEventListener('astilectron-ready', (e: Event) => {
      if (typeof astilectron !== 'undefined') {
        this.isReady = true;

        astilectron.onMessage(this.handleIncomingMessages.bind(this));
        this.store.dispatch(new AstilectronReadyAction());
      }
    });
  }

  /**
   * Send a message to the GO connector service, without waiting for a reply.
   */
  public sendMessage(message: AstiMsg) {
    if (this.isReady) {
      astilectron.sendMessage(message);
    } else {
      const msg = `Astilectron undefined. Cannot send message: ${message.type}`;
      console.error(msg);
      Sentry.captureEvent({
        message: msg,
        level: Sentry.Severity.Error,
        extra: { message },
      });
    }
  }

  private handleIncomingMessages(message: AstiMsg) {
    let toastMsg: AstiShowToastrMsg;

    switch (message.type) {
      case AstiMsgTypes.LOAD_COMPANY_CONFIG:
        this.store.dispatch(new SelectCompanyAction(message.payload));
        break;

      case AstiMsgTypes.SHOW_TOAST_SUCCESS:
        toastMsg = message as AstiShowToastrMsg;
        this.toastrService.success(toastMsg.payload.message, toastMsg.payload.title);
        break;

      case AstiMsgTypes.SHOW_TOAST_ERROR:
        toastMsg = message as AstiShowToastrMsg;
        this.toastrService.error(toastMsg.payload.message, toastMsg.payload.title);
        break;

      case AstiMsgTypes.SHOW_TOAST_WARN:
        toastMsg = message as AstiShowToastrMsg;
        this.toastrService.warning(toastMsg.payload.message, toastMsg.payload.title);
        break;

      default:
        const warnMsg = `Unknown astilectron message: ${message.type}`;
        console.warn(warnMsg, message);
        Sentry.captureEvent({
          message: warnMsg,
          level: Sentry.Severity.Warning,
          extra: { message },
        });
        break;
    }
  }
}
