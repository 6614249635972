import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  public getCompanies(userToken: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/companies`;
    const headers = new HttpHeaders({ Authorization: userToken });

    return this.http.get(url, { headers });
  }

  public isOnline(companyId: number): Observable<any> {
    const url = `${environment.apiUrl}/v1/mobile/company/${companyId}/online`;

    return this.http.get(url).pipe(map((response: any) => response.online));
  }

  public getConfig(posToken: string, companyId: number): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/companies/${companyId}`;
    const headers = new HttpHeaders({ Authorization: posToken });

    return this.http.get(url, { headers });
  }
}
