import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginUserAction } from '../../actions/login.action';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/app.state';
import { loginErrorMsg } from '../../reducers/app.feature';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent implements OnInit, OnDestroy {
  spinnerIcon = faSpinner;
  loginForm: FormGroup;

  subscription: Subscription;

  executingLogin: boolean;
  loginErrorMsg: string;
  loginError: boolean;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();

    this.loginError = false;
    this.executingLogin = false;

    this.subscription = this.store.select(loginErrorMsg).subscribe((errorMsg: string) => {
      if (errorMsg) {
        this.executingLogin = false;
        this.loginError = true;
        this.loginErrorMsg = errorMsg;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onLogin() {
    this.executingLogin = true;
    this.loginError = false;

    const formModel = this.loginForm.value;

    this.store.dispatch(new LoginUserAction({ username: formModel.username, password: formModel.password }));
  }
}
