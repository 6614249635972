import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ProductAvailability } from '../../model/product-availability.model';

/**
 * This service retrieves a product list for a given company from the API.
 */
@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  constructor(private http: HttpClient) {}

  public getProductList(authToken: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/company/product-list`;

    const headers = new HttpHeaders({ Authorization: authToken });

    return this.http.get(url, { headers }).pipe(map((response: any) => response.data));
  }

  public setProductAvailability(authToken: string, products: ProductAvailability[]): Observable<ProductAvailability[] | Error> {
    const url = `${environment.apiUrl}/v1/connector/product/unavailable`;

    const headers = new HttpHeaders({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });

    const productData = JSON.stringify({ items: products });

    return this.http.put(url, productData, { headers }).pipe(map((response: any) => response.items));
  }
}
