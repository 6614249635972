import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs';

import * as state from '../../../app/reducers/app.state';
import { Order } from './../../../shared/model/order.model';
import { MobileDetectService } from './../../../shared/services/mobile-detect/mobile-detect.service';
import { OrdersImportState } from './../../reducers/order-import/order-import.reducer';
import { LoadAllImportOrdersAction } from '../../actions/order-import.action';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-import-view',
  templateUrl: './order-import-view.component.html',
  styleUrls: ['./order-import-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderImportViewComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, { static: true }) scrollComponent: PerfectScrollbarComponent;

  chevronUpIcon = faChevronUp;
  chevronDownIcon = faChevronDown;

  isMobileDevice = false;

  importOrdersState$: Observable<OrdersImportState>;
  importOrdersStateSubscription: Subscription;

  pendingOrders$: Observable<Map<number, Order>>;
  importingOrders$: Observable<Map<number, Order>>;
  importedOrders$: Observable<Map<number, Order>>;
  importError$: Observable<Map<number, Order>>;
  manuallyImported$: Observable<Map<number, Order>>;

  constructor(private store: Store<state.AppState>, private mobileDetectService: MobileDetectService) {}

  ngOnInit() {
    this.isMobileDevice = this.mobileDetectService.isMobileDevice();

    this.store.dispatch(new LoadAllImportOrdersAction());

    // Listen to all changes to the OrdersState, so that the scroll component can be updated.
    this.importOrdersState$ = this.store.select(state.importFeature);
    this.importOrdersStateSubscription = this.importOrdersState$.subscribe(() => {
      this.scrollComponent.directiveRef.update();
    });

    this.pendingOrders$ = this.store.select(state.importPendingOrders);
    this.importingOrders$ = this.store.select(state.importingOrders);
    this.importedOrders$ = this.store.select(state.importedOrders);
    this.importError$ = this.store.select(state.importError);
    this.manuallyImported$ = this.store.select(state.manuallyImported);
  }

  ngOnDestroy() {
    if (this.importOrdersStateSubscription) {
      this.importOrdersStateSubscription.unsubscribe();
    }
  }

  onOrderTableCollapse(event: Event) {
    this.scrollComponent.directiveRef.update();
  }

  onScrollUp(event: Event) {
    event.stopPropagation();

    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y - 350;

    if (newScrollPosition < 0) {
      newScrollPosition = 0;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }

  onScrollDown(event: Event) {
    event.stopPropagation();
    const geom = this.scrollComponent.directiveRef.geometry();
    let newScrollPosition = geom.y + 350;

    if (newScrollPosition > geom.h) {
      newScrollPosition = geom.h;
    }

    this.scrollComponent.directiveRef.scrollToY(newScrollPosition);
  }
}
