import { environment } from '../../../environments/environment';
import * as reportActions from '../../actions/report.action';
import { ReportData } from '../../model/report-data.model';
import { RequestStatus } from './../../../shared/model/request-status.model';

export interface ReportState {
  data: ReportData;
  timeout: number;

  // Status of loading the standard report
  status: RequestStatus;
  errorMsg: string;

  // Status of loading the CSV report
  csvStatus: RequestStatus;
  csvErrorMsg: string;
}

const initialState: ReportState = {
  data: undefined,
  timeout: environment.defaultTimeout,
  errorMsg: undefined,
  status: undefined,
  csvStatus: undefined,
  csvErrorMsg: undefined,
};

export function reportReducer(state: ReportState = initialState, action: reportActions.Actions): ReportState {
  switch (action.type) {
    case reportActions.LOAD_REPORT:
      return Object.assign({}, state, { status: RequestStatus.LOADING, errorMsg: undefined });

    case reportActions.LOAD_REPORT_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        status: RequestStatus.SUCCESS,
        errorMsg: undefined,
      });

    case reportActions.LOAD_REPORT_FAIL:
      return Object.assign({}, state, {
        reportData: new Array<ReportData>(),
        status: RequestStatus.FAIL,
        errorMsg: 'Erro ao carregar o relatório, por favor tente novamente.',
      });

    case reportActions.LOAD_CSV_REPORT:
      return Object.assign({}, state, {
        csvStatus: RequestStatus.LOADING,
        csvErrorMsg: undefined,
      });

    case reportActions.LOAD_CSV_REPORT_SUCCESS:
      return Object.assign({}, state, {
        csvStatus: RequestStatus.SUCCESS,
        csvErrorMsg: undefined,
      });

    case reportActions.LOAD_CSV_REPORT_FAIL:
      return Object.assign({}, state, {
        csvStatus: RequestStatus.FAIL,
        csvErrorMsg: 'Erro ao exportar o relatório, por favor tente novamente.',
      });

    case reportActions.SET_REPORT_STATUS:
      return Object.assign({}, state, { status: action.payload });

    default:
      return state;
  }
}
