import { LoyaltyPointsEffect } from './effects/loyalty-points/loyalty-points.effect';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { LoyaltyPointsFormComponent } from './components/loyalty-points-form/loyalty-points-form.component';
import { LoyaltyPointsViewComponent } from './components/loyalty-points-view/loyalty-points-view.component';
import { loyaltyPointsReducer } from './reducers/loyalty-points/loyalty-points.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [LoyaltyPointsViewComponent, LoyaltyPointsFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature('loyaltyPoints', loyaltyPointsReducer),
    EffectsModule.forFeature([LoyaltyPointsEffect]),
    FontAwesomeModule,
  ],
  exports: [LoyaltyPointsViewComponent],
})
export class LoyaltyPointsModule {}
