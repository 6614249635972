import { ActionReducerMap } from '@ngrx/store';
import { AstilectronState, astilectronReducer } from '../reducers/astilectron/astilectron.reducer';
import { CompanyLogisticState, companyLogisticReducer } from '../reducers/company-logistic/company-logistic.reducer';

export interface SharedModuleState {
  astilectron: AstilectronState;
  companyLogistic: CompanyLogisticState;
}

export const reducers: ActionReducerMap<SharedModuleState> = {
  astilectron: astilectronReducer,
  companyLogistic: companyLogisticReducer,
};
