import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: any): string {
    const phoneNumber = value.toString();

    const ddd = phoneNumber.substring(0, 2);
    let prefix: string;
    let num: string;

    // If we have a cellphone number
    if (phoneNumber.length === 11) {
      prefix = phoneNumber.substring(2, 7);
      num = phoneNumber.substring(7, 12);
    } else {
      // Landline number
      prefix = phoneNumber.substring(2, 6);
      num = phoneNumber.substring(6, 11);
    }

    return `(${ddd}) ${prefix}-${num}`;
  }
}
