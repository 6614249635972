import { SetProductAvailableAction } from '../../actions/unavailable-product.action';
import { AppState } from '../../../app/reducers/app.state';
import { Store } from '@ngrx/store';
import { Product } from '../../model/product.model';
import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-unavailable-product',
  templateUrl: './unavailable-product.component.html',
  styleUrls: ['./unavailable-product.component.scss'],
})
export class UnavailableProductComponent implements OnInit {
  @Input() product: Product;
  timesIcon = faTimes;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  setProductAvailable() {
    this.store.dispatch(new SetProductAvailableAction(this.product));
  }
}
