import { SetProductUnavailableAction } from '../../actions/unavailable-product.action';
import { AppState } from '../../../app/reducers/app.state';
import { Store } from '@ngrx/store';
import { Product } from '../../model/product.model';
import { Component, Input, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-available-product',
  templateUrl: './available-product.component.html',
  styleUrls: ['./available-product.component.scss'],
})
export class AvailableProductComponent implements OnInit {
  @Input() product: Product;
  circleIcon = faCircle;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  setProductUnavailable() {
    this.store.dispatch(new SetProductUnavailableAction(this.product));
  }
}
