import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared/shared.module';
import { AvailableProductComponent } from './components/available-product/available-product.component';
import { AvailableProductsListComponent } from './components/available-products-list/available-products-list.component';
import { UnavailableProductComponent } from './components/unavailable-product/unavailable-product.component';
import { UnavailableProductsListComponent } from './components/unavailable-products-list/unavailable-products-list.component';
import { UnavailableProductsViewComponent } from './components/unavailable-products-view/unavailable-products-view.component';
import { UnavailableProductEffect } from './effects/unavailable-product/unavailable-product.effect';
import { unavailableProductsReducer } from './reducers/unavailable-product/unavailable-product.reducer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('unavailableProducts', unavailableProductsReducer),
    EffectsModule.forFeature([UnavailableProductEffect]),
    PerfectScrollbarModule,
    FontAwesomeModule,
  ],
  declarations: [
    UnavailableProductsViewComponent,
    UnavailableProductsListComponent,
    UnavailableProductComponent,
    AvailableProductsListComponent,
    AvailableProductComponent,
  ],
  exports: [UnavailableProductsViewComponent],
})
export class UnavailableProductsModule {}
