import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CPFFormatterService {
  constructor() {}

  /**
   * formats the CPF as XXX.XXX.XXX-YY
   */
  formatCPF(cpf: string): string {
    if (!cpf) {
      return null;
    }

    const numCPF = cpf.replace(/\D+/gm, '');

    if (numCPF.length > 9) {
      return `${numCPF.substring(0, 3)}.${numCPF.substring(3, 6)}.${numCPF.substring(6, 9)}-${numCPF.substring(9, 11)}`;
    } else if (numCPF.length > 6) {
      return `${numCPF.substring(0, 3)}.${numCPF.substring(3, 6)}.${numCPF.substring(6)}`;
    } else if (numCPF.length > 3) {
      return `${numCPF.substring(0, 3)}.${numCPF.substring(3)}`;
    } else {
      return numCPF;
    }
  }

  /**
   * Removes all non numerical characters from the string.
   */
  getOnlyNumbers(cpf: string): string {
    return cpf.replace(/\D+/gm, '');
  }
}
