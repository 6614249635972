import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  AppState,
  filteredAvailableProductsListSelector,
  filteredUnavailableProductsListSelector,
  unavailableProductsErroMsgSelector,
  unavailableProductsStateSelector,
} from '../../../app/reducers/app.state';
import { LoadProductsAction } from '../../actions/unavailable-product.action';
import { Product } from '../../model/product.model';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { FilterProductsAction } from './../../actions/unavailable-product.action';
import { faSpinner, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-unavailable-products-view',
  templateUrl: './unavailable-products-view.component.html',
  styleUrls: ['./unavailable-products-view.component.scss'],
})
export class UnavailableProductsViewComponent implements OnInit {
  @ViewChild('searchInputField') searchInputField: ElementRef;
  spinnerIcon = faSpinner;
  searchIcon = faSearch;
  timesIcon = faTimes;

  status = RequestStatus;

  status$: Observable<RequestStatus>;
  errorMsg$: Observable<string>;
  availableProducts$: Observable<Map<number, Product>>;
  unavailableProducts$: Observable<Map<number, Product>>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.status$ = this.store.select(unavailableProductsStateSelector);
    this.errorMsg$ = this.store.select(unavailableProductsErroMsgSelector);

    this.availableProducts$ = this.store.select(filteredAvailableProductsListSelector);
    this.unavailableProducts$ = this.store.select(filteredUnavailableProductsListSelector);

    this.store.dispatch(new LoadProductsAction());
  }

  search(searchStr: string) {
    this.store.dispatch(new FilterProductsAction(searchStr));
  }

  clearSearchField() {
    this.searchInputField.nativeElement.value = '';
    this.store.dispatch(new FilterProductsAction(''));
  }

  tryAgain() {
    this.store.dispatch(new LoadProductsAction());
  }
}
