import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(private http: HttpClient) {}

  public resendOrderReadyPush(authToken: string, orderId: number): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/order/${orderId}/message`;

    const headers = new HttpHeaders({ Authorization: authToken });

    return this.http.post(url, '', { headers });
  }
}
