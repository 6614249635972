<div class="container pt-3 pb-3 loyalty-points-view">
  <div class="content pt-3 pb-3">
    <h2 class="title">Compra de Pontos</h2>

    <div class="loyalty-points-form" *ngIf="status === requestStatus.SUCCESS">
      <app-loyalty-points-form [data]="data"></app-loyalty-points-form>
    </div>

    <div class="loading-spinner text-center p-3" *ngIf="status === requestStatus.LOADING">
      <fa-icon [icon]="spinnerIcon" size="3x" [pulse]="true"></fa-icon>
    </div>

    <div class="error p-3 d-flex flex-column justify-content-center align-items-center" *ngIf="status === requestStatus.FAIL">
      <div class="error-message">
        <p>
          {{ errorMessage }}
        </p>
      </div>
      <div>
        <button class="btn try-again-button" (click)="retry()">Tentar novamente</button>
      </div>
    </div>
  </div>
</div>
