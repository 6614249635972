import * as orderDetailsActions from '../../actions/order-details.action';
import { OrderDetails } from './../../../shared/model/order-details.model';
import { RequestStatus } from './../../../shared/model/request-status.model';

export interface OrderDetailsState {
  order: OrderDetails;
  timeout: number;
  errorMsg: string;
  status: RequestStatus;
}

const initialState: OrderDetailsState = {
  order: undefined,
  timeout: 2000,
  errorMsg: undefined,
  status: undefined,
};

export function orderDetailsReducer(state: OrderDetailsState = initialState, action: orderDetailsActions.Actions): OrderDetailsState {
  switch (action.type) {
    case orderDetailsActions.LOAD_ORDER_DETAILS:
      return Object.assign({}, state, {
        order: undefined,
        errorMsg: undefined,
        status: RequestStatus.LOADING,
      });

    case orderDetailsActions.LOAD_ORDER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        order: action.payload,
        errorMsg: undefined,
        status: RequestStatus.SUCCESS,
      });

    case orderDetailsActions.LOAD_ORDER_DETAILS_FAIL:
      return Object.assign({}, state, {
        order: undefined,
        errorMsg: 'Erro ao carregar os detalhes do pedido.',
        status: RequestStatus.FAIL,
      });

    case orderDetailsActions.SET_LOAD_ORDER_DETAILS_TIMEOUT:
      return Object.assign({}, state, {
        timeout: action.payload,
      });

    default:
      return state;
  }
}
