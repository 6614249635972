import { Injectable } from '@angular/core';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class QueryStringService {
  constructor() {}

  parseBoolean(param: string, search: string): boolean {
    const parsedStr = queryString.parse(search);
    return parsedStr[param] === 'true';
  }
}
