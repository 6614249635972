<div class="unavailable-products-view container-fluid">
  <div [ngSwitch]="status$ | async" class="content">
    <ng-template [ngSwitchCase]="status.LOADING">
      <div class="container text-center">
        <fa-icon id="spinner" [icon]="spinnerIcon" size="3x" [pulse]="true"></fa-icon>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="status.SUCCESS">
      <div class="unavailable-products">
        <div class="search d-flex pt-3 pl-2 pr-2">
          <div class="input-group m-1">
            <div class="input-group-prepend">
              <span class="search-addon input-group-text"><fa-icon id="search-icon" [icon]="searchIcon"></fa-icon></span>
            </div>
            <input
              class="form-control search-input-field p-1"
              type="text"
              (keyup.enter)="search(searchInputField.value)"
              #searchInputField
            />
            <div class="input-group-append">
              <span class="input-group-text times-addon" (click)="clearSearchField()"
                ><fa-icon id="times-icon" [icon]="timesIcon"></fa-icon
              ></span>
            </div>
          </div>
          <button class="btn search-button m-1" (click)="search(searchInputField.value)">Buscar</button>
        </div>

        <div class="row unavailable-products-tables">
          <div class="col-md-6 table">
            <app-available-products-list [availableProducts]="availableProducts$ | async | mapToArray"></app-available-products-list>
          </div>

          <div class="col-md-6 table">
            <app-unavailable-products-list
              [unavailableProducts]="unavailableProducts$ | async | mapToArray"
            ></app-unavailable-products-list>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="status.FAIL">
      <div class="container text-center">
        <p class="error-msg">{{ errorMsg$ | async }}</p>
        <button class="btn try-again-button" (click)="tryAgain()">Tentar novamente</button>
      </div>
    </ng-template>
  </div>
</div>
