<div class="order-action-popup">
  <div class="popup-header p-2">
    <div class="modal-header">
      <span class="popup-title">Pedido #{{ order.displayCode }}</span>
      <fa-icon id="times-icon" [icon]="timesIcon" size="2x" (click)="activeModal.dismiss()"></fa-icon>
    </div>
    <div class="modal-header">
      <span class="popup-title">{{ order.customerName }}</span>
    </div>
  </div>

  <div class="buttons p-2 d-flex justify-content-between">
    <div class="p-2">
      <button type="button" class="btn btn-secondary left-button" (click)="onPrimaryButtonClick()">{{ leftButtonText }}</button>
    </div>
    <div class="p-2">
      <button type="button" class="btn btn-secondary right-button" (click)="onSecondaryButtonClick()">{{ rightButtonText }}</button>
    </div>
  </div>
</div>
