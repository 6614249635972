import { LoyaltyPointsState } from './../../loyalty-points/reducers/loyalty-points/loyalty-points.reducer';
import { StaffGetMemberState } from './staff-get-member/staff-get-member.reducer';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ReportState } from '../../report/reducers/report/report.reducer';
import { UnavailableProductsState } from '../../unavailable-products/reducers/unavailable-product/unavailable-product.reducer';
import { OrdersImportState } from './../../integration/reducers/order-import/order-import.reducer';
import { OrderDetailsState } from './../../order-details/reducers/order-details/order-details.reducer';
import { CompanyState } from './company/company.reducer';
import { LoginState } from './login/login.reducer';
import { OrdersState } from './order/order.reducer';
import { RouterStateUrl } from './router/router.reducer';
import { OrdersLogisticStatusState } from './order-logistic-status/order-logistic-status.reducer';

export interface AppState {
  orders: OrdersState;
  ordersLogisticStatus: OrdersLogisticStatusState;
  login: LoginState;
  company: CompanyState;
  staffGetMember: StaffGetMemberState;
  router: RouterReducerState<RouterStateUrl>;
}

// ***** ORDER DETAILS MODULE *****

export const orderDetailsFeatureSelector = createFeatureSelector<OrderDetailsState>('orderDetails');
export const orderDetailsSelector = createSelector(orderDetailsFeatureSelector, (state: OrderDetailsState) => state.order);
export const orderDetailsStatusSelector = createSelector(orderDetailsFeatureSelector, (state: OrderDetailsState) => state.status);
export const orderDetailsErrorMsgSelector = createSelector(orderDetailsFeatureSelector, (state: OrderDetailsState) => state.errorMsg);

// ***** UNAVAILABLE PRODUCTS MODULE *****

export const unavailableProductsFeature = createFeatureSelector<UnavailableProductsState>('unavailableProducts');
export const unavailableProductsTimeoutSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.timeout
);
export const unavailableProductsStateSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.status
);
export const unavailableProductsErroMsgSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.errorMsg
);

export const unavailableProductsListSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.unavailableProducts
);
export const availableProductsListSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.availableProducts
);

export const filteredUnavailableProductsListSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.filteredUnavailableProducts
);
export const filteredAvailableProductsListSelector = createSelector(
  unavailableProductsFeature,
  (state: UnavailableProductsState) => state.filteredAvailableProducts
);

// ***** REPORT MODULE *****

export const reportFeatureSelector = createFeatureSelector<ReportState>('report');
export const reportTimeoutSelector = createSelector(reportFeatureSelector, (state: ReportState) => state.timeout);
export const reportStatusSelector = createSelector(reportFeatureSelector, (state: ReportState) => state.status);
export const reportCSVStatusSelector = createSelector(reportFeatureSelector, (state: ReportState) => state.csvStatus);
export const reportErrorMsgSelector = createSelector(reportFeatureSelector, (state: ReportState) => state.errorMsg);
export const reportDataSelector = createSelector(reportFeatureSelector, (state: ReportState) => state.data);

// ***** INTEGRATION MODULE *****

export const importFeature = createFeatureSelector<OrdersImportState>('importOrders');
export const importPollingInterval = createSelector(importFeature, (state: OrdersImportState) => state.pollingInterval);
export const importStatusUpdateTimeout = createSelector(importFeature, (state: OrdersImportState) => state.orderImportUpdateTimeout);
export const importPendingOrders = createSelector(importFeature, (state: OrdersImportState) => state.pendingOrders);
export const importingOrders = createSelector(importFeature, (state: OrdersImportState) => state.importingOrders);
export const importedOrders = createSelector(importFeature, (state: OrdersImportState) => state.importedOrders);
export const importError = createSelector(importFeature, (state: OrdersImportState) => state.importError);
export const importStatusUpdateErrorMsg = createSelector(importFeature, (state: OrdersImportState) => state.orderImportUpdateErrorMsg);
export const manuallyImported = createSelector(importFeature, (state: OrdersImportState) => state.manuallyImported);

// ***** LOYALTY POINTS MODULE *****
export const loyaltyPointsFeature = createFeatureSelector<LoyaltyPointsState>('loyaltyPoints');
export const loyaltyPointsTimeout = createSelector(loyaltyPointsFeature, (state: LoyaltyPointsState) => state.timeout);
export const creditRewardsRequestStatus = createSelector(
  loyaltyPointsFeature,
  (state: LoyaltyPointsState) => state.postCreditRewardsStatus
);
export const loyaltyPointsErrorMsg = createSelector(loyaltyPointsFeature, (state: LoyaltyPointsState) => state.postCreditRewardsErrorMsg);
export const creditRewardsData = createSelector(loyaltyPointsFeature, (state: LoyaltyPointsState) => ({
  data: state.creditRewardsData,
  status: state.getCreditRewardsStatus,
  errorMsg: state.getCreditRewardsErrorMsg,
}));
