import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.state';
import { CompanyLogisticService } from 'src/shared/services/company-logistic/company-logistic.service';
import { asyncScheduler, Observable, of, timer, throwError } from 'rxjs';
import {
  getCompanyLogisticData,
  getCompanyLogisticDataFail,
  getCompanyLogisticDataSuccess,
} from 'src/shared/actions/company-logistic.action';
import { withLatestFrom, switchMap, map, catchError, timeout, retryWhen, mergeMap } from 'rxjs/operators';
import { companyLogisticRetryInterval, companyLogisticMaxRetries } from 'src/shared/state/shared.feature';
import { companyLogisticTokenSelector } from 'src/app/reducers/app.feature';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';

@Injectable()
export class CompanyLogisticEffects {
  companyLogistic$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType(getCompanyLogisticData),
      withLatestFrom(
        this.store$.pipe(select(companyLogisticTokenSelector)),
        this.store$.pipe(select(companyLogisticRetryInterval)),
        this.store$.pipe(select(companyLogisticMaxRetries))
      ),
      switchMap(([action, token, retryInterval, maxRetries]) => this.getCompanyLogisticData(token, retryInterval, maxRetries, scheduler))
    )
  );

  constructor(private actions$: Actions, private store$: Store<AppState>, private companyLogisticService: CompanyLogisticService) {}

  private getCompanyLogisticData(token: string, retryInterval: number, maxRetries: number, scheduler: AsyncScheduler): Observable<any> {
    if (!token || token === '') {
      console.warn('No logistic token available');
      return of(getCompanyLogisticDataFail({ error: new Error('No logistic token available') }));
    }

    return this.companyLogisticService.getCompanyConfig(token).pipe(
      timeout(retryInterval, scheduler),
      retryWhen((retryObs) =>
        retryObs.pipe(
          mergeMap((error, i) => {
            if (error.name === 'TimeoutError' && i < maxRetries) {
              console.warn('Timeout error while retrieving company data', error);
              return timer(retryInterval * (i + 1), scheduler);
            }

            return throwError(error);
          })
        )
      ),
      map(() => getCompanyLogisticDataSuccess()),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while retriving company logistic data', error);
        } else if (error.status === 404) {
          console.warn('Logistic configuration for this company was not found', error);
        } else {
          console.error('Error retrieving company logistic data', error);
          Sentry.captureException(error);
        }

        return of(getCompanyLogisticDataFail({ error }));
      })
    );
  }
}
