import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { LoyaltyPoints } from './../../model/loyalty-points.model';

@Injectable({
  providedIn: 'root',
})
export class CreditRewardsService {
  constructor(private http: HttpClient) {}

  getRewardsProducts(authToken: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/credit-rewards`;

    const headers = new HttpHeaders({
      Authorization: authToken,
    });

    return this.http.get(url, { headers });
  }

  creditRewards(authToken: string, loyaltyPoints: LoyaltyPoints): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/credit-rewards`;

    const headers = new HttpHeaders({
      Authorization: authToken,
    });

    const body = {
      customer_document: loyaltyPoints.cpf,
      manager_password: loyaltyPoints.password,
      price: loyaltyPoints.price,
      points: loyaltyPoints.points,
      product_id: loyaltyPoints.productId,
      payment_method_id: loyaltyPoints.paymentMethod,
      card_brand_id: loyaltyPoints.cardBrandId,
    };

    return this.http.post(url, body, { headers });
  }
}
