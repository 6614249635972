import { Action } from '@ngrx/store';
import { Order } from './../model/order.model';
import { OrderDetails } from './../model/order-details.model';

export const PRINT_ORDER = 'print-order';
export const PRINT_ORDER_SUCCESS = 'print-order-success';
export const PRINT_ORDER_FAIL = 'print-order-fail';

export const PRINT_REPORT = 'print-report';
export const PRINT_REPORT_SUCCESS = 'print-report-success';
export const PRINT_REPORT_FAIL = 'print-report-fail';

export const REIMPORT_ORDER = 'reimport-order';
export const REIMPORT_ORDER_SUCCESS = 'reimport-order-success';
export const REIMPORT_ORDER_FAIL = 'reimport-order-fail';

/**
 * Sends a request for the connector service to print an order.
 * Payload - OrderDetails - Order that should be printed.
 */
export class PrintOrderAction implements Action {
  readonly type = PRINT_ORDER;
  constructor(public payload: OrderDetails) {}
}

/**
 * This action is fired when a print request is successfully made.
 * Payload - Order - order that was printed
 */
export class PrintOrderSuccessAction implements Action {
  readonly type = PRINT_ORDER_SUCCESS;
  constructor(public payload: OrderDetails) {}
}

/**
 * This action is fired when there is a problem in the print request.
 */
export class PrintOrderFailAction implements Action {
  readonly type = PRINT_ORDER_FAIL;
  constructor(public payload: OrderDetails) {}
}

/**
 * Sends a request for the connector service to print a report.
 * Payload:
 * startDate - Date: The initial date of the report.
 * endDate - Date: the final date of the report.
 */
export class PrintReportAction implements Action {
  readonly type = PRINT_REPORT;
  constructor(public payload: { startDate: Date; endDate: Date }) {}
}

/**
 * This action is fired when the print report rqeuest is successfully made.
 */
export class PrintReportSuccessAction implements Action {
  readonly type = PRINT_REPORT_SUCCESS;
  constructor() {}
}

/**
 * This action is fired when there is a problem in the print request.
 * Payload - Error
 */
export class PrintReportFailAction implements Action {
  readonly type = PRINT_REPORT_FAIL;
  constructor() {}
}

/**
 * This action sends a request to the connector service to reimport an order
 * Payload: Order
 */
export class ReimportOrderAction implements Action {
  readonly type = REIMPORT_ORDER;
  constructor(public payload: Order) {}
}

/**
 * This action is called when the reimport message is successfully sent ot the
 * connector service (it doesn't mean that the order was successfully reimported)
 */
export class ReimportOrderSuccessAction implements Action {
  readonly type = REIMPORT_ORDER_SUCCESS;
  constructor(public payload: Order) {}
}

/**
 * This action is called when there is an error sending the reimport message to the
 * connector service (it doesn't mean the there was a problem importing the order)
 */
export class ReimportOrderFailAction implements Action {
  readonly type = REIMPORT_ORDER_FAIL;
  constructor(public payload: Order) {}
}

export type Actions =
  | PrintOrderAction
  | PrintOrderSuccessAction
  | PrintOrderFailAction
  | PrintReportAction
  | PrintReportSuccessAction
  | PrintReportFailAction
  | ReimportOrderAction
  | ReimportOrderSuccessAction
  | ReimportOrderFailAction;
