import { OrderError } from './../../shared/model/order-error.model';
import { OrderDetails } from './../../shared/model/order-details.model';
import { Order } from './../../shared/model/order.model';
import { Action } from '@ngrx/store';

export const LOAD_ALL_IMPORT_ORDERS = 'load-all-import-orders';
export const LOAD_ALL_IMPORT_ORDERS_COMPLETE = 'load-all-import-orders-complete';
export const LOAD_ALL_IMPORT_ORDERS_FAIL = 'load-all-import-orders-fail';
export const UPDATE_ORDER_IMPORT_STATUS = 'update-order-import-status';
export const UPDATE_ORDER_IMPORT_STATUS_SUCCESS = 'update-order-import-status-success';
export const UPDATE_ORDER_IMPORT_STATUS_FAIL = 'update-order-import-status-fail';

/**
 * This action loads all of the available orders grouped by import status.
 */
export class LoadAllImportOrdersAction implements Action {
  readonly type = LOAD_ALL_IMPORT_ORDERS;
  constructor() {}
}

/**
 * This action is called when the load import orders action returns successfully.
 */
export class LoadAllImportOrdersCompleteAction implements Action {
  readonly type = LOAD_ALL_IMPORT_ORDERS_COMPLETE;
  constructor(public payload: Map<number, any>) {}
}

/**
 * This action is called when the load import orders action returns an error.
 */
export class LoadAllImportOrdersFailAction implements Action {
  readonly type = LOAD_ALL_IMPORT_ORDERS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action is called to update the import status of a given order.
 * Payload: { nextStatus: number, order: Order, orderError?: OrderError } - contains the next status, the
 * order that will be updated and an option orderError object if the status change implies an error.
 */
export class UpdateOrderImportStatusAction implements Action {
  readonly type = UPDATE_ORDER_IMPORT_STATUS;
  constructor(public payload: { nextImportStatus: number; order: Order | OrderDetails; orderError?: OrderError }) {}
}

/**
 * This action is called when the import status update of an order is successful.
 * Payload: Order - the updated order
 */
export class UpdateOrderImportStatusSuccessAction implements Action {
  readonly type = UPDATE_ORDER_IMPORT_STATUS_SUCCESS;
  constructor(public payload: Order) {}
}

/**
 * This action is called when the import status update of an order fails.
 * Payload: error
 */
export class UpdateOrderImportStatusFailAction implements Action {
  readonly type = UPDATE_ORDER_IMPORT_STATUS_FAIL;
  constructor(public payload: Error) {}
}

export type Actions =
  | LoadAllImportOrdersAction
  | LoadAllImportOrdersCompleteAction
  | LoadAllImportOrdersFailAction
  | UpdateOrderImportStatusAction
  | UpdateOrderImportStatusSuccessAction
  | UpdateOrderImportStatusFailAction;
