import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { ToastrService } from 'ngx-toastr';
import { Observable, of as observableOf, timer as observableTimer } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import * as orderActions from '../../actions/order.action';
import { Company } from '../../model/company.model';
import * as features from '../../reducers/app.feature';
import { AppState } from '../../reducers/app.state';
import { NotificationService } from '../../services/notification/notification.service';
import { OrderService } from '../../services/order/order.service';
import { PushService } from '../../services/push/push.service';
import { OrderActionPopupComponent } from './../../components/order-action-popup/order-action-popup.component';
import { isConnectorServiceReady } from 'src/shared/state/shared.feature';

@Injectable()
export class OrderEffect {
  @Effect()
  loadAllOrders$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.LOAD_ALL_ORDERS),
    withLatestFrom(this.store$.select(features.ordersPollingIntervalSelector)),
    switchMap(([action, pollingInterval]: any[]) => {
      return observableTimer(0, pollingInterval).pipe(
        // Timer to create the polling effect.
        withLatestFrom(this.store$.select(features.selectedCompanySelector)),
        switchMap(([timerNum, company]: [any, Company]) => this.loadAllOrders(action, pollingInterval, company))
      );
    })
  );

  @Effect({ dispatch: false })
  loadAllOrdersComplete$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.LOAD_ALL_ORDERS_COMPLETE),
    withLatestFrom(this.store$.select(features.hasNewOrders), this.store$.select(isConnectorServiceReady)),
    map(([action, hasNewOrders, hasConnectorService]) => {
      if (hasNewOrders && !hasConnectorService) {
        this.notificationService.showNotification('Pede Pronto', `Chegou um novo pedido!`);
        this.notificationService.playNotificationSound();
      }
      return action;
    })
  );

  @Effect()
  updateOrderStatus$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.UPDATE_ORDER_STATUS),
    withLatestFrom(this.store$.select(features.orderUpdateTimeout), this.store$.select(features.companyPosTokenSelector)),
    concatMap(([action, timeoutValue, posToken]: any[]) => this.updateOrderStatus(action, timeoutValue, posToken))
  );

  @Effect({ dispatch: false })
  notifyOrderUpdateSuccess$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.UPDATE_ORDER_STATUS_SUCCESS),
    tap((action: orderActions.UpdateOrderStatusSuccessAction) => {
      // Show notificaiton that the order was successfully updated.
      this.toastr.success(`Pedido: ${action.payload.displayCode} atualizado com sucesso.`);
    })
  );

  @Effect({ dispatch: false })
  notifyOrderUpdateFail$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.UPDATE_ORDER_STATUS_FAIL),
    withLatestFrom(this.store$.select(features.orderUpdateErrorMsgSelector)),
    map(([action, errorMsg]: [Action, string]) => {
      // Show notification that the order update failed.
      this.toastr.error(`${errorMsg}`, 'Erro ao atualizar o pedido:');
      return action;
    })
  );

  @Effect()
  resendOrderReadyPush$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.RESEND_ORDER_READY_PUSH),
    withLatestFrom(this.store$.select(features.orderUpdateTimeout), this.store$.select(features.companyPosTokenSelector)),
    switchMap(([action, timeoutValue, posToken]: [orderActions.ResendOrderReadyPushAction, number, string]) =>
      this.resendOrderReadyPush(action, timeoutValue, posToken)
    )
  );

  @Effect({ dispatch: false })
  resendOrderReadyPushSuccess$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.RESEND_ORDER_READY_PUSH_SUCCESS),
    tap(() => {
      this.mixpanel.trackEvent('resend-order-ready-push-success');
      this.toastr.success('Mensagem enviada com sucesso');
    })
  );

  @Effect({ dispatch: false })
  resendOrderReadyPushFail$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.RESEND_ORDER_READY_PUSH_FAIL),
    tap((action: orderActions.ResendOrderReadyPushFailAction) => {
      this.mixpanel.trackEvent('resend-order-ready-push-fail', { error: action.payload.message });

      if (action.payload.message === 'onyo.order.too-soon-to-resend-ready-push') {
        this.toastr.warning(
          'Uma mensagem já foi enviada ao cliente, por favor aguarde 1 minuto para reenviá-la.',
          'Erro ao enviar mensagem.'
        );
      } else {
        this.toastr.error('Por favor tente novamente.', 'Erro ao enviar mensagem.');
      }
    })
  );

  @Effect({ dispatch: false })
  openOrderActionPopup$: Observable<Action> = this.actions.pipe(
    ofType(orderActions.OPEN_ORDER_ACTION_POPUP),
    tap((action: orderActions.OpenOrderActionPopupAction) => {
      const componentRef = this.modalService.open(OrderActionPopupComponent);
      componentRef.componentInstance.order = action.payload.order;
      componentRef.componentInstance.leftButtonText = action.payload.leftButtonText;
      componentRef.componentInstance.leftButtonAction = action.payload.leftButtonAction;
      componentRef.componentInstance.rightButtonText = action.payload.rightButtonText;
      componentRef.componentInstance.rightButtonAction = action.payload.rightButtonAction;
    })
  );

  constructor(
    private actions: Actions,
    private orderService: OrderService,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private router: Router,
    private mixpanel: MixpanelService,
    private pushService: PushService,
    private modalService: NgbModal
  ) {}

  /**
   * Loads all orders for a given company using the order service.
   * If the POS Token has not been selected by the user yet, log a warning and return an error.
   */
  private loadAllOrders(
    action: orderActions.LoadAllOrdersAction,
    pollingInterval: number,
    company: Company
  ): Observable<orderActions.LoadAllOrdersCompleteAction | orderActions.LoadAllOrdersFailAction> {
    // Only load orders if we have a token selected.
    if (company && company.posToken) {
      return this.orderService
        .getAllOrders(company.posToken, company.includeOrderTypes, company.excludeOrderTypes, company.acceptNewOrders)
        .pipe(
          timeout(pollingInterval),
          map((newOrders: any) => {
            return new orderActions.LoadAllOrdersCompleteAction(newOrders);
          }),
          catchError((error) => this.handleLoadAllOrdersError(error))
        );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new orderActions.LoadAllOrdersFailAction(new Error(errorMsg)));
    }
  }

  /**
   * Handles any error that occurrs with the load all orders effect.
   */
  private handleLoadAllOrdersError(error): Observable<orderActions.LoadAllOrdersFailAction> {
    if (error.name === 'TimeoutError') {
      console.warn('Timeout error while loading all orders', error);
    } else {
      const errorMsg = 'Error loading all orders';
      console.error(errorMsg, error);

      const err: any = new Error(errorMsg);
      err.innerException = error;
      err.error = error.error;
      err.headers = error.headers;
      err.JSON = JSON.stringify(error);
      Sentry.captureException(err);
    }

    return observableOf(new orderActions.LoadAllOrdersFailAction(error));
  }

  /**
   * Tries to update an orders status
   */
  private updateOrderStatus(
    action: orderActions.UpdateOrderStatusAction,
    timeoutValue: number,
    posToken: string
  ): Observable<orderActions.UpdateOrderStatusSuccessAction | orderActions.UpdateOrderStatusFailAction> {
    if (posToken) {
      this.mixpanel.trackEvent('order-status-update', {
        status: action.payload.order.status,
        nextStatus: action.payload.nextStatus,
        id: action.payload.order.numericalId,
        creationDateTime: action.payload.order.creationDatetime,
        posAcceptedDateTime: action.payload.order.posAcceptedDatetime,
        readyDateTime: action.payload.order.readyDatetime,
        customerName: action.payload.order.customerName,
        displayCode: action.payload.order.displayCode,
      });

      return this.orderService
        .updateOrderStatus(posToken, action.payload.order.numericalId, action.payload.order.status, action.payload.nextStatus)
        .pipe(
          timeout(timeoutValue),
          map((newOrder) => {
            this.mixpanel.trackEvent('order-status-update-success', {
              currentStatus: action.payload.order.status,
              nextStatus: action.payload.nextStatus,
            });
            return new orderActions.UpdateOrderStatusSuccessAction(newOrder);
          }),
          catchError((error) => this.handleUpdateOrderStatusError(error, action))
        );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new orderActions.UpdateOrderStatusFailAction(new Error(errorMsg)));
    }
  }

  /**
   * Handles any errors that occur while trying to udpdate the order status.
   */
  private handleUpdateOrderStatusError(
    error: any,
    action: orderActions.UpdateOrderStatusAction
  ): Observable<orderActions.UpdateOrderStatusFailAction> {
    if (error.name === 'TimeoutError') {
      console.warn('Timeout error while updating order status', error);
      this.mixpanel.trackEvent('order-status-update-fail', {
        error: 'timeout',
        currentStatus: action.payload.order.status,
        nextStatus: action.payload.nextStatus,
      });
    } else {
      const errorMsg = 'Error while updating order status';
      console.error(errorMsg, error);

      const err: any = new Error(errorMsg);
      err.innerException = error;
      err.error = error.error;
      err.headers = error.headers;
      err.JSON = JSON.stringify(error);
      Sentry.captureException(err);

      let backendError: any;
      if (error.error) {
        backendError = error.error.error;
      } else {
        backendError = error;
      }

      this.mixpanel.trackEvent('order-status-update-fail', {
        errorMsg: backendError,
        currentStatus: action.payload.order.status,
        nextStatus: action.payload.nextStatus,
      });
    }

    return observableOf(new orderActions.UpdateOrderStatusFailAction(error));
  }

  /**
   * Calls the resend order service to try to resend the order ready push for a given order id.
   */
  private resendOrderReadyPush(
    action: orderActions.ResendOrderReadyPushAction,
    timeoutValue: number,
    token: string
  ): Observable<orderActions.ResendOrderReadyPushSuccessAction | orderActions.ResendOrderReadyPushFailAction> {
    // Check company token
    if (!token) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new orderActions.ResendOrderReadyPushFailAction(new Error(errorMsg)));
    }

    this.mixpanel.trackEvent('resend-order-ready-push', { orderId: action.payload });
    return this.pushService.resendOrderReadyPush(token, action.payload).pipe(
      timeout(timeoutValue),
      map(() => new orderActions.ResendOrderReadyPushSuccessAction()),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.warn('Timeout error while resending order ready push', error);
        } else {
          const errorMsg = 'Error while resending order ready push';
          console.error(errorMsg, error);

          const err: any = new Error(errorMsg);
          err.innerException = error;
          err.error = error.error;
          err.headers = error.headers;
          err.JSON = JSON.stringify(error);
          Sentry.captureException(err);
        }

        return observableOf(new orderActions.ResendOrderReadyPushFailAction(error));
      })
    );
  }
}
