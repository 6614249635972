export const environment = {
  version: require('../../package.json').version,
  production: false,
  apiUrl: 'https://api.staging.onyo.com',
  logisticApiUrl: 'https://lgs.staging.onyo.com',
  ravenUrl: '',
  connectorWebsocketUrl: 'wss://localhost:12125/api',
  connectorFallbackWebsocketUrl: 'ws://localhost:12126/api-fallback',
  mixpanelUrl: 'https://api.mixpanel.com',
  mixpanelToken: '047155a57f2ee8923e0dae4f20412229',

  defaultTimeout: 60000,
  defaultPollingInterval: 20000,
  latest: '8h',

  enableRouteTracing: true,

  enableDebugDate: false,
  neopboUrl: "https://chat.b1app.com.br/?organization=e2f403f9-5699-405b-b4bb-e26ef7888ce7",

  features: {
    importProducts: true,
    unavailableProducts: true,
    printOrder: true,
    printReport: true,
  },
};
