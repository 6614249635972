<td class="table-cell align-middle">
  <fa-icon [icon]="plusIcon" size="2x" class="order-details-button" (click)="showOrderPopup()"></fa-icon>
</td>

<td id="displayCode" class="table-cell align-middle" (click)="tableCellClickHandler()">
  {{ orderNumber }}
</td>

<td id="customerName" class="table-cell align-middle" (click)="tableCellClickHandler()">
  {{ order.customerName }}
</td>

<td id="orderDate" class="table-cell align-middle" (click)="tableCellClickHandler()">
  <span>{{ orderDate | date: 'HH:mm' }}</span>
  /
  <span id="diff" *ngIf="showDateDiff">{{ orderDate | dateDiff: orderDateDiff }}</span>
</td>

<td class="table-cell align-middle" (click)="tableCellClickHandler()">
  <div placement="bottom" [ngbTooltip]="tooltipText" class="status-tooltip">
    <fa-icon *ngIf="logisticIcon" [icon]="logisticIcon" [class]="logisticIconClass" size="lg"></fa-icon>
  </div>
</td>

<td class="table-cell align-middle">
  <button [hidden]="!showActionButton" type="button" class="btn btn-secondary action-button" (click)="actionButtonClick()">
    {{ actionButtonText }}
  </button>
</td>
