<div class="app container d-flex flex-column align-items-center">
  <div class="app-content">
    <app-navbar></app-navbar>
  </div>
  <div class="app-content flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <div class="app-content">
    <app-footer></app-footer>
  </div>
</div>
