import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver-es';

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {
  constructor() {}

  public saveCSV(content: string) {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'onyo.csv');
  }
}
