import { SharedModuleState } from './shared.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const sharedModuleFeature = createFeatureSelector<SharedModuleState>('sharedModule');
export const isConnectorServiceReady = createSelector(sharedModuleFeature, (state: SharedModuleState) => state.astilectron.isReady);
export const companyLogisticRetryInterval = createSelector(
  sharedModuleFeature,
  (state: SharedModuleState) => state.companyLogistic.retryInterval
);
export const companyLogisticMaxRetries = createSelector(
  sharedModuleFeature,
  (state: SharedModuleState) => state.companyLogistic.maxRetries
);
export const logisticIntegrationAvailable = createSelector(
  sharedModuleFeature,
  (state: SharedModuleState) => state.companyLogistic.integrationAvailable
);
