<div class="order-item-popup">

  <div [ngSwitch]="productType">
    <div *ngSwitchCase="'choosable'">
      <span class="d-flex justify-content-between">
        <p class="item-name">{{item.name}}</p>
      </span>
    </div>
    <div *ngSwitchCase="'normal'">
      <span class="d-flex justify-content-between">
        <p class="item-name">{{item.quantity | number}} {{item.name}}</p>
        <p class="item-price">{{price | currency:'BRL'}}</p>
      </span>
    </div>
    <div *ngSwitchCase="'redeem'">
      <span class="d-flex justify-content-between">
        <p class="item-name">{{item.quantity | number}} {{item.name}}</p>
        <p class="item-price">Pts: {{item.loyalty_points}}</p>
      </span>
    </div>
  </div>

  <ul class="item-list">
    <li *ngFor="let childItem of item.items">
      <app-order-details-popup-item [item]="childItem"></app-order-details-popup-item>
    </li>
  </ul>
</div>