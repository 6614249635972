<div class="order-view">
  <perfect-scrollbar class="order-view-scrollbar" [disabled]="isMobileDevice">
    <div [class.order-view-table]="!isMobileDevice" [class.order-view-table-mobile]="isMobileDevice">
      <fa-icon *ngIf="!isMobileDevice" [icon]="chevronUpIcon" class="arrow-up-button" (click)="onScrollUp($event)"></fa-icon>
      <app-order-table
        [ordersStatus]="preparingOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        tableTitle="Preparando"
        [allowCollapse]="false"
        [isCollapsed]="false"
        class="order-table title-color-preparing"
      >
      </app-order-table>

      <app-order-table
        [ordersStatus]="stoppedOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        tableTitle="Parado"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-table title-color-stopped"
      >
      </app-order-table>

      <app-order-table
        [ordersStatus]="readyOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        tableTitle="Pronto"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-table title-color-ready"
      >
      </app-order-table>

      <app-order-table
        [ordersStatus]="doneOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        tableTitle="Finalizado"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-table title-color-done"
      >
      </app-order-table>

      <app-order-table
        [ordersStatus]="cancelledOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        tableTitle="Cancelado"
        [allowCollapse]="true"
        [isCollapsed]="true"
        class="order-table title-color-cancelled"
      >
      </app-order-table>
    </div>
    <fa-icon *ngIf="!isMobileDevice" [icon]="chevronDownIcon" class="arrow-down-button" (click)="onScrollDown($event)"></fa-icon>
  </perfect-scrollbar>
</div>
