import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent as observableFromEvent, merge as observableMerge, Observable, of as observableOf, Subscription } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { IsCompanyOnlineAction } from '../../actions/company.action';
import { AppState } from '../../reducers/app.state';
import * as appState from '../../reducers/app.state';
import * as routerFeatureSelector from '../../reducers/app.feature';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  checkCircleIcon = faCheckCircle;
  exclamationCircleIcon = faExclamationCircle;

  companyName$: Observable<string>;

  online$: Observable<boolean>;
  appOnlineSubscription: Subscription;
  storeOnlineSubscription: Subscription;

  appOnline: boolean;
  appOnlineOfflineText: string;
  readonly appOnlineText = 'Conectado';
  readonly appOfflineText = 'Desconectado';

  storeOnline: boolean;
  storeOnlineOfflineText: string;
  readonly storeOnlineText = 'Online';
  readonly storeOfflineText = 'Offline';

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.companyName$ = this.store.select(routerFeatureSelector.companyNameSelector);

    this.appOnline = false;
    this.appOnlineOfflineText = this.appOfflineText;
    this.storeOnline = false;
    this.storeOnlineOfflineText = this.storeOfflineText;

    this.online$ = observableMerge(
      observableOf(navigator.onLine),
      observableFromEvent(window, 'online').pipe(mapTo(true)),
      observableFromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.appOnlineSubscription = this.online$.subscribe((online: boolean) => {
      if (online) {
        this.appOnline = true;
        this.appOnlineOfflineText = this.appOnlineText;
      } else {
        this.appOnline = false;
        this.appOnlineOfflineText = this.appOfflineText;
      }
    });

    this.storeOnlineSubscription = this.store
      .select(routerFeatureSelector.isSelectedCompanyOnlineSelector)
      .subscribe((companyOnline: boolean) => {
        if (companyOnline) {
          this.storeOnline = true;
          this.storeOnlineOfflineText = this.storeOnlineText;
        } else {
          this.storeOnline = false;
          this.storeOnlineOfflineText = this.storeOfflineText;
        }
      });

    // Begin checking if the selected company is online or offline.
    this.store.dispatch(new IsCompanyOnlineAction());
  }

  ngOnDestroy() {
    if (this.appOnlineSubscription) {
      this.appOnlineSubscription.unsubscribe();
    }
    if (this.storeOnlineSubscription) {
      this.storeOnlineSubscription.unsubscribe();
    }
  }
}
