<div class="order-import-view">
  <perfect-scrollbar class="order-import-view-scrollbar" [disabled]="isMobileDevice">
    <div [class.order-import-view-table]="!isMobileDevice" [class.order-import-view-table-mobile]="isMobileDevice">
      <fa-icon *ngIf="!isMobileDevice" [icon]="chevronUpIcon" class="arrow-up-button" (click)="onScrollUp($event)"></fa-icon>
      <app-order-import-table
        [ordersMap]="importError$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        title="Erro de Importação"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-import-table title-color-import-error"
      >
      </app-order-import-table>

      <app-order-import-table
        [ordersMap]="pendingOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        title="Pendente"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-import-table title-color-pending"
      >
      </app-order-import-table>

      <app-order-import-table
        [ordersMap]="importingOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        title="Importando"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-import-table title-color-importing"
      >
      </app-order-import-table>

      <app-order-import-table
        [ordersMap]="importedOrders$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        title="Importado"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-import-table title-color-imported"
      >
      </app-order-import-table>

      <app-order-import-table
        [ordersMap]="manuallyImported$ | async"
        (collapseEvent)="onOrderTableCollapse($event)"
        title="Importado Manualmente"
        [allowCollapse]="true"
        [isCollapsed]="false"
        class="order-import-table title-color-manually-imported"
      >
      </app-order-import-table>
      <fa-icon *ngIf="!isMobileDevice" [icon]="chevronDownIcon" class="arrow-down-button" (click)="onScrollDown($event)"></fa-icon>
    </div>
  </perfect-scrollbar>
</div>
