import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { AppState } from '../../../app/reducers/app.state';
import * as appState from '../../../app/reducers/app.state';
import * as routerFeatureSelector from '../../../app/reducers/app.feature';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import * as Sentry from '@sentry/browser';
import {
  LOAD_CSV_REPORT,
  LOAD_CSV_REPORT_FAIL,
  LOAD_CSV_REPORT_SUCCESS,
  LOAD_REPORT,
  LoadCSVReportAction,
  LoadCSVReportFailAction,
  LoadCSVReportSuccessAction,
  LoadReportAction,
  LoadReportFailAction,
  LoadReportSuccessAction,
} from '../../actions/report.action';
import { FileSaverService } from '../../services/file-saver/file-saver.service';
import { ReportService } from '../../services/report/report.service';

@Injectable()
export class ReportEffect {
  @Effect()
  loadReportData$: Observable<Action> = this.actions.pipe(
    ofType(LOAD_REPORT),
    withLatestFrom(this.store$.select(routerFeatureSelector.companyPosTokenSelector), this.store$.select(appState.reportTimeoutSelector)),
    switchMap(([action, token, timeoutValue]: [LoadReportAction, string, number]) => this.loadReport(action, token, timeoutValue))
  );

  @Effect()
  loadCSVReportData$: Observable<Action> = this.actions.pipe(
    ofType(LOAD_CSV_REPORT),
    withLatestFrom(this.store$.select(routerFeatureSelector.companyPosTokenSelector), this.store$.select(appState.reportTimeoutSelector)),
    switchMap(([action, token, timeoutValue]: [LoadCSVReportAction, string, number]) => this.loadCSVReport(action, token, timeoutValue))
  );

  @Effect({ dispatch: false })
  loadCSVReportSuccess$: Observable<Action> = this.actions.pipe(
    ofType(LOAD_CSV_REPORT_SUCCESS),
    tap((action: LoadCSVReportSuccessAction) => this.fileSaverService.saveCSV(action.payload))
  );

  @Effect({ dispatch: false })
  loadCSVReportFail$: Observable<Action> = this.actions.pipe(
    ofType(LOAD_CSV_REPORT_FAIL),
    tap((action: LoadCSVReportFailAction) => this.toastrService.error('Erro ao exportar o relatório, por favor tente novamente.'))
  );

  constructor(
    private actions: Actions,
    private reportService: ReportService,
    private fileSaverService: FileSaverService,
    private store$: Store<AppState>,
    private toastrService: ToastrService,
    private mixpanel: MixpanelService
  ) {}

  /**
   * Loads the report data for a given company from the API
   */
  private loadReport(
    action: LoadReportAction,
    token: string,
    timeoutValue: number
  ): Observable<LoadReportSuccessAction | LoadReportFailAction> {
    if (token) {
      this.mixpanel.trackEvent('report', { startDate: action.payload.startDate, endDate: action.payload.endDate });

      return this.reportService.getReportData(token, action.payload.startDate, action.payload.endDate).pipe(
        timeout(timeoutValue),
        map((reportData) => new LoadReportSuccessAction(reportData)),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.warn('Timeout error while loading the order report', error);
          } else {
            const errorMsg = 'Error while loading the order report';
            console.error(errorMsg, error);

            const err: any = new Error(errorMsg);
            err.innerException = error;
            err.error = error.error;
            err.headers = error.headers;
            err.JSON = JSON.stringify(error);
            Sentry.captureException(err);
          }

          return observableOf(new LoadReportFailAction(error));
        })
      );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new LoadReportFailAction(new Error(errorMsg)));
    }
  }

  private loadCSVReport(
    action: LoadCSVReportAction,
    token: string,
    timeoutValue: number
  ): Observable<LoadCSVReportSuccessAction | LoadCSVReportFailAction> {
    if (token) {
      this.mixpanel.trackEvent('csv-report', { startDate: action.payload.startDate, endDate: action.payload.endDate });

      return this.reportService.getCSVReportData(token, action.payload.startDate, action.payload.endDate).pipe(
        timeout(timeoutValue),
        map((reportData) => new LoadCSVReportSuccessAction(reportData)),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.warn('Timeout error while loading the order CSV report.', error);
          } else {
            const errorMsg = 'Error while loading the order CSV report.';
            console.error(errorMsg, error);

            const err: any = new Error(errorMsg);
            err.innerException = error;
            err.error = error.error;
            err.headers = error.headers;
            err.JSON = JSON.stringify(error);
            Sentry.captureException(err);
          }

          return observableOf(new LoadCSVReportFailAction(error));
        })
      );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({
        message: errorMsg,
        category: 'http',
        level: Sentry.Severity.Warning,
      });

      return observableOf(new LoadCSVReportFailAction(new Error(errorMsg)));
    }
  }
}
