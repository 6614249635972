import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { Company } from '../../../app/model/company.model';

@Injectable()
export class MixpanelService {
  constructor() {}

  public init() {
    mixpanel.init(environment.mixpanelToken, { api_host: environment.mixpanelUrl });
  }

  public setCompany(company: Company) {
    mixpanel.identify(company.numericalId);
    mixpanel.people.set({
      id: company.numericalId,
      name: company.name,
      brandName: company.brandName,
    });
  }

  public trackEvent(name: string, data?: any) {
    mixpanel.track(name, data);
  }

  public trackLink(link: string, name: string, data?: any) {
    mixpanel.track_links(link, name, data);
  }
}
