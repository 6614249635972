import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { DisplayLoginComponentAction } from '../../actions/login.action';
import { AppState } from '../../reducers/app.state';
import { authenticatedSelector } from '../../reducers/app.feature';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(authenticatedSelector).pipe(
      take(1),
      map((auth: boolean) => {
        if (!auth) {
          this.store.dispatch(new DisplayLoginComponentAction());
          return false;
        }
        return true;
      })
    );
  }
}
