import { Action } from '@ngrx/store';

import { CreditRewardsData } from './../model/credit-rewards-data.model';
import { LoyaltyPointsError } from './../model/loyalty-points-error.model';
import { LoyaltyPoints } from './../model/loyalty-points.model';

export const GET_CREDIT_REWARDS_PRODUCTS = 'get-credit-rewards-products';
export const GET_CREDIT_REWARDS_PRODUCTS_SUCCESS = 'get-credit-rewards-products-success';
export const GET_CREDIT_REWARDS_PRODUCTS_FAIL = 'get-credit-rewards-products-fail';
export const CREDIT_REWARDS = 'credit-rewards';
export const CREDIT_REWARDS_SUCCESS = 'credit-rewards-success';
export const CREDIT_REWARDS_FAIL = 'credit-rewards-fail';

export class GetCreditRewardsProductsAction implements Action {
  readonly type = GET_CREDIT_REWARDS_PRODUCTS;
  constructor() {}
}

export class GetCreditRewardsProductsSuccessAction implements Action {
  readonly type = GET_CREDIT_REWARDS_PRODUCTS_SUCCESS;
  constructor(public payload: CreditRewardsData) {}
}

export class GetCreditRewardsProductsFailAction implements Action {
  readonly type = GET_CREDIT_REWARDS_PRODUCTS_FAIL;
  constructor(public payload: any) {}
}

export class CreditRewardsAction implements Action {
  readonly type = CREDIT_REWARDS;
  constructor(public payload: LoyaltyPoints) {}
}

export class CreditRewardsSuccessAction implements Action {
  readonly type = CREDIT_REWARDS_SUCCESS;
  constructor() {}
}

export class CreditRewardsFailAction implements Action {
  readonly type = CREDIT_REWARDS_FAIL;
  constructor(public payload: LoyaltyPointsError) {}
}

export type Actions =
  | GetCreditRewardsProductsAction
  | GetCreditRewardsProductsSuccessAction
  | GetCreditRewardsProductsFailAction
  | CreditRewardsAction
  | CreditRewardsSuccessAction
  | CreditRewardsFailAction;
