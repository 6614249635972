import { manuallyImported } from './../../../app/reducers/app.state';
import * as importActions from '../../actions/order-import.action';
import * as importStatusGroup from '../../model/import-status-group.model';
import { environment } from './../../../environments/environment';
import { Order } from './../../../shared/model/order.model';

export interface OrdersImportState {
  pendingOrders: Map<number, Order>;
  importingOrders: Map<number, Order>;
  importedOrders: Map<number, Order>;
  importError: Map<number, Order>;
  manuallyImported: Map<number, Order>;

  pendingStatusList: Set<number>;
  importingStatusList: Set<number>;
  importedStatusList: Set<number>;
  importErrorStatusList: Set<number>;
  manuallyImportedStatusList: Set<number>;

  pollingInterval: number;
  errorMsg: string;

  orderImportUpdateTimeout: number;
  orderImportUpdateErrorMsg: string;
}

const initialState: OrdersImportState = {
  pendingOrders: new Map<number, Order>(),
  importingOrders: new Map<number, Order>(),
  importedOrders: new Map<number, Order>(),
  importError: new Map<number, Order>(),
  manuallyImported: new Map<number, Order>(),

  pendingStatusList: new Set<number>(),
  importingStatusList: new Set<number>(),
  importedStatusList: new Set<number>(),
  importErrorStatusList: new Set<number>(),
  manuallyImportedStatusList: new Set<number>(),

  pollingInterval: environment.defaultPollingInterval,
  errorMsg: undefined,

  orderImportUpdateTimeout: environment.defaultTimeout,
  orderImportUpdateErrorMsg: undefined,
};

export function orderImportReducer(state: OrdersImportState = initialState, action: importActions.Actions) {
  let newState: OrdersImportState;

  switch (action.type) {
    case importActions.LOAD_ALL_IMPORT_ORDERS:
      return Object.assign({}, state, { erorMsg: undefined });

    case importActions.LOAD_ALL_IMPORT_ORDERS_COMPLETE:
      return Object.assign({}, state, {
        pendingOrders: new Map<number, Order>(action.payload[importStatusGroup.IMPORT_GROUP_PENDING].items),
        importingOrders: new Map<number, Order>(action.payload[importStatusGroup.IMPORT_GROUP_IMPORTING].items),
        importedOrders: new Map<number, Order>(action.payload[importStatusGroup.IMPORT_GROUP_IMPORTED].items),
        importError: new Map<number, Order>(action.payload[importStatusGroup.IMPORT_GROUP_ERROR].items),
        manuallyImported: new Map<number, Order>(action.payload[importStatusGroup.IMPORT_GROUP_MANUALLY_IMPORTED].items),

        pendingStatusList: new Set<number>(action.payload[importStatusGroup.IMPORT_GROUP_PENDING].statuses),
        importingStatusList: new Set<number>(action.payload[importStatusGroup.IMPORT_GROUP_IMPORTING].statuses),
        importedStatusList: new Set<number>(action.payload[importStatusGroup.IMPORT_GROUP_IMPORTED].statuses),
        importErrorStatusList: new Set<number>(action.payload[importStatusGroup.IMPORT_GROUP_ERROR].statuses),
        manuallyImportedStatusList: new Set<number>(action.payload[importStatusGroup.IMPORT_GROUP_MANUALLY_IMPORTED].statuses),

        errorMsg: undefined,
      });

    case importActions.LOAD_ALL_IMPORT_ORDERS_FAIL:
      return Object.assign({}, state, { errorMsg: 'Erro ao obter o status de importação dos pedidos' });

    case importActions.UPDATE_ORDER_IMPORT_STATUS:
      return Object.assign({}, state, { orderImportUpdateErrorMsg: undefined });

    case importActions.UPDATE_ORDER_IMPORT_STATUS_SUCCESS:
      newState = {
        pendingOrders: new Map<number, Order>(state.pendingOrders),
        importingOrders: new Map<number, Order>(state.importingOrders),
        importedOrders: new Map<number, Order>(state.importedOrders),
        importError: new Map<number, Order>(state.importError),
        manuallyImported: new Map<number, Order>(state.manuallyImported),

        pendingStatusList: new Set<number>(state.pendingStatusList),
        importingStatusList: new Set<number>(state.importingStatusList),
        importedStatusList: new Set<number>(state.importedStatusList),
        importErrorStatusList: new Set<number>(state.importErrorStatusList),
        manuallyImportedStatusList: new Set<number>(state.manuallyImportedStatusList),

        pollingInterval: state.pollingInterval,
        errorMsg: state.errorMsg,

        orderImportUpdateTimeout: state.orderImportUpdateTimeout,
        orderImportUpdateErrorMsg: undefined,
      };

      const order = action.payload;

      // Remove the order from its current place.
      if (newState.pendingOrders.has(order.numericalId)) {
        newState.pendingOrders.delete(order.numericalId);
      } else if (newState.importingOrders.has(order.numericalId)) {
        newState.importingOrders.delete(order.numericalId);
      } else if (newState.importedOrders.has(order.numericalId)) {
        newState.importedOrders.delete(order.numericalId);
      } else if (newState.importError.has(order.numericalId)) {
        newState.importError.delete(order.numericalId);
      } else if (newState.manuallyImported.has(order.numericalId)) {
        newState.manuallyImported.delete(order.numericalId);
      }

      // Add the order to its new place
      if (newState.pendingStatusList.has(order.posImportStatus) || order.posImportStatus === null) {
        newState.pendingOrders.set(order.numericalId, order);
      } else if (newState.importingStatusList.has(order.posImportStatus)) {
        newState.importingOrders.set(order.numericalId, order);
      } else if (newState.importedStatusList.has(order.posImportStatus)) {
        newState.importedOrders.set(order.numericalId, order);
      } else if (newState.importErrorStatusList.has(order.posImportStatus)) {
        newState.importError.set(order.numericalId, order);
      } else if (newState.manuallyImportedStatusList.has(order.posImportStatus)) {
        newState.manuallyImported.set(order.numericalId, order);
      }

      return newState;

    case importActions.UPDATE_ORDER_IMPORT_STATUS_FAIL:
      // Create a user friendly error message.
      let errorMsg: string;
      if (action.payload.message.includes('order.invalid-current-pos-import-status')) {
        errorMsg = 'O pedido está com um status de importação diferente do atual.\nAguarde o carregamento do novo status.';
      } else if (action.payload.message.includes('timeout')) {
        errorMsg = 'Tempo limite para atualização do status de importação do pedido atingido.\nPor favor tente novamente.';
      } else {
        errorMsg = 'Não foi possível atualizar o pedido.\nPor favor tente novamente.';
      }

      return Object.assign({}, state, { orderImportUpdateErrorMsg: errorMsg });

    default:
      return state;
  }
}
