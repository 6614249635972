import { AstilectronService } from './../shared/services/astilectron/astilectron.service';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { LoyaltyPointsModule } from '../loyalty-points/loyalty-points.module';
import { OrderDetailsModule } from '../order-details/order-details.module';
import { ReportModule } from '../report/report.module';
import { SharedModule } from '../shared/shared.module';
import { UnavailableProductsModule } from '../unavailable-products/unavailable-products.module';
import { IntegrationModule } from './../integration/integration.module';
import { SentryErrorHandler } from './../shared/services/sentry/sentry.service';
import { AppComponent } from './app.component';
import { CellphoneInputComponent } from './components/cellphone-input/cellphone-input.component';
import { ConfigurationViewComponent } from './components/configuration-view/configuration-view.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { OrderActionPopupComponent } from './components/order-action-popup/order-action-popup.component';
import { OrderTableComponent } from './components/order-table/order-table.component';
import { OrderViewComponent } from './components/order-view/order-view.component';
import { OrderComponent } from './components/order/order.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CompanyEffect } from './effects/company/company.effect';
import { LoginEffect } from './effects/login/login.effect';
import { OrderEffect } from './effects/order/order.effect';
import { StaffGetMemberEffect } from './effects/staff-get-member/staff-get-member.effect';
import { DateDiffPipe } from './pipes/date-diff/date-diff.pipe';
import { OrderAliasPipe } from './pipes/order-alias/order-alias.pipe';
import { AppState } from './reducers/app.state';
import { companyReducer } from './reducers/company/company.reducer';
import { loginReducer } from './reducers/login/login.reducer';
import { ordersReducer } from './reducers/order/order.reducer';
import { CustomSerializer } from './reducers/router/router.reducer';
import { staffGetMemberReducer } from './reducers/staff-get-member/staff-get-member.reducer';
import { AppRoutingModule } from './router/app-routing.module';
import { InteractionPopupComponent } from './components/interaction-popup/interaction-popup.component';
import { orderLogisticStatusReducer } from './reducers/order-logistic-status/order-logistic-status.reducer';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { OrderLogisticStatusEffects } from './effects/order-logistic-status/order-logistic-status.effect';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';

export const reducers: ActionReducerMap<AppState> = {
  orders: ordersReducer,
  ordersLogisticStatus: orderLogisticStatusReducer,
  login: loginReducer,
  company: companyReducer,
  staffGetMember: staffGetMemberReducer,
  router: routerReducer,
};

const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    AppComponent,
    OrderTableComponent,
    OrderComponent,
    NavbarComponent,
    PageNotFoundComponent,
    OrderViewComponent,
    ConfigurationViewComponent,
    LoginViewComponent,
    FooterComponent,
    OrderAliasPipe,
    DateDiffPipe,
    SafeUrlPipe,
    OrderActionPopupComponent,
    CellphoneInputComponent,
    InteractionPopupComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    UnavailableProductsModule,
    ReportModule,
    IntegrationModule,
    OrderDetailsModule,
    SharedModule,
    LoyaltyPointsModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      closeButton: true,
      titleClass: 'toastr-title',
      messageClass: 'toastr-message',
      maxOpened: 5,
      newestOnTop: false,
    }),
    NgbModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    EffectsModule.forRoot([OrderEffect, LoginEffect, CompanyEffect, StaffGetMemberEffect, OrderLogisticStatusEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    PerfectScrollbarModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    CookieService,
    AstilectronService,
  ],
  entryComponents: [OrderActionPopupComponent, InteractionPopupComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
