<nav class="connector-navbar nav navbar navbar-fixed-top navbar-expand-xl">
  <div class="container">
    <div class="navbar-brand">
      <img src="assets/img/logo-onyo.png" class="onyo-logo" />
    </div>

    <button class="navbar-toggler" type="button"
      (click)="toggleCollapse()">
      <fa-icon [icon]="barsIcon" size="lg"></fa-icon>
    </button>

    <div class="navbar-collapse collapse" [class.show]="show">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link orders-link" [class.nav-link-active]="ordersLinkActive" routerLink="orders"
            routerLinkActive="active" (click)="collapse()">Pedidos</a>
        </li>
        <li class="nav-item" [hidden]="!displayImportLink">
          <a class="nav-link import-link" [class.nav-link-active]="importLinkActive" routerLink="integration"
            routerLinkActive="active" (click)="collapse()">Integração</a>
        </li>
        <li class="nav-item" [hidden]="!displayLoyaltyPointsLink">
          <a class="nav-link loyalty-points-link" [class.nav-link-active]="loyaltyPointsLinkActive"
            routerLink="loyalty-points" routerLinkActive="active" (click)="collapse()">Pontuação</a>
        </li>
        <li class="nav-item" [hidden]="!displayUnavailableLink">
          <a class="nav-link unavailable-link" [class.nav-link-active]="unavailableLinkActive"
            routerLink="unavailable-products" routerLinkActive="active" (click)="collapse()">Indisponíveis</a>
        </li>

        <!-- Only show the configuration button if the connector sevice (Astilectron) is not available -->
        <li *ngIf="(connectorServiceAvailable$ | async) === false || null || undefined" class="nav-item">
          <a class="nav-link configuration-link" [class.nav-link-active]="configurationLinkActive"
            routerLink="configuration" routerLinkActive="active" (click)="collapse()">Configuração</a>
        </li>

        <li class="nav-item">
          <a class="nav-link report-link" [class.nav-link-active]="reportLinkActive" routerLink="report"
            routerLinkActive="active" (click)="collapse()">Relatório</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
