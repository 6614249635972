import { Action } from '@ngrx/store';

export const INIT_ASTILECTRON = 'init-astilectron';
export const ASTILECTRON_READY = 'astilectron-ready';

/**
 * Initializes the Astilectron service and registers the astilectron ready event
 */
export class InitAstilectronAction implements Action {
  readonly type = INIT_ASTILECTRON;
  constructor() {}
}

export class AstilectronReadyAction implements Action {
  readonly type = ASTILECTRON_READY;
  constructor() {}
}

export type Actions = InitAstilectronAction | AstilectronReadyAction;
