import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { CPFFormatterService } from '../../services/cpf-formatter/cpf-formatter.service';
import { CPFValidatorService } from '../../services/cpf-validator/cpf-validator.service';
import { AppState, creditRewardsRequestStatus, loyaltyPointsErrorMsg } from './../../../app/reducers/app.state';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { CreditRewardsAction } from './../../actions/loyalty-points.action';
import { CreditRewardsData } from './../../model/credit-rewards-data.model';
import { CreditRewardsProduct } from './../../model/credit-rewards-product.model';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loyalty-points-form',
  templateUrl: './loyalty-points-form.component.html',
  styleUrls: ['./loyalty-points-form.component.scss'],
})
export class LoyaltyPointsFormComponent implements OnInit {
  @Input() data: CreditRewardsData;

  spinnerIcon = faSpinner;

  onDestroy$: Subject<void>;
  loyaltyPointsForm: FormGroup;
  status = RequestStatus;
  reqStatus: RequestStatus;
  reqErrorMessage$: Observable<string>;
  hideCardBrands: boolean;

  // Valid Payment Methods
  validVouchers: Set<number>;
  validCreditCards: Set<number>;
  validDebtCards: Set<number>;

  constructor(
    private formBuilder: FormBuilder,
    private cpfValidator: CPFValidatorService,
    private cpfFormatter: CPFFormatterService,
    private store$: Store<AppState>
  ) {}

  ngOnInit() {
    this.onDestroy$ = new Subject<void>();
    this.reqErrorMessage$ = this.store$.select(loyaltyPointsErrorMsg);
    this.hideCardBrands = false;
    this.initForm();

    // Valid payment methods
    this.validVouchers = new Set<number>([7, 8, 9, 10, 11]); // VR, Ticket, Sodexo, Alelo, Ben Visa Vale
    this.validCreditCards = new Set<number>([1, 2, 3, 4, 5, 6]); // Amex, Diners, Elo, Hipercard, Mastercard, Visa
    this.validDebtCards = new Set<number>([3, 4, 5, 6]); // Elo, Hipercard, Mastercard, Visa

    this.store$
      .select(creditRewardsRequestStatus)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentReqStatus) => {
        this.reqStatus = currentReqStatus;

        if (currentReqStatus === RequestStatus.SUCCESS) {
          this.loyaltyPointsForm.reset();
        }
      });
  }

  private initForm() {
    this.loyaltyPointsForm = this.formBuilder.group(
      {
        cpf: ['', [Validators.required, this.cpfFormValidator.bind(this)]],
        points: ['', Validators.required],
        paymentMethods: ['', Validators.required],
        cardBrands: [''],
        password: ['', Validators.required],
      },
      {
        validators: this.cardBrandValidator.bind(this),
      }
    );

    // Format the CPF as we type (allows only numbers).
    this.loyaltyPointsForm
      .get('cpf')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((cpf: string) => {
        const formattedCPF = this.cpfFormatter.formatCPF(cpf);
        this.loyaltyPointsForm.get('cpf').setValue(formattedCPF, { emitEvent: false });
      });

    this.loyaltyPointsForm
      .get('paymentMethods')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: number) => {
        // Do not show the card brands dropdown if the user chosen cash.
        if (value === 4) {
          this.hideCardBrands = true;
        } else {
          this.hideCardBrands = false;
        }
      });
  }

  onSubmit() {
    this.loyaltyPointsForm.get('cpf').markAsDirty();
    this.loyaltyPointsForm.get('points').markAsDirty();
    this.loyaltyPointsForm.get('password').markAsDirty();
    this.loyaltyPointsForm.get('paymentMethods').markAsDirty();
    this.loyaltyPointsForm.get('cardBrands').markAsDirty();

    if (this.loyaltyPointsForm.invalid) {
      return;
    }

    const product: CreditRewardsProduct = this.loyaltyPointsForm.get('points').value;
    const cpf = this.loyaltyPointsForm.get('cpf').value;

    let action: CreditRewardsAction;
    if (this.loyaltyPointsForm.get('paymentMethods').value === 4) {
      // Ignore card brand ID for cash payments.
      action = new CreditRewardsAction({
        cpf: this.cpfFormatter.getOnlyNumbers(cpf),
        password: this.loyaltyPointsForm.get('password').value,
        points: product.points,
        productId: product.id,
        price: product.price,
        paymentMethod: this.loyaltyPointsForm.get('paymentMethods').value,
        cardBrandId: null,
      });
    } else {
      action = new CreditRewardsAction({
        cpf: this.cpfFormatter.getOnlyNumbers(cpf),
        password: this.loyaltyPointsForm.get('password').value,
        points: product.points,
        productId: product.id,
        price: product.price,
        paymentMethod: this.loyaltyPointsForm.get('paymentMethods').value,
        cardBrandId: this.loyaltyPointsForm.get('cardBrands').value,
      });
    }
    this.store$.dispatch(action);
  }

  /**
   * Helper method for easy access to the form controls on the template.
   */
  get controls() {
    return this.loyaltyPointsForm.controls;
  }

  cpfFormValidator(control: AbstractControl): { [key: string]: any } | null {
    const result = this.cpfValidator.validateCPF(control.value);
    return result ? null : { invalidCPF: true };
  }

  cardBrandValidator(control: FormGroup): ValidationErrors | null {
    const paymentMethod = control.get('paymentMethods').value;
    const cardBrand = control.get('cardBrands').value;

    switch (paymentMethod) {
      // Credit card
      case 1:
        return this.validCreditCards.has(cardBrand) ? null : { invalidCardBrand: true };

      // Debt card
      case 2:
        return this.validDebtCards.has(cardBrand) ? null : { invalidCardBrand: true };

      // Voucher
      case 3:
        return this.validVouchers.has(cardBrand) ? null : { invalidCardBrand: true };

      // Cash
      case 4:
      default:
        return null;
    }
  }
}
