import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AstilectronEffect } from './effects/astilectron/astilectron.effec';
import { MapToArrayPipe } from './pipes/map-to-array/map-to-array.pipe';
import { reducers } from './state/shared.state';
import { I18nService } from './services/i18n/i18n.service';
import { MixpanelService } from './services/mixpanel/mixpanel.service';
import { MobileDetectService } from './services/mobile-detect/mobile-detect.service';
import { CompanyLogisticEffects } from './effects/company-logistic/company-logistic.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sharedModule', reducers),
    EffectsModule.forFeature([AstilectronEffect, CompanyLogisticEffects]),
  ],
  declarations: [MapToArrayPipe],
  providers: [MobileDetectService, MixpanelService, I18nService],
  exports: [MapToArrayPipe],
})
export class SharedModule {}
