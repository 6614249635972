<table class="table table-striped table-sm order-import-table">
  <thead>
    <tr>
      <th colspan="5" class="table-title text-center">
        {{ title }} ({{ this.ordersMap.size }})
        <fa-icon
          *ngIf="allowCollapse"
          [icon]="caretDownIcon"
          size="lg"
          class="collapse-button p-1"
          [class.fa-rotate-270]="isCollapsed"
          (click)="collapse()"
        ></fa-icon>
      </th>
    </tr>

    <tr [ngbCollapse]="isCollapsed" class="table-header">
      <th class="table-text text-center"></th>
      <th class="table-text text-center">Pedido - Mesa</th>
      <th class="table-text text-center">Cliente</th>
      <th class="table-text text-center">Hora</th>
      <th class="table-text text-center">Ação</th>
    </tr>

    <tr></tr>
  </thead>

  <tbody [ngbCollapse]="isCollapsed" class="table-body">
    <tr
      appOrderImport
      [order]="order"
      *ngFor="let order of ordersMap | mapToArray; let i = index"
      class="table-text text-center order-row"
    ></tr>
  </tbody>
</table>
