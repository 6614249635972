import { I18nService } from '../../../shared/services/i18n/i18n.service';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDatePickerI18nService extends NgbDatepickerI18n {
  I18N_VALUES = {
    'pt-br': {
      weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      monthsFull: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    // other languages you would support
  };

  constructor(private service: I18nService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.I18N_VALUES[this.service.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.I18N_VALUES[this.service.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }
}
