<div class="order-popup">
  <div class="order-popup-body">
    <div class="order-group">
      <div class="display">
        <div class="pair">
          <div class="order-info gutter-tb">
            <div class="order-info-item">
              <span class="order-label order-name">Nome</span>
              {{ orderDetails.customerName }}
            </div>
            <div class="order-info-item" *ngIf="orderDetails.customerDocument">
              <span class="order-label order-cpf">CPF</span>
              {{ orderDetails.customerDocument | formatCpf }}
            </div>
            <div class="order-info-item" *ngIf="orderDetails.customerTelephone">
              <span class="order-label order-phone">Telefone</span>
              {{ orderDetails.customerTelephone | formatPhoneNumber }}
            </div>
          </div>
        </div>
        <div class="pair">
          <div class="order-info gutter-tb">

            <div *ngIf="showFullAddress || showPartialAddress" class="order-address order-info-item">
              <span class="order-label">Endereço</span>
              <div id="full-address" *ngIf="showFullAddress">{{ orderDetails.address | formatAddress }}</div>
              <div id="partial-address" *ngIf="showPartialAddress">{{ orderDetails.address.complement }}</div>
            </div>

            <div *ngFor="let payment of orderDetails.payments" class="d-flex justify-content-between payment">
              <div *ngIf="payment.brandName">
                <div class="payment-method payment_default payment-item">

                  <span [ngClass]="{'order-table': orderDetails.tableReference}">
                    {{orderDetails.tableReference ? 'mesa: '+orderDetails.tableReference : orderType}}
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-group">
      <perfect-scrollbar class="scrollbar">
      <div class="order-info gutter-tb">
        <div class="order-info-item">
          <span class="order-label">Pedido</span>
            <div class="display">
              <div class="full">
                <ul class="order-info gutter-reset order-items">

                  <ng-container *ngFor="let orderItem of orderDetails.items">
                    <li class="order-info-item order-item">
                      <app-order-details-popup-item [item]="orderItem" class="full"></app-order-details-popup-item>
                    </li>
                  </ng-container>

                </ul>
              </div>
            </div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>
  </div>

  <div class="order-popup-body">
    <div *ngIf="showOrderExtras" class="order-extra-info">
      <div *ngFor="let orderExtra of orderDetails.extras" class="order-extra d-flex justify-content-between">
        <div>{{ orderExtra.label }}</div>
        <div class="payment-value">{{ orderExtra.value | currency: 'BRL' }}</div>
      </div>
    </div>

    <div class="payment-info gutter">
      <div class="order-total d-flex justify-content-between">
        <div>Total:</div>
        <div class="order-payable-value">{{ orderDetails.payableValue | currency: 'BRL':true }}</div>
      </div>

      <div class="order-payments">
            <div *ngIf="orderDetails.payments.length" [ngClass]="paymentCssClassType" class="d-flex justify-content-between payment">
              <div class="payment-method">

                <fa-icon *ngIf="(paymentCssClassType === 'payment_pending')" [icon]="exclamationIcon" class="far fa-exclamation-triangle"></fa-icon>
                <fa-icon *ngIf="(paymentCssClassType === 'payment_completed')" [icon]="checkCircleIcon" class="far fa-check-circle"></fa-icon>

                <div id="paymentStatus">
                  {{getMessageByPaymentStatus(orderDetails.payments)}}
                </div>

                <ul *ngFor="let payment of orderDetails.payments"  class="payment-items">
                  <li class="detail-payment-item" *ngIf="payment.brandName">{{payment.brandName}}</li>
                  <li class="detail-payment-item"  *ngIf="!payment.brandName"> {{payment.paymentMethod}}</li>
                  <li class="payment-value">{{payment.value | currency: 'BRL'}}</li>
               </ul>

                <ul *ngFor="let payment of orderDetails.prepaidPayments"  class="payment-items">
                  <li class="detail-payment-item" *ngIf="payment.brandName">{{payment.brandName}}</li>
                  <li class="detail-payment-item"  *ngIf="!payment.brandName">{{payment.paymentMethod}}</li>
                  <li class="payment-value">{{payment.value | currency: 'BRL'}}</li>
               </ul>

              </div>
            </div>




      </div>
    </div>
  </div>

  <div *ngIf="showPrintButton$ | async" class="d-flex justify-content-between print-container">
    <div id="printOrderButton" class="order-button print-button" (click)="orderPrintButtonClick()">
      Imprimir
    </div>
  </div>
</div>
