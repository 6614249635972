import { environment } from '../../../environments/environment';
import * as companyActions from '../../actions/company.action';
import { Company } from '../../model/company.model';
import { RequestStatus } from './../../../shared/model/request-status.model';

export interface CompanyState {
  // List of companies that is retrieved from the API.
  companies: Array<Company>;

  // Company that is selected by the user.
  selectedCompany: Company;
  isSelectedCompanyOnline: boolean;
  checkCompanyOnlinePollingInterval: number;
  checkCompanyOnlineErrorMsg: string;

  timeout: number;
  errorMsg: string;
  status: RequestStatus;
}

const initialState: CompanyState = {
  companies: new Array<Company>(),
  selectedCompany: undefined,
  isSelectedCompanyOnline: undefined,
  checkCompanyOnlinePollingInterval: 10000,
  checkCompanyOnlineErrorMsg: undefined,
  timeout: environment.defaultTimeout,
  errorMsg: undefined,
  status: undefined,
};

export function companyReducer(state: CompanyState = initialState, action: companyActions.Actions): CompanyState {
  let company: Company;

  switch (action.type) {
    case companyActions.GET_COMPANIES:
      return { ...state, errorMsg: undefined, status: RequestStatus.LOADING };

    case companyActions.GET_COMPANIES_SUCCESS:
      return { ...state, companies: action.payload, errorMsg: undefined, status: RequestStatus.SUCCESS };

    case companyActions.GET_COMPANIES_FAIL:
      return {
        ...state,
        companies: new Array<Company>(),
        errorMsg: 'Erro ao obter as informações das lojas. Por favor tente novamente mais tarde.',
        status: RequestStatus.FAIL,
      };

    case companyActions.LOAD_COMPANY_FROM_CONNECTOR_SERVICE:
    case companyActions.SELECT_COMPANY:
      company = parseCompanyId(action.payload);
      return { ...state, selectedCompany: company, isSelectedCompanyOnline: false };

    case companyActions.SELECT_ONLY_COMPANY:
      company = parseCompanyId(action.payload);

      if (!state.selectedCompany) {
        return { ...state, selectedCompany: company, isSelectedCompanyOnline: false };
      }

      return {
        ...state,
        isSelectedCompanyOnline: false,
        selectedCompany: {
          ...company,
          includeOrderTypes: state.selectedCompany.includeOrderTypes,
          excludeOrderTypes: state.selectedCompany.excludeOrderTypes,
          acceptNewOrders: state.selectedCompany.acceptNewOrders,
        },
      };

    case companyActions.UPDATE_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        selectedCompany: {
          ...action.payload,
          includeOrderTypes: state.selectedCompany.includeOrderTypes,
          excludeOrderTypes: state.selectedCompany.excludeOrderTypes,
          acceptNewOrders: state.selectedCompany.acceptNewOrders,
        },
      };

    case companyActions.LOAD_COMPANY_FROM_COOKIE_SUCCESS:
      company = parseCompanyId(action.payload);
      return { ...state, selectedCompany: company, isSelectedCompanyOnline: false };

    case companyActions.IS_COMPANY_ONLINE:
      return { ...state, checkCompanyOnlineErrorMsg: undefined };

    case companyActions.IS_COMPANY_ONLINE_SUCCESS:
      return { ...state, isSelectedCompanyOnline: action.payload, checkCompanyOnlineErrorMsg: undefined };

    case companyActions.IS_COMPANY_ONLINE_FAIL:
      return { ...state, isSelectedCompanyOnline: false, checkCompanyOnlineErrorMsg: action.payload.message };

    default:
      return state;
  }
}

/**
 * Parses the companie's numerical ID from the token, if it is not available.
 * Note: this function was created, because the connector service does not store the company ID in its
 * configuration file, so this information was missing and we couldn't use it to tell if the company was online
 * or not.
 */
function parseCompanyId(comp: Company): Company {
  // If we already have the numerical ID, just return it.
  if (!comp || comp.numericalId) {
    return comp;
  }

  const token = comp.posToken.replace('Basic ', '');
  const tokenFields = atob(token).split(':');
  return { ...comp, numericalId: Number(tokenFields[0]) };
}
