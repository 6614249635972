import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as localePtBr from 'dayjs/locale/pt-br';

@Pipe({
  name: 'DateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, dateFormat: string): string {
    return dayjs(value).locale(localePtBr).format(dateFormat);
  }
}
