import { ProductAvailability } from '../model/product-availability.model';
import { Product } from '../model/product.model';
import { Action } from '@ngrx/store';

export const LOAD_PRODUCTS = 'load-products';
export const LOAD_PRODUCTS_SUCCESS = 'load-products-success';
export const LOAD_PRODUCTS_FAIL = 'load-products-fail';

export const SET_PRODUCT_UNAVAILABLE = 'set-product-unavailable';
export const SET_PRODUCT_AVAILABLE = 'set-product-available';
export const CHANGE_PRODUCTS_AVAILABILITY_SUCCESS = 'change-products-availability-success';
export const CHANGE_PRODUCTS_AVAILABILITY_FAIL = 'change-products-availability-fail';

export const FILTER_PRODUCTS = 'filter-products';

/**
 * Tries to load all products (available and unavailable) for the chosen company
 */
export class LoadProductsAction implements Action {
  readonly type = LOAD_PRODUCTS;
  constructor() {}
}

/**
 * This action is executed when the products are successfully loaded for a given company.
 */
export class LoadProductsSuccessAction implements Action {
  readonly type = LOAD_PRODUCTS_SUCCESS;
  constructor(public payload: Array<Product>) {}
}

/**
 * This action is executed when there is a problem while loading the products for a given company.
 */
export class LoadProductsFailAction implements Action {
  readonly type = LOAD_PRODUCTS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * Sets the current product (and all that have the same name) as unavailable.
 * Payload: Product
 */
export class SetProductUnavailableAction implements Action {
  readonly type = SET_PRODUCT_UNAVAILABLE;
  constructor(public payload: Product) {}
}

/**
 * Sets the current product (and all that have the same name) as available
 * Payload: Product
 */
export class SetProductAvailableAction implements Action {
  readonly type = SET_PRODUCT_AVAILABLE;
  constructor(public payload: Product) {}
}

/**
 * This action is called when the availability of several products is successfully changed.
 * Payload: ProductAvailability[] - Products that had their avaibility changed.
 */
export class ChangeProductsAvailabilitySuccessAction implements Action {
  readonly type = CHANGE_PRODUCTS_AVAILABILITY_SUCCESS;
  constructor(public payload: ProductAvailability[]) {}
}

/**
 * This action is called when changing the availability of several products results in an error.
 * Payload: Error
 */
export class ChangeProductsAvailabilityFailAction implements Action {
  readonly type = CHANGE_PRODUCTS_AVAILABILITY_FAIL;
  constructor(public payload: Error) {}
}

/**
 * Filters all of the products by a given name.
 * Payload: string
 */
export class FilterProductsAction implements Action {
  readonly type = FILTER_PRODUCTS;
  constructor(public payload: string) {}
}

export type Actions =
  | LoadProductsAction
  | LoadProductsSuccessAction
  | LoadProductsFailAction
  | SetProductUnavailableAction
  | SetProductAvailableAction
  | ChangeProductsAvailabilitySuccessAction
  | ChangeProductsAvailabilityFailAction
  | FilterProductsAction;
