import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyLogisticService {
  constructor(private http: HttpClient) {}

  getCompanyConfig(token: string) {
    const url = `${environment.logisticApiUrl}/api/v1/company-config`;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(url, { headers });
  }
}
