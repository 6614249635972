import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { OrderError } from './../../../shared/model/order-error.model';

@Injectable({
  providedIn: 'root',
})
export class OrderImportService {
  constructor(private http: HttpClient) {}

  public getAllOrdersByImportStaus(
    authToken: string,
    includeOrderType: string[],
    excludeOrderType: string[],
    acceptNewOrders: boolean
  ): Observable<any> {
    const url = `${environment.apiUrl}/v3/connector/order`;

    const headers = new HttpHeaders({ Authorization: authToken });

    const params: any = {
      latest: environment.latest,
      group_by: 'pos_import_status',
    };

    const includeOrderTypeParam = this.createOrderTypeFilterParam(includeOrderType);
    if (includeOrderTypeParam !== '') {
      params.order_type = includeOrderTypeParam;
    }

    const excludeOrderTypeParam = this.createOrderTypeFilterParam(excludeOrderType);
    if (excludeOrderTypeParam !== '') {
      params.ignore_order_type = excludeOrderTypeParam;
    }

    if (acceptNewOrders) {
      params.online = true;
    }

    return this.http.get(url, { headers, params }).pipe(map((response: any) => response.data));
  }

  public updateOrderImportStatus(
    authToken: string,
    orderId: number,
    currentImportStatus: number,
    newImportStatus: number,
    orderError?: OrderError
  ): Observable<any> {
    const url = `${environment.apiUrl}/v3/connector/order/${orderId}`;

    const headers = new HttpHeaders({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });

    const body = {
      posImportStatus: newImportStatus,
      currentPosImportStatus: currentImportStatus,
      error: null,
    };

    if (orderError) {
      body.error = orderError;
    }

    return this.http.put(url, body, { headers });
  }

  /**
   * Concatenates orderTypes array values separating them by ',' or returns an empty string.
   */
  private createOrderTypeFilterParam(orderTypes: string[]): string {
    let orderTypesParam = '';
    let i: number;

    // Ignore empty arrays.
    if (!orderTypes) {
      return '';
    }

    for (i = 0; i < orderTypes.length; i++) {
      if (orderTypesParam === '') {
        orderTypesParam = orderTypes[i];
      } else {
        orderTypesParam = orderTypesParam + ',' + orderTypes[i];
      }
    }

    return orderTypesParam;
  }
}
