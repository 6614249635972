import { Injectable } from '@angular/core';

import * as MobileDetect from 'mobile-detect';

@Injectable()
export class MobileDetectService {
  private mobileDetect = new MobileDetect(navigator.userAgent);

  constructor() {}

  /**
   * Checks if the current device is a mobile device or a computer.
   */
  public isMobileDevice(): boolean {
    const result = this.mobileDetect.mobile();

    if (result) {
      return true;
    } else {
      return false;
    }
  }
}
