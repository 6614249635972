export const LOGISTIC_STATUS_CANCELED = 0;
export const LOGISTIC_STATUS_CREATED = 1;
export const LOGISTIC_STATUS_WAITING_FOR_PICK_UP = 2;
export const LOGISTIC_STATUS_EN_ROUTE_TO_DELIVERY = 3;
export const LOGISTIC_STATUS_DELIVERED = 4;
export const LOGISTIC_STATUS_PARTNER_DENIED = 5;
export const LOGISTIC_STATUS_DELIVERY_FAILED = 6;
export const LOGISTIC_STATUS_UNKNOWN = 7;
export const LOGISTIC_STATUS_SENDING_TO_PARTNER = 8;
export const LOGISTIC_STATUS_PARTNER_CREATED = 9;
