import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import * as Sentry from '@sentry/browser';
import {
  DISPLAY_LOGIN_COMPONENT,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LoginUserAction,
  LoginUserFailAction,
  LoginUserSuccessAction,
} from '../../actions/login.action';
import { AppState } from '../../reducers/app.state';
import { LoginService } from '../../services/login/login.service';

@Injectable()
export class LoginEffect {
  @Effect()
  loginUser$: Observable<Action> = this.actions.pipe(
    ofType(LOGIN_USER),
    withLatestFrom(this.store), // Get the latest state from the store and add as the second value of the array below
    switchMap(([action, state]: any[]) => this.loginUserAction(action, state))
  );

  @Effect({ dispatch: false })
  displayLoginComponent$: Observable<Action> = this.actions.pipe(
    ofType(DISPLAY_LOGIN_COMPONENT),
    tap(() => this.router.navigate(['login']))
  );

  @Effect({ dispatch: false })
  loginSuccess$: Observable<Action> = this.actions.pipe(
    ofType(LOGIN_USER_SUCCESS),
    tap(() => this.router.navigate(['configuration']))
  );

  constructor(private actions: Actions, private loginService: LoginService, private store: Store<AppState>, private router: Router) {}

  /**
   * Execute the login action.
   */
  private loginUserAction(action: LoginUserAction, state: AppState): Observable<LoginUserSuccessAction | LoginUserFailAction> {
    return this.loginService.loginUser(action.payload.username, action.payload.password).pipe(
      timeout(state.login.timeout),
      map((userToken: string) => new LoginUserSuccessAction({ token: userToken })),
      catchError((error) => this.handleError(error))
    );
  }

  private handleError(error): Observable<LoginUserFailAction> {
    const errorMsg = 'Error executing user login';

    if (error.name === 'TimeoutError') {
      console.warn(errorMsg, error);
    } else {
      console.error(errorMsg, error);
      const err: any = new Error(errorMsg);
      err.innerException = error;
      err.error = error.error;
      err.headers = error.headers;
      err.JSON = JSON.stringify(error);
      Sentry.captureException(err);
    }

    return observableOf(new LoginUserFailAction(error));
  }
}
