import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of as observableOf, timer as observableTimer } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { Company } from '../../../app/model/company.model';
import * as state from '../../../app/reducers/app.state';
import * as routerFeatureSelector from '../../../app/reducers/app.feature';
import * as importActions from '../../actions/order-import.action';
import { MixpanelService } from './../../../shared/services/mixpanel/mixpanel.service';
import { OrderImportService } from './../../services/order-import/order-import.service';
import * as Sentry from '@sentry/browser';

@Injectable()
export class OrderImportEffect {
  @Effect()
  loadAllImportOrders$: Observable<Action> = this.actions.pipe(
    ofType(importActions.LOAD_ALL_IMPORT_ORDERS),
    withLatestFrom(this.store$.select(state.importPollingInterval)),
    switchMap(([action, pollingInterval]: [importActions.Actions, number]) => {
      return observableTimer(0, pollingInterval).pipe(
        withLatestFrom(this.store$.select(routerFeatureSelector.selectedCompanySelector)),
        switchMap(([timer, company]: [number, Company]) => this.loadAllImportOrders(pollingInterval, company))
      );
    })
  );

  @Effect()
  updateOrderImportStatus$: Observable<Action> = this.actions.pipe(
    ofType(importActions.UPDATE_ORDER_IMPORT_STATUS),
    withLatestFrom(this.store$.select(state.importStatusUpdateTimeout), this.store$.select(routerFeatureSelector.companyPosTokenSelector)),
    concatMap(([action, timeoutValue, posToken]: any[]) => this.updateOrderImportStatus(action, timeoutValue, posToken))
  );

  @Effect({ dispatch: false })
  notifyOrderImportUpdateSuccess$: Observable<Action> = this.actions.pipe(
    ofType(importActions.UPDATE_ORDER_IMPORT_STATUS_SUCCESS),
    tap((action: importActions.UpdateOrderImportStatusSuccessAction) => {
      this.toastr.success(`Status de importação do pedido: ${action.payload.displayCode} atualizado com sucesso.`);
    })
  );

  @Effect({ dispatch: false })
  notifyOrderImportUpdateFail$: Observable<Action> = this.actions.pipe(
    ofType(importActions.UPDATE_ORDER_IMPORT_STATUS_FAIL),
    withLatestFrom(this.store$.select(state.importStatusUpdateErrorMsg)),
    map(([action, errorMsg]: [Action, string]) => {
      this.toastr.error(`${errorMsg}`, 'Erro ao atualizar o status de importação do pedido:');
      return action;
    })
  );

  constructor(
    private actions: Actions,
    private orderImportService: OrderImportService,
    private store$: Store<state.AppState>,
    private toastr: ToastrService,
    private mixpanel: MixpanelService
  ) {}

  private loadAllImportOrders(
    pollingInterval: number,
    company: Company
  ): Observable<importActions.LoadAllImportOrdersCompleteAction | importActions.LoadAllImportOrdersFailAction> {
    if (!company || !company.posToken) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({ message: errorMsg, category: 'http', level: Sentry.Severity.Warning });
      return observableOf(new importActions.LoadAllImportOrdersFailAction(new Error(errorMsg)));
    }

    return this.orderImportService
      .getAllOrdersByImportStaus(company.posToken, company.includeOrderTypes, company.excludeOrderTypes, company.acceptNewOrders)
      .pipe(
        timeout(pollingInterval),
        map((orders) => new importActions.LoadAllImportOrdersCompleteAction(orders)),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.warn('Timeout error while loading all import orders', error);
          } else {
            const errorMsg = 'Error while loading all import orders';
            console.error(errorMsg, error);

            const err: any = new Error(errorMsg);
            err.innerException = error;
            err.error = error.error;
            err.headers = error.headers;
            err.JSON = JSON.stringify(error);
            Sentry.captureException(err);
          }

          return observableOf(new importActions.LoadAllImportOrdersFailAction(error));
        })
      );
  }

  /**
   * Tries to update an order's import status
   */
  private updateOrderImportStatus(
    action: importActions.UpdateOrderImportStatusAction,
    timeoutValue: number,
    posToken: string
  ): Observable<importActions.UpdateOrderImportStatusSuccessAction | importActions.UpdateOrderImportStatusFailAction> {
    if (!posToken) {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({ message: errorMsg, category: 'http', level: Sentry.Severity.Warning });
      return observableOf(new importActions.UpdateOrderImportStatusFailAction(new Error(errorMsg)));
    }

    const order = action.payload.order;
    const nextImportStatus = action.payload.nextImportStatus;
    const orderError = action.payload.orderError;

    const mixpanelEventData = {
      status: order.status,
      nextImportStatus,
      id: order.numericalId,
      creationDateTime: order.creationDatetime,
      posAcceptedDateTime: order.posAcceptedDatetime,
      readyDateTime: order.readyDatetime,
      customerName: order.customerName,
      displayCode: order.displayCode,
      orderErrorType: undefined,
      orderErrorMsg: undefined,
    };

    if (orderError) {
      mixpanelEventData.orderErrorType = orderError.type;
      mixpanelEventData.orderErrorMsg = orderError.message;
    }

    this.mixpanel.trackEvent('order-import-status-update', mixpanelEventData);

    return this.orderImportService
      .updateOrderImportStatus(posToken, order.numericalId, order.posImportStatus, nextImportStatus, orderError)
      .pipe(
        timeout(timeoutValue),
        map((newOrder) => {
          return new importActions.UpdateOrderImportStatusSuccessAction(newOrder);
        }),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.warn('Timeout error while updating order import status', error);
          } else {
            const errorMsg = 'Error while updating order import status';
            console.error(errorMsg, error);

            const err: any = new Error(errorMsg);
            err.innerException = error;
            err.error = error.error;
            err.headers = error.headers;
            err.JSON = JSON.stringify(error);
            Sentry.captureException(err);
          }

          return observableOf(new importActions.UpdateOrderImportStatusFailAction(error));
        })
      );
  }
}
