import * as Actions from '../../actions/astlectron.action';

export interface AstilectronState {
  isReady: boolean;
}

const initialState: AstilectronState = {
  isReady: false,
};

export function astilectronReducer(state: AstilectronState = initialState, action: Actions.Actions): AstilectronState {
  switch (action.type) {
    case Actions.ASTILECTRON_READY:
      return { ...state, isReady: true };

    default:
      return state;
  }
}
