import { RequestStatus } from 'src/shared/model/request-status.model';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../../actions/company-logistic.action';
import { environment } from 'src/environments/environment';

export interface CompanyLogisticState {
  integrationAvailable: boolean;
  requestStatus: RequestStatus;
  retryInterval: number;
  maxRetries: number;
}

const initialState: CompanyLogisticState = {
  integrationAvailable: false,
  requestStatus: null,
  retryInterval: environment.defaultPollingInterval,
  maxRetries: 3,
};

export const companyLogisticReducer = createReducer(
  initialState,
  on(actions.getCompanyLogisticData, (state) => ({ ...state, requestStatus: RequestStatus.LOADING })),
  on(actions.getCompanyLogisticDataSuccess, (state) => ({ ...state, requestStatus: RequestStatus.SUCCESS, integrationAvailable: true })),
  on(actions.getCompanyLogisticDataFail, (state) => ({ ...state, requestStatus: RequestStatus.FAIL, integrationAvailable: false }))
);
