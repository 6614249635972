import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  AppState,
  reportCSVStatusSelector,
  reportDataSelector,
  reportErrorMsgSelector,
  reportStatusSelector,
} from '../../../app/reducers/app.state';
import { isConnectorServiceReady } from '../../../shared/state/shared.feature';
import { PrintReportAction } from '../../../shared/actions/connector-service.action';
import { LoadCSVReportAction, LoadReportAction, SetReportStatusAction } from '../../actions/report.action';
import { ReportData } from '../../model/report-data.model';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportViewComponent implements OnInit {
  calendarIcon = faCalendar;
  spinnerIcon = faSpinner;

  status$: Observable<RequestStatus>;
  csvStatus$: Observable<RequestStatus>;
  reportData$: Observable<ReportData>;
  errorMsg$: Observable<string>;

  status = RequestStatus;
  reportForm: FormGroup;
  invalidDates: boolean;

  showPrintReportButton$: Observable<boolean>;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit() {
    // Reset the status when the component is loaded.
    this.store.dispatch(new SetReportStatusAction(undefined));
    this.showPrintReportButton$ = this.store.select(isConnectorServiceReady);

    this.status$ = this.store.select(reportStatusSelector);
    this.csvStatus$ = this.store.select(reportCSVStatusSelector);
    this.reportData$ = this.store.select(reportDataSelector);
    this.errorMsg$ = this.store.select(reportErrorMsgSelector);
    this.createForm();
  }

  generateReport() {
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    // Check if we have a valid period.
    if (startDate > endDate) {
      this.invalidDates = true;
    } else {
      this.invalidDates = false;
      this.store.dispatch(new LoadReportAction({ startDate, endDate }));
    }
  }

  exportReport() {
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    // Check if we have a valid period.
    if (startDate > endDate) {
      this.invalidDates = true;
    } else {
      this.invalidDates = false;
      this.store.dispatch(new LoadCSVReportAction({ startDate, endDate }));
    }
  }

  printReport() {
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();
    this.store.dispatch(new PrintReportAction({ startDate, endDate }));
  }

  private createForm() {
    this.reportForm = this.fb.group({
      startDateField: ['', Validators.required],
      endDateField: ['', Validators.required],
      startTimeField: [{ hour: 0, minute: 0, second: 0 }, Validators.required],
      endTimeField: [{ hour: 23, minute: 59, second: 59 }, Validators.required],
    });

    this.invalidDates = false;
  }

  private getStartDate(): Date {
    // Ignore the timezone when creating this date. The correct timezone will ne sorted out by the backend.
    const model = this.reportForm.value;

    return new Date(
      Date.UTC(
        model.startDateField.year,
        model.startDateField.month - 1,
        model.startDateField.day,
        model.startTimeField.hour,
        model.startTimeField.minute,
        model.startTimeField.second
      )
    );
  }

  private getEndDate(): Date {
    // Ignore the timezone when creating this date. The correct timezone will ne sorted out by the backend.
    const model = this.reportForm.value;

    return new Date(
      Date.UTC(
        model.endDateField.year,
        model.endDateField.month - 1,
        model.endDateField.day,
        model.endTimeField.hour,
        model.endTimeField.minute,
        model.endTimeField.second
      )
    );
  }
}
