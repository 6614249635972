import { RequestStatus } from '../../../shared/model/request-status.model';
import * as actions from '../../actions/loyalty-points.action';
import { isErrorDescriptionString } from '../../model/loyalty-points-error.model';
import { environment } from './../../../environments/environment';
import { CreditRewardsData } from './../../model/credit-rewards-data.model';

export interface LoyaltyPointsState {
  creditRewardsData: CreditRewardsData;
  getCreditRewardsStatus: RequestStatus;
  getCreditRewardsErrorMsg: string;

  postCreditRewardsStatus: RequestStatus;
  postCreditRewardsErrorMsg: string;
  timeout: number;
}

const initialState: LoyaltyPointsState = {
  creditRewardsData: {
    cardBrands: [],
    paymentMethods: [],
    products: [],
  },
  getCreditRewardsStatus: undefined,
  getCreditRewardsErrorMsg: '',

  postCreditRewardsStatus: undefined,
  postCreditRewardsErrorMsg: '',
  timeout: environment.defaultTimeout,
};

export function loyaltyPointsReducer(state: LoyaltyPointsState = initialState, action: actions.Actions): LoyaltyPointsState {
  let errorMsg: string;

  switch (action.type) {
    case actions.GET_CREDIT_REWARDS_PRODUCTS:
      return { ...state, getCreditRewardsStatus: RequestStatus.LOADING };

    case actions.GET_CREDIT_REWARDS_PRODUCTS_SUCCESS:
      return { ...state, getCreditRewardsStatus: RequestStatus.SUCCESS, creditRewardsData: action.payload, getCreditRewardsErrorMsg: '' };

    case actions.GET_CREDIT_REWARDS_PRODUCTS_FAIL:
      if (action.payload.type === 'onyo.company.not-configured') {
        errorMsg = 'Loja não está configurada para compra de pontos.';
      } else {
        errorMsg = 'Erro ao obter os produtos para compra de pontos.';
      }

      return {
        ...state,
        getCreditRewardsStatus: RequestStatus.FAIL,
        getCreditRewardsErrorMsg: errorMsg,
        creditRewardsData: {
          cardBrands: [],
          paymentMethods: [],
          products: [],
        },
      };

    case actions.CREDIT_REWARDS:
      return { ...state, postCreditRewardsStatus: RequestStatus.LOADING };

    case actions.CREDIT_REWARDS_SUCCESS:
      return { ...state, postCreditRewardsStatus: RequestStatus.SUCCESS, postCreditRewardsErrorMsg: '' };

    case actions.CREDIT_REWARDS_FAIL:
      // If we received the error in the format we are looking for
      if (action.payload.description) {
        if (isErrorDescriptionString(action.payload.description)) {
          errorMsg = action.payload.description;
        } else {
          errorMsg = 'Erro: ';
          if (action.payload.description.points) {
            errorMsg = `${errorMsg} ${action.payload.description.points[0]}`;
          }
          if (action.payload.description.customerDocument) {
            errorMsg = `${errorMsg} ${action.payload.description.customerDocument[0]}`;
          }
          if (action.payload.description.managerPassword) {
            errorMsg = `${errorMsg} ${action.payload.description.managerPassword[0]}`;
          }
          if (action.payload.description.nonFieldErrors) {
            errorMsg = `${errorMsg} ${action.payload.description.nonFieldErrors[0]}`;
          }
        }
      } else {
        errorMsg = 'Erro ao realizar operação, por favor tente novamente ou entre em contato com o suporte.';
      }

      return { ...state, postCreditRewardsStatus: RequestStatus.FAIL, postCreditRewardsErrorMsg: errorMsg };

    default:
      return state;
  }
}
