import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCpf',
})
export class FormatCpfPipe implements PipeTransform {
  transform(value: any): string {
    let cpf = value.toString();

    // Calculate the number of left side zeros.
    const numZeros = Math.abs(11 - cpf.length);
    cpf = '0'.repeat(numZeros) + cpf;

    const firstGroup = cpf.substring(0, 3);
    const secondGroup = cpf.substring(3, 6);
    const thirdGroup = cpf.substring(6, 9);
    const verification = cpf.substring(9, 12);

    return `${firstGroup}.${secondGroup}.${thirdGroup}-${verification}`;
  }
}
