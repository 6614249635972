<div class="order-popup-view">
  <div class="modal-header">
    <h4 class="modal-title popup-title">Pedido #{{ order.numericalId }}</h4>
    <span class="modal-text order-date">{{order.creationDatetime | DateFormatPipe:'DD [de] MMMM [de] YYYY [às] HH:mm'}}</span>
    <span id="btn-close" (click)="activeModal.dismiss()">Fechar</span>
  </div>

  <div [ngSwitch]="status$ | async">
    <ng-template [ngSwitchCase]="status.LOADING">
      <div class="modal-body text-center spinner-container">
        <fa-icon id="spinner" [icon]="spinnerIcon" size="2x" [pulse]="true"></fa-icon>
      </div>
    </ng-template>

    <ng-template [ngSwitchCase]="status.SUCCESS">
      <app-order-details-popup class="order-details" [orderDetails]="orderDetails$ | async"> </app-order-details-popup>
    </ng-template>

    <ng-template [ngSwitchCase]="status.FAIL">
      <div class="modal-body text-center error-container">
        <p class="error-msg">{{ errorMsg$ | async }}</p>
        <button class="btn try-again-button" (click)="tryAgain()">Tentar novamente</button>
      </div>
    </ng-template>
  </div>
</div>
