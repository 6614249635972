<div class="available-products-component m-2">
  <div class="title-text text-center p-2 mr-3 ml-3">Disponíveis</div>

  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronUpIcon" size="lg" class="arrow-up-button" (click)="scrollUp($event)"></fa-icon>
  <perfect-scrollbar [class.available-products-list-scrollbar]="!isMobileDevice">
    <div class="available-products-list" [class.list-padding]="!isMobileDevice" [class.available-products-list-height]="!isMobileDevice">
      <div class="product-list">
        <div *ngFor="let product of availableProducts" class="product p-2 mr-3 ml-3">
          <app-available-product [product]="product"></app-available-product>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronDownIcon" class="arrow-down-button" (click)="scrollDown($event)"></fa-icon>
</div>
