<div class="configuration-view container text-center">
  <div class="configuration-title">Configuração</div>

  <div [ngSwitch]="status$ | async" class="configuration-content p-2">
    <ng-template [ngSwitchCase]="status.LOADING">
      <fa-icon id="spinner" [icon]="spinnerIcon" [pulse]="true" size="3x"></fa-icon>
    </ng-template>

    <ng-template [ngSwitchCase]="status.SUCCESS">
      <form [formGroup]="companyForm" (ngSubmit)="selectCompany()">
        <hr />
        <div class="mb-2">
          <div class="form-subtitle">Loja</div>
        </div>

        <div class="form-row relative-box">
          <div class="form-group col">
            <input type="text" class="form-control" [ngValue]="companyName" formControlName="companyName"
              (keyup)="onFilterByValue($event)" 
              (focus)="onChangeVisibility($event)"
              placeholder="Informe o nome da loja">
          </div>

          <ul class="company-dropdown fixed-drop-down shadow-drop-down" [ngClass]="{'hide': hideCompanies === true }">
            <li *ngFor="let company of companies" (click)="setCompany(company)" class="box-list-item"
              [ngClass]="{'active': (companyName === company.name)}">
              {{ company.name }}
            </li>
          </ul>
        </div>


        <div *ngIf="isOnyoca" class="order-type-filters">
          <hr />

          <div class="mb-2">
            <div class="form-subtitle">Tipos de Pedidos</div>
            <small class="text-muted">Opcional: se nenhum valor for escolhido, todos os tipos de pedidos serão
              processados.</small>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <label for="include-order-types-filter" class="form-label">Receber Somente Tipos Selecionados</label>
              <select multiple id="include-order-types-filter" class="form-control company-dropdown"
                formControlName="includeOrderTypes">
                <option *ngFor="let type of includeOrderTypes" [ngValue]="type">{{ type }}</option>
              </select>
              <small class="form-text text-muted">Somente os tipos de pedidos selecionados serão processados.</small>
            </div>

            <div class="form-group col">
              <label for="exclude-order-types-filter" class="form-label">Não Receber os Tipos Selecionados</label>
              <select multiple id="exclude-order-types-filter" class="form-control company-dropdown"
                formControlName="excludeOrderTypes">
                <option *ngFor="let type of excludeOrderTypes" [ngValue]="type">{{ type }}</option>
              </select>
              <small class="form-text text-muted">Todos os tipos de pedidos, menos os selecionados, serão
                processados.</small>
            </div>
          </div>

          <hr />

          <div class="mb-2">
            <div class="form-subtitle">Deixar a Loja Online / Receber Pedidos com o Conector Web</div>
            <small class="text-muted">Opcional: só deve ser usado sem o serviço do conector e sem integração.</small>
          </div>

          <div class="row form-group">
            <div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="accept-orders-checkbox"
                  formControlName="acceptOrders" />
                <label for="accept-orders-checkbox" class="form-check-label">Deixar a loja online?</label>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="form-row">
          <div class="form-group col">
            <button class="btn ok-button" type="submit" [disabled]="companyForm.invalid">Ok</button>
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template [ngSwitchCase]="status.FAIL">
      <div>
        <p class="error-msg">{{ errorMsg$ | async }}</p>
        <button class="btn try-again-button" (click)="tryAgain()">Tentar novamente</button>
      </div>
    </ng-template>
  </div>
</div>