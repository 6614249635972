export const ORDER_TYPE_COUNTER = 1;
export const ORDER_TYPE_TO_GO = 2;
export const ORDER_TYPE_CURBSIDE = 3;
export const ORDER_TYPE_TABLE = 4;
export const ORDER_TYPE_DELIVERY = 5;
export const ORDER_TYPE_CARD = 6;
export const ORDER_TYPE_PAYMENT = 7;
export const ORDER_TYPE_COUPON = 8;
export const ORDER_TYPE_ROOM_SERVICE = 9;
export const ORDER_TYPE_PREPAID_PAYMENT = 10;
export const ORDER_TYPE_TRUCK_STOP = 11;

export const ORDER_TYPE_STR_COUNTER = 'counter';
export const ORDER_TYPE_STR_TO_GO = 'to-go';
export const ORDER_TYPE_STR_CURBSIDE = 'curbside';
export const ORDER_TYPE_STR_TABLE = 'table';
export const ORDER_TYPE_STR_DELIVERY = 'delivery';
export const ORDER_TYPE_STR_CARD = 'card';
export const ORDER_TYPE_STR_PAYMENT = 'payment';
export const ORDER_TYPE_STR_COUPON = 'coupon';
export const ORDER_TYPE_STR_ROOM_SERVICE = 'room-service';
export const ORDER_TYPE_STR_PREPAID_PAYMENT = 'prepaid-points';
export const ORDER_TYPE_STR_TRUCK_STOP = 'truck-stop';
