import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  transform(value: string, args?: string): string {
    const date1 = new Date(value);
    let date2: Date;

    if (!args) {
      date2 = new Date();
    } else {
      date2 = new Date(args);
    }

    // Convert dates to UTC
    const utcDate1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
      date1.getHours(),
      date1.getMinutes(),
      date1.getSeconds(),
      date1.getMilliseconds()
    ); // tslint:disable-line max-line-length
    const utcDate2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
      date2.getHours(),
      date2.getMinutes(),
      date2.getSeconds(),
      date2.getMilliseconds()
    ); // tslint:disable-line max-line-length

    let diff = Math.abs(utcDate1 - utcDate2); // Get difference in milliseconds.
    diff = diff / (1000 * 60 * 60); // Transform to hours.

    const hours = Math.floor(diff);
    const minutes = Math.floor((diff % 1) * 60);

    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
  }
}
