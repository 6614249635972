<div class="container login-form">
  <h2 class="login-title text-center">Login</h2>

  <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
    <div class="form-group">
      <label for="username">Usuário</label>
      <input type="text" class="form-control username-field" formControlName="username" />
    </div>

    <div class="form-group">
      <label for="password">Senha</label>
      <input type="password" class="form-control password-field" formControlName="password" />
    </div>

    <div class="form-group d-flex" [class.has-danger]="loginError">
      <div class="p-1">
        <button type="submit" class="btn form-btn submit-button" [disabled]="loginForm.invalid || executingLogin">
          <span>Entrar</span>
          <fa-icon id="spinner" [icon]="spinnerIcon" [pulse]="true" [hidden]="!executingLogin" class="px-1"></fa-icon>
        </button>
      </div>
      <div class="p-1">
        <span [hidden]="!loginError" class="form-control-feedback error-msg">{{ loginErrorMsg }}</span>
      </div>
    </div>
  </form>
</div>