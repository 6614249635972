export const PREPARING = 1;
export const STOPPED = 2;
export const READY = 3;
export const DONE = 4;
export const CANCELLED = 5;

export const PREPARING_ORDERS = new Set<number>([4, 13]);
export const STOPPED_ORDERS = new Set<number>([15, 16]);
export const READY_ORDERS = new Set<number>([6, 7]);
export const DONE_ORDERS = new Set<number>([8, 9, 10, 11, 12, 14]);
export const CANCELLED_ORDERS = new Set<number>([0, 5]);
