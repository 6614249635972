import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StaffGetMemberService {
  constructor(private http: HttpClient) {}

  public sendCellphoneNumber(token: string, cellphoneNumber: string): Observable<any> {
    const url = `${environment.apiUrl}/v1/connector/sms_temporary_user`;

    const headers = new HttpHeaders({
      Authorization: token,
      'Content-Type': 'application/json',
    });

    const reqData = {
      telephone: cellphoneNumber,
    };

    return this.http.post(url, reqData, { headers });
  }
}
