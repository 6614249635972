import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  public getReportData(authToken: string, startDate: Date, endDate: Date): Observable<any | Error> {
    const url = `${environment.apiUrl}/v1/connector/report`;

    const headers = new HttpHeaders({
      Authorization: authToken,
    });

    const params: any = {
      csv: false,
      start_date: startDate.toISOString().substring(0, 19),
      end_date: endDate.toISOString().substring(0, 19),
    };

    return this.http.get(url, { params, headers }).pipe(map((response: any) => response.data));
  }

  public getCSVReportData(authToken: string, startDate: Date, endDate: Date): Observable<any | Error> {
    const url = `${environment.apiUrl}/v1/connector/report`;

    const headers = new HttpHeaders({ Authorization: authToken });

    const params: any = {
      csv: true,
      start_date: startDate.toISOString().substring(0, 19),
      end_date: endDate.toISOString().substring(0, 19),
    };

    return this.http.get(url, { params, headers, responseType: 'text' });
  }
}
