<div class="report-table">
  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronUpIcon" class="report-arrow-up-button" (click)="scrollUp($event)"></fa-icon>
  <perfect-scrollbar class="report-scrollbar" [disabled]="isMobileDevice">
    <div class="report-content" [class.desktop-table]="!isMobileDevice">
      <table class="table table-striped table-responsive-sm">
        <thead>
          <tr class="table-header">
            <th class="table-text text-center">Bandeira</th>
            <th class="table-text text-center">Num. Pedidos</th>
            <th class="table-text text-center">Valor Total</th>
            <th class="table-text text-center">Valor Pago</th>
            <th class="table-text text-center">Valor Cupom</th>
            <th class="table-text text-center">Valor Desconto</th>
            <th class="table-text text-center">Valor Pontos Pré-Pagos</th>
          </tr>
        </thead>

        <tbody class="table-body">
          <tr *ngFor="let payment of data.payments" class="table-text text-center payment-row">
            <td class="table-cell brand-cell align-middle">{{ payment.cardBrand }}</td>
            <td class="table-cell align-middle">{{ payment.ordersCount }}</td>
            <td class="table-cell align-middle">{{ payment.totalValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ payment.payableValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ payment.couponValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ payment.discountValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ payment.prepaidRedeemValue | currency: 'BRL' }}</td>
          </tr>

          <tr class="table-text text-center total-row">
            <td class="table-cell brand-cell align-middle">{{ data.total.cardBrand }}</td>
            <td class="table-cell align-middle">{{ data.total.ordersCount }}</td>
            <td class="table-cell align-middle">{{ data.total.totalValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ data.total.payableValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ data.total.couponValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ data.total.discountValue | currency: 'BRL' }}</td>
            <td class="table-cell align-middle">{{ data.total.prepaidRedeemValue | currency: 'BRL' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </perfect-scrollbar>
  <fa-icon *ngIf="!isMobileDevice" [icon]="chevronDownIcon" class="report-arrow-down-button" (click)="scrollDown($event)"></fa-icon>
</div>
