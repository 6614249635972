<table class="table table-striped table-sm order-table">
  <thead>
    <tr>
      <th colspan="6" class="table-title text-center">
        {{ tableTitle }} ({{ this.ordersStatus.length }})
        <fa-icon
          *ngIf="allowCollapse"
          [icon]="caretDownIcon"
          size="lg"
          class="collapse-button p-1"
          [class.fa-rotate-270]="isCollapsed"
          (click)="collapse()"
        ></fa-icon>
      </th>
    </tr>

    <tr [ngbCollapse]="isCollapsed" class="table-header">
      <th scope="col" class="table-text"></th>
      <th scope="col" class="table-text text-center">Pedido / Mesa</th>
      <th scope="col" class="table-text text-center">Cliente</th>
      <th scope="col" class="table-text text-center">Hora / Duração</th>
      <th scope="col" class="table-text text-center">Logística</th>
      <th scope="col" class="table-text text-center">Ação</th>
    </tr>

    <tr></tr>
  </thead>

  <tbody [ngbCollapse]="isCollapsed" class="table-body">
    <tr
      appOrder
      [order]="status.order"
      [logistic]="status.logistic"
      *ngFor="let status of ordersStatus"
      class="table-text text-center order-row"
    ></tr>
  </tbody>
</table>
