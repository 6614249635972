import { LOAD_ALL_ORDERS_FAIL } from './order.action';
import { Company } from '../model/company.model';
import { Action } from '@ngrx/store';

export const GET_COMPANIES = 'get-companies';
export const GET_COMPANIES_SUCCESS = 'get-companies-success';
export const GET_COMPANIES_FAIL = 'get-companies-fail';
export const SELECT_COMPANY = 'select-company';
export const SELECT_ONLY_COMPANY = 'select-only-company';
export const UPDATE_COMPANY_CONFIG = 'update-company-config';
export const UPDATE_COMPANY_CONFIG_SUCCESS = 'update-company-config-success';
export const UPDATE_COMPANY_CONFIG_FAIL = 'update-company-config-fail';
export const LOAD_COMPANY_FROM_COOKIE = 'load-company-from-cookie';
export const LOAD_COMPANY_FROM_COOKIE_SUCCESS = 'load-company-from-cookie-success';
export const LOAD_COMPANY_FROM_COOKIE_FAIL = 'load-company-from-cookie-fail';
export const LOAD_COMPANY_FROM_CONNECTOR_SERVICE = 'load-company-from-connector-service';
export const IS_COMPANY_ONLINE = 'is-company-online';
export const IS_COMPANY_ONLINE_SUCCESS = 'is-company-online-success';
export const IS_COMPANY_ONLINE_FAIL = 'is-company-online-fail';

/**
 * Tries to retrieve the list of companies.
 * Payload: N/A
 */
export class GetCompaniesAction implements Action {
  readonly type = GET_COMPANIES;
  constructor() {}
}

/**
 * This action is called when the retrieval of the company information is successful.
 * Payload: Array<Company> - an array of company objects.
 */
export class GetCompaniesSuccessAction implements Action {
  readonly type = GET_COMPANIES_SUCCESS;
  constructor(public payload: Array<Company>) {}
}

/**
 * This action is called when the retrieval of the company information fails.
 * Payload: Error
 */
export class GetCompaniesFailAction implements Action {
  readonly type = GET_COMPANIES_FAIL;
  constructor(public payload: Error) {}
}

/**
 * Selects a company to use in the connector.
 * Payload: Company - the company that was selected by the user.
 */
export class SelectCompanyAction implements Action {
  readonly type = SELECT_COMPANY;
  constructor(public payload: Company) {}
}

/**
 * Selects only a company to use in the connector (will not change the order type filters)
 * This action is used by non Onyoca users, that do not have a full system access.
 * Payload: Company - the company that was selected by the user.
 */
export class SelectOnlyCompanyAction implements Action {
  readonly type = SELECT_ONLY_COMPANY;
  constructor(public payload: Company) {}
}

/**
 * Tries to retrieve the previous selected company from a cookie.
 * Payload: N/A
 */
export class LoadCompanyFromCookieAction implements Action {
  readonly type = LOAD_COMPANY_FROM_COOKIE;
  constructor() {}
}

/**
 * This action is called when the previously selected company was successfully loaded from a cookie.
 * Payload: Company - the previously selected company.
 */
export class LoadCompanyFromCookieSuccessAction implements Action {
  readonly type = LOAD_COMPANY_FROM_COOKIE_SUCCESS;
  constructor(public payload: Company) {}
}

/**
 * This action is called when the previously selected company could not be loaded from a cookie.
 * Note: the most common reason for the loading to fail is that the cookie wasn't created yet.
 * Payload: Error
 */
export class LoadCompanyFromCookieFailAction implements Action {
  readonly type = LOAD_ALL_ORDERS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action is called to check if the chosen company is online.
 */
export class IsCompanyOnlineAction implements Action {
  readonly type = IS_COMPANY_ONLINE;
  constructor() {}
}

/**
 * This action is called when the online/offline check for the selected company
 * was done successfully.
 * Payload: boolean - online = true / offline = false
 */
export class IsCompanyOnlineSuccessAction implements Action {
  readonly type = IS_COMPANY_ONLINE_SUCCESS;
  constructor(public payload: boolean) {}
}

/**
 * This action is called when the online/offline check for the selected company fails.
 * Payload: error
 */
export class IsCompanyOnlineFailAction implements Action {
  readonly type = IS_COMPANY_ONLINE_FAIL;
  constructor(public payload: Error) {}
}

export class UpdateCompanyConfig implements Action {
  readonly type = UPDATE_COMPANY_CONFIG;
}

export class UpdateCompanyConfigSuccess implements Action {
  readonly type = UPDATE_COMPANY_CONFIG_SUCCESS;
  constructor(public payload: Company) {}
}

export class UpdateCompanyConfigFail implements Action {
  readonly type = UPDATE_COMPANY_CONFIG_FAIL;
  constructor(public payload: any) {}
}

export class LoadCompanyFromConnectorService implements Action {
  readonly type = LOAD_COMPANY_FROM_CONNECTOR_SERVICE;
  constructor(public payload: Company) {}
}

export type Actions =
  | GetCompaniesAction
  | GetCompaniesSuccessAction
  | GetCompaniesFailAction
  | SelectCompanyAction
  | SelectOnlyCompanyAction
  | LoadCompanyFromCookieAction
  | LoadCompanyFromCookieSuccessAction
  | LoadCompanyFromCookieFailAction
  | IsCompanyOnlineAction
  | IsCompanyOnlineSuccessAction
  | IsCompanyOnlineFailAction
  | UpdateCompanyConfig
  | UpdateCompanyConfigSuccess
  | UpdateCompanyConfigFail
  | LoadCompanyFromConnectorService;
