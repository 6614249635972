<div class="report-view">
  <form [formGroup]="reportForm" class="report-form p-2">
    <div class="form-row align-items-center m-2">
      <div class="mt-1 mb-1" [class.text-danger]="invalidDates">
        <span for="startDateField">Data de Início:</span>
        <div class="d-flex align-items-center">
          <div class="input-group mr-2">
            <input
              id="startDateField"
              readonly
              ngbDatepicker
              #startDatepicker="ngbDatepicker"
              class="date-field form-control"
              [class.alert-danger]="invalidDates"
              placeholder="dd/mm/aaaa"
              formControlName="startDateField"
              (click)="startDatepicker.toggle()"
            />

            <div class="input-group-append">
              <button
                class="btn input-group-button py-0"
                (click)="startDatepicker.toggle()"
                [class.alert-danger]="invalidDates"
                type="button"
              >
                <fa-icon [icon]="calendarIcon" class="calendar-icon"></fa-icon>
              </button>
            </div>
          </div>

          <div class="ml-2">
            <ngb-timepicker formControlName="startTimeField" [seconds]="true" class="report-timepicker"></ngb-timepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row align-items-center m-2">
      <div class="mt-1 mb-1" [class.text-danger]="invalidDates">
        <span for="endDateField">Data de Fim:</span>
        <div class="d-flex align-items-center">
          <div class="input-group mr-2">
            <input
              id="endDateField"
              readonly
              ngbDatepicker
              #endDatepicker="ngbDatepicker"
              class="date-field form-control"
              [class.alert-danger]="invalidDates"
              placeholder="dd/mm/aaaa"
              formControlName="endDateField"
              (click)="endDatepicker.toggle()"
            />

            <div class="input-group-append">
              <button
                class="btn input-group-button py-0"
                (click)="endDatepicker.toggle()"
                [class.alert-danger]="invalidDates"
                type="button"
              >
                <fa-icon [icon]="calendarIcon" class="calendar-icon"></fa-icon>
              </button>
            </div>
          </div>

          <div class="ml-2">
            <ngb-timepicker formControlName="endTimeField" [seconds]="true" class="report-timepicker"></ngb-timepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row align-items-center">
      <div class="m-2 align-self-end">
        <button
          id="generate-report-button"
          class="btn btn-secondary report-button"
          [disabled]="reportForm.invalid || (status$ | async) === status.LOADING"
          (click)="generateReport()"
        >
          Gerar
          <fa-icon id="load-spinner" [icon]="spinnerIcon" [pulse]="true" [hidden]="(status$ | async) !== status.LOADING"></fa-icon>
        </button>
      </div>

      <div class="m-2 align-self-end">
        <button
          id="export-report-button"
          class="btn btn-secondary report-button"
          [disabled]="reportForm.invalid || (csvStatus$ | async) === status.LOADING"
          (click)="exportReport()"
        >
          Exportar
          <fa-icon id="export-spinner" [icon]="spinnerIcon" [pulse]="true" [hidden]="(csvStatus$ | async) !== status.LOADING"></fa-icon>
        </button>
      </div>

      <div *ngIf="showPrintReportButton$ | async" class="m-2 align-self-end">
        <button
          id="print-report-button"
          class="btn btn-secondary report-button"
          [disabled]="(status$ | async) !== status.SUCCESS"
          (click)="printReport()"
        >
          Imprimir
        </button>
      </div>
    </div>

    <div id="invalid-date-text" [hidden]="!invalidDates" class="form-row text-danger p-2">
      A data de início é maior que a data de fim.
    </div>
  </form>

  <div [ngSwitch]="status$ | async">
    <ng-template [ngSwitchCase]="status.SUCCESS">
      <app-report-table [data]="reportData$ | async" class="report-table"></app-report-table>
    </ng-template>

    <ng-template [ngSwitchCase]="status.FAIL">
      <div class="p-2">
        <p class="error-msg">{{ errorMsg$ | async }}</p>
      </div>
    </ng-template>
  </div>
</div>
