import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as appState from '../../../app/reducers/app.state';
import { LoadOrderDetailsAction } from '../../actions/order-details.action';
import { AppState } from './../../../app/reducers/app.state';
import { OrderDetails } from './../../../shared/model/order-details.model';
import { Order } from './../../../shared/model/order.model';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-details-popup-view',
  templateUrl: './order-details-popup-view.component.html',
  styleUrls: ['./order-details-popup-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsPopupViewComponent implements OnInit {
  timesIcon = faTimesCircle;
  spinnerIcon = faSpinner;

  status = RequestStatus;
  orderDetails$: Observable<OrderDetails>;
  status$: Observable<RequestStatus>;
  errorMsg$: Observable<string>;

  order: Order;
  showOrderErrorButton: boolean;
  showResendOrderReadyPushButton: boolean;

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {}

  ngOnInit() {
    this.orderDetails$ = this.store.select(appState.orderDetailsSelector);
    this.status$ = this.store.select(appState.orderDetailsStatusSelector);
    this.errorMsg$ = this.store.select(appState.orderDetailsErrorMsgSelector);
    this.store.dispatch(new LoadOrderDetailsAction(this.order.numericalId));
  }

  tryAgain() {
    this.store.dispatch(new LoadOrderDetailsAction(this.order.numericalId));
  }
}
