import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import * as orderType from '../../../shared/model/order-type.model';
import * as companyActions from '../../actions/company.action';
import { Company } from '../../model/company.model';
import * as state from '../../reducers/app.state';
import * as routerFeatureSelector from '../../reducers/app.feature';
import { RequestStatus } from './../../../shared/model/request-status.model';
import { takeUntil } from 'rxjs/operators';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'app-configuration-view',
  templateUrl: './configuration-view.component.html',
  styleUrls: ['./configuration-view.component.scss'],
})
export class ConfigurationViewComponent implements OnInit, OnDestroy {
  spinnerIcon = faSpinner;

  companyForm: FormGroup;
  status = RequestStatus;
  includeOrderTypes: string[];
  excludeOrderTypes: string[];

  isOnyoca: boolean;
  onDestroy$: Subject<void>;
  companyList$: Observable<Company[]>;
  companies: Company[];
  errorMsg$: Observable<string>;
  status$: Observable<RequestStatus>;

  filterValue: string;
  companyName: string;
  hideCompanies: boolean;

  constructor(private store: Store<state.AppState>, private formBuilder: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.hideCompanies = true;
    this.onDestroy$ = new Subject<void>();
    this.createForm();
    this.createOrderTypeArray();

    this.companyList$ = this.store.select(routerFeatureSelector.companyListSelector);
    this.errorMsg$ = this.store.select(routerFeatureSelector.companyErrorMsgSelector);
    this.status$ = this.store.select(routerFeatureSelector.companyStatusSelector);
    this.store.dispatch(new companyActions.GetCompaniesAction());

    this.setCompaniesOnInit();

    this.isOnyoca = true;
    this.store
      .select(routerFeatureSelector.isOnyoca)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isOnyoca: boolean) => {
        this.isOnyoca = isOnyoca;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  createForm() {
    this.companyForm = this.formBuilder.group({
      companyName: [],
      company: ['', Validators.required],
      includeOrderTypes: new FormControl([]),
      excludeOrderTypes: new FormControl([]),
      acceptOrders: [false],
    });
  }

  createOrderTypeArray() {
    this.includeOrderTypes = [
      orderType.ORDER_TYPE_STR_COUNTER,
      orderType.ORDER_TYPE_STR_TO_GO,
      orderType.ORDER_TYPE_STR_CURBSIDE,
      orderType.ORDER_TYPE_STR_TABLE,
      orderType.ORDER_TYPE_STR_DELIVERY,
      orderType.ORDER_TYPE_STR_CARD,
      orderType.ORDER_TYPE_STR_PAYMENT,
      orderType.ORDER_TYPE_STR_COUPON,
      orderType.ORDER_TYPE_STR_ROOM_SERVICE,
      orderType.ORDER_TYPE_STR_PREPAID_PAYMENT,
      orderType.ORDER_TYPE_STR_TRUCK_STOP,
    ];

    this.excludeOrderTypes = this.includeOrderTypes;
  }

  tryAgain() {
    this.store.dispatch(new companyActions.GetCompaniesAction());
  }

  selectCompany() {
    const formModel = this.companyForm.value;

    const selectedCompany: Company = cloneDeep(formModel.company);
    selectedCompany.includeOrderTypes = clone(formModel.includeOrderTypes);
    selectedCompany.excludeOrderTypes = clone(formModel.excludeOrderTypes);
    selectedCompany.acceptNewOrders = formModel.acceptOrders;

    if (this.isOnyoca) {
      this.store.dispatch(new companyActions.SelectCompanyAction(selectedCompany));
    } else {
      this.store.dispatch(new companyActions.SelectOnlyCompanyAction(selectedCompany));
    }

    this.router.navigateByUrl('orders');
  }

  onFilterByValue(event): void {
    const term = event.target.value;
    const termExp = new RegExp(`${term}`, 'ig');

    this.companyList$.forEach((groupCompany) => {
      this.companies = groupCompany.filter((company) => {
        if (termExp.test(company.name)) {
          return company;
        }
      });
    });

    this.hideCompanies = false;
  }

  onChangeVisibility(event) {
    this.hideCompanies = false;
  }

  setCompaniesOnInit() {
    this.hideCompanies = true;

    this.store
      .select(routerFeatureSelector.companyListSelector)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((companies) => {
        this.companies = companies;
      });
  }

  setCompany(company) {
    this.companyName = company.name || '';
    this.companyForm.controls.company.setValue(company);
    this.companyForm.controls.companyName.setValue(company.name);
    this.hideCompanies = true;
  }
}
