import { IntegrationComponent } from './../../integration/integration.component';
import { UnavailableProductsViewComponent } from '../../unavailable-products/components/unavailable-products-view/unavailable-products-view.component';
import { LoginViewComponent } from '../components/login-view/login-view.component';
import { AuthGuardService } from '../services/auth-guard/auth-guard.service';
import { ConfigurationViewComponent } from '../components/configuration-view/configuration-view.component';
import { OrderViewComponent } from '../components/order-view/order-view.component';
import { environment } from '../../environments/environment.prod';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportComponent } from '../../report/report.component';
import { LoyaltyPointsViewComponent } from '../../loyalty-points/components/loyalty-points-view/loyalty-points-view.component';

const appRoutes: Routes = [
  { path: 'orders', component: OrderViewComponent },
  { path: 'integration', component: IntegrationComponent },
  { path: 'loyalty-points', component: LoyaltyPointsViewComponent },
  { path: 'unavailable-products', component: UnavailableProductsViewComponent },
  { path: 'report', component: ReportComponent },
  { path: 'configuration', component: ConfigurationViewComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginViewComponent },
  { path: '', redirectTo: 'orders', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: environment.enableRouteTracing })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
