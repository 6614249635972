import * as diacritics from 'diacritics';

import { environment } from '../../../environments/environment';
import * as unavailableActions from '../../actions/unavailable-product.action';
import { ProductAvailability } from '../../model/product-availability.model';
import { Product } from '../../model/product.model';
import { RequestStatus } from './../../../shared/model/request-status.model';
import cloneDeep from 'lodash/cloneDeep';

export interface UnavailableProductsState {
  availableProducts: Map<number, Product>;
  filteredAvailableProducts: Map<number, Product>;

  unavailableProducts: Map<number, Product>;
  filteredUnavailableProducts: Map<number, Product>;

  timeout: number;

  // Note: the fields below are only used by the load products actions.
  errorMsg: string;
  status: RequestStatus;
}

const initialState: UnavailableProductsState = {
  availableProducts: new Map<number, Product>(),
  filteredAvailableProducts: new Map<number, Product>(),

  unavailableProducts: new Map<number, Product>(),
  filteredUnavailableProducts: new Map<number, Product>(),

  timeout: environment.defaultTimeout,
  errorMsg: undefined,
  status: undefined,
};

export function unavailableProductsReducer(
  state: UnavailableProductsState = initialState,
  action: unavailableActions.Actions
): UnavailableProductsState {
  let availableProducts: Map<number, Product>;
  let filteredAvailableProducts: Map<number, Product>;
  let unavailableProducts: Map<number, Product>;
  let filteredUnavailableProducts: Map<number, Product>;

  switch (action.type) {
    case unavailableActions.LOAD_PRODUCTS:
      return {
        ...state,
        errorMsg: undefined,
        status: RequestStatus.LOADING,
      };

    case unavailableActions.LOAD_PRODUCTS_SUCCESS:
      availableProducts = new Map<number, Product>();
      unavailableProducts = new Map<number, Product>();

      // Ignore choosables
      let products: Product[] = new Array<Product>();
      for (const product of action.payload) {
        if (product.type !== 'choosable') {
          products.push(product);
        }
      }

      // Sort alphabetically
      products = products.sort((a: Product, b: Product) => {
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });

      // Separate unavailable products from available products.
      for (const product of products) {
        if (product.unavailable) {
          unavailableProducts.set(product.numericalId, product);
        } else {
          availableProducts.set(product.numericalId, product);
        }
      }

      return {
        ...state,
        availableProducts,
        filteredAvailableProducts: new Map<number, Product>(availableProducts),
        unavailableProducts,
        filteredUnavailableProducts: new Map<number, Product>(unavailableProducts),
        errorMsg: undefined,
        status: RequestStatus.SUCCESS,
      };

    case unavailableActions.LOAD_PRODUCTS_FAIL:
      return {
        ...state,
        errorMsg: 'Erro ao carregar a lista de produtos.',
        status: RequestStatus.FAIL,
      };

    case unavailableActions.CHANGE_PRODUCTS_AVAILABILITY_SUCCESS:
      availableProducts = cloneDeep(state.availableProducts);
      unavailableProducts = cloneDeep(state.unavailableProducts);
      filteredAvailableProducts = cloneDeep(state.filteredAvailableProducts);
      filteredUnavailableProducts = cloneDeep(state.filteredUnavailableProducts);

      // Add or remove each product from the available / unavailable product lists.
      const productAvailabilityArray: ProductAvailability[] = action.payload;
      for (const product of productAvailabilityArray) {
        let newProduct: Product;

        if (availableProducts.has(product.product)) {
          newProduct = availableProducts.get(product.product);
          newProduct.unavailable = product.unavailable;

          // Move the product from available to unavailable
          availableProducts.delete(newProduct.numericalId);
          unavailableProducts.set(newProduct.numericalId, newProduct);

          // Handle filtered products
          if (filteredAvailableProducts.has(product.product)) {
            filteredAvailableProducts.delete(newProduct.numericalId);
            filteredUnavailableProducts.set(newProduct.numericalId, newProduct);
          }
        } else if (unavailableProducts.has(product.product)) {
          newProduct = unavailableProducts.get(product.product);
          newProduct.unavailable = product.unavailable;

          // Move the product from unavailable to available
          unavailableProducts.delete(newProduct.numericalId);
          availableProducts.set(newProduct.numericalId, newProduct);

          // Handle filtered products
          if (filteredUnavailableProducts.has(product.product)) {
            filteredUnavailableProducts.delete(newProduct.numericalId);
            filteredAvailableProducts.set(newProduct.numericalId, newProduct);
          }
        }
      }

      return {
        ...state,
        availableProducts,
        filteredAvailableProducts,
        unavailableProducts,
        filteredUnavailableProducts,
        errorMsg: state.errorMsg,
        status: state.status,
      };

    case unavailableActions.FILTER_PRODUCTS:
      if (!action.payload || action.payload === '') {
        filteredAvailableProducts = cloneDeep(state.availableProducts);
        filteredUnavailableProducts = cloneDeep(state.unavailableProducts);
      } else {
        filteredAvailableProducts = filterProductsByName(state.availableProducts, action.payload);
        filteredUnavailableProducts = filterProductsByName(state.unavailableProducts, action.payload);
      }

      return {
        ...state,
        filteredAvailableProducts,
        filteredUnavailableProducts,
      };

    default:
      return state;
  }
}

/**
 * Iterates over a product map and returns another map with all the products that contain a given string.
 */
function filterProductsByName(products: Map<number, Product>, name: string) {
  const filteredProducts = new Map<number, Product>();
  for (const p of Array.from(products.values())) {
    // Remove accents and captalization prior to comparison
    const normalizedName = diacritics.remove(name).toLowerCase();
    const normalizedProductName = diacritics.remove(p.name).toLowerCase();

    if (normalizedProductName.includes(normalizedName)) {
      filteredProducts.set(p.numericalId, p);
    }
  }

  return filteredProducts;
}
