<div class="interaction-popup">
  <div class="modal-header">
    <h4 class="modal-title">Conector Pede Pronto</h4>
  </div>
  <div class="modal-body">
    <p>Já está tudo pronto para receber os seus pedidos!</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="close()">Ok!</button>
  </div>
</div>
