import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { concatMap, tap, withLatestFrom } from 'rxjs/operators';

import * as connectorServiceActions from '../../actions/connector-service.action';
import * as State from './../../../app/reducers/app.state';
import * as sharedModuleFeature from '../../state/shared.feature';
import { ReportData } from './../../../report/model/report-data.model';
import { AstiMsg, AstiMsgTypes, AstiPrintReportMsg, AstiReimportOrderMsg } from './../../model/astilectron-message.model';
import { AstilectronService } from './../../services/astilectron/astilectron.service';

@Injectable()
export class AstilectronEffect {
  // ******** Print Orders ********
  @Effect()
  printOrder$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_ORDER),
    withLatestFrom(this.store$.select(sharedModuleFeature.isConnectorServiceReady)),
    concatMap(([action, isConnectorServiceAvailable]: [connectorServiceActions.PrintOrderAction, boolean]) => {
      if (!isConnectorServiceAvailable) {
        return of(new connectorServiceActions.PrintOrderFailAction(action.payload));
      }

      const msg: AstiMsg = { type: AstiMsgTypes.PRINT_ORDER, payload: action.payload };
      this.astiService.sendMessage(msg);
      return of(new connectorServiceActions.PrintOrderSuccessAction(action.payload));
    })
  );

  @Effect({ dispatch: false })
  printOrderSuccess$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_ORDER_SUCCESS),
    tap((action: connectorServiceActions.PrintOrderSuccessAction) => {
      this.toastr.success(`O pedido: ${action.payload.displayCode} foi enviado para impressão.`);
    })
  );

  @Effect({ dispatch: false })
  printOrderFail$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_ORDER_FAIL),
    tap((action: connectorServiceActions.PrintOrderFailAction) => {
      this.toastr.error(`Ocorreu um erro ao tentar imprimir o pedido: ${action.payload.displayCode}. Por favor tente novamente.`);
    })
  );

  // ******** Print Report ********
  @Effect()
  printReport$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_REPORT),
    withLatestFrom(this.store$.select(sharedModuleFeature.isConnectorServiceReady), this.store$.select(State.reportDataSelector)),
    concatMap(([action, isConnectorServiceAvailable, reportData]: [connectorServiceActions.PrintReportAction, boolean, ReportData]) => {
      if (!isConnectorServiceAvailable) {
        return of(new connectorServiceActions.PrintReportFailAction());
      }

      const msg: AstiPrintReportMsg = {
        type: AstiMsgTypes.PRINT_REPORT,
        payload: {
          startDate: action.payload.startDate.toISOString(),
          endDate: action.payload.endDate.toISOString(),
          total: reportData.total,
          payments: reportData.payments,
        },
      };
      this.astiService.sendMessage(msg);
      return of(new connectorServiceActions.PrintReportSuccessAction());
    })
  );

  @Effect({ dispatch: false })
  printReportSuccess$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_REPORT_SUCCESS),
    tap((action: connectorServiceActions.PrintReportSuccessAction) => {
      this.toastr.success('O relatório foi enviado para impressão.');
    })
  );

  @Effect({ dispatch: false })
  printReportFail$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.PRINT_REPORT_FAIL),
    tap((action: connectorServiceActions.PrintOrderFailAction) => {
      this.toastr.error('Ocorreu um erro ao enviar o relatório para impressão. Por favor tente novamente.');
    })
  );

  // ******** Reimport Order ********
  @Effect()
  reimportOrder$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.REIMPORT_ORDER),
    withLatestFrom(this.store$.select(sharedModuleFeature.isConnectorServiceReady)),
    concatMap(([action, isConnectorServiceAvailable]: [connectorServiceActions.ReimportOrderAction, boolean]) => {
      if (!isConnectorServiceAvailable) {
        return of(new connectorServiceActions.ReimportOrderFailAction(action.payload));
      }

      const msg: AstiReimportOrderMsg = {
        type: AstiMsgTypes.REIMPORT_ORDER,
        payload: {
          orderId: action.payload.numericalId,
        },
      };
      this.astiService.sendMessage(msg);
      return of(new connectorServiceActions.ReimportOrderSuccessAction(action.payload));
    })
  );

  @Effect({ dispatch: false })
  reimportOrderSuccess$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.REIMPORT_ORDER_SUCCESS),
    tap((action: connectorServiceActions.ReimportOrderSuccessAction) => {
      this.toastr.success(`O pedido: ${action.payload.displayCode} foi enviado para reimportação.`);
    })
  );

  @Effect({ dispatch: false })
  reimportOrderFail$: Observable<Action> = this.actions.pipe(
    ofType(connectorServiceActions.REIMPORT_ORDER_FAIL),
    tap((action: connectorServiceActions.ReimportOrderFailAction) => {
      this.toastr.error(`Ocorreu um erro ao enviar o pedido: ${action.payload.displayCode} para reimportação.`);
    })
  );

  constructor(
    private actions: Actions,
    private store$: Store<State.AppState>,
    private toastr: ToastrService,
    private astiService: AstilectronService
  ) {}
}
