import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, map, tap, timeout, withLatestFrom } from 'rxjs/operators';

import * as staffGetMemberActions from '../../actions/staff-get-member.action';
import * as state from '../../reducers/app.state';
import * as routerFeatureSelector from '../../reducers/app.feature';
import { AppState } from '../../reducers/app.state';
import { StaffGetMemberService } from '../../services/staff-get-member/staff-get-member.service';
import { MixpanelService } from './../../../shared/services/mixpanel/mixpanel.service';
import * as Sentry from '@sentry/browser';

@Injectable()
export class StaffGetMemberEffect {
  @Effect()
  sendCellphoneNumber$: Observable<Action> = this.actions.pipe(
    ofType(staffGetMemberActions.SEND_CELLPHONE_NUMBER),
    withLatestFrom(
      this.store$.select(routerFeatureSelector.staffGetMemberTimeoutSelector),
      this.store$.select(routerFeatureSelector.companyPosTokenSelector)
    ),
    concatMap(([action, timeoutValue, posToken]: [staffGetMemberActions.SendCellphoneNumberAction, number, string]) =>
      this.sendCellphoneNumber(action, timeoutValue, posToken)
    )
  );

  @Effect({ dispatch: false })
  sendCellphoneNumberSuccess$: Observable<Action> = this.actions.pipe(
    ofType(staffGetMemberActions.SEND_CELLPHONE_NUMBER_SUCCESS),
    tap(() => {
      this.mixpanel.trackEvent('staff-get-member-sucess');
      this.toastrService.success('Convite enviado com sucesso!');
    })
  );

  @Effect({ dispatch: false })
  sendCellphoneNumberFail$: Observable<Action> = this.actions.pipe(
    ofType(staffGetMemberActions.SEND_CELLPHONE_NUMBER_FAIL),
    tap((action: staffGetMemberActions.SendCellphoneNumberFailAction) => {
      this.mixpanel.trackEvent('staff-get-member-fail', { error: action.payload.message });

      let message: string;
      switch (action.payload.message) {
        case 'onyo.user.phone-already.exists':
          message = 'Esse telefone já está cadastrado no Pede Pronto.';
          break;

        case 'onyo.invalid-data':
          message = 'A sua loja está sem cupom de indicação. Por favor entre em contato com o nosso suporte.';
          break;

        default:
          message = `Erro ao convidar um usuário: ${action.payload.message}`;
          break;
      }

      this.toastrService.error(message, 'Falha ao enviar o convite.');
    })
  );

  constructor(
    private actions: Actions,
    private store$: Store<AppState>,
    private staffGetMemberService: StaffGetMemberService,
    private toastrService: ToastrService,
    private mixpanel: MixpanelService
  ) {}

  /**
   * Sends a cellphone number to the backend, so that the owner can receive a SMS message with the app's download link.
   */
  private sendCellphoneNumber(
    action: staffGetMemberActions.SendCellphoneNumberAction,
    timeoutValue: number,
    posToken: string
  ): Observable<staffGetMemberActions.SendCellphoneNumberSuccessAction | staffGetMemberActions.SendCellphoneNumberFailAction> {
    if (posToken) {
      this.mixpanel.trackEvent('staff-get-member', { cellphoneNumber: action.payload, token: posToken });

      return this.staffGetMemberService.sendCellphoneNumber(posToken, action.payload).pipe(
        timeout(timeoutValue),
        map(() => new staffGetMemberActions.SendCellphoneNumberSuccessAction()),
        catchError((error) => this.handleError(error))
      );
    } else {
      const errorMsg = 'No company token selected.';
      Sentry.addBreadcrumb({ message: errorMsg, category: 'http', level: Sentry.Severity.Warning });
      return observableOf(new staffGetMemberActions.SendCellphoneNumberFailAction(new Error(errorMsg)));
    }
  }

  private handleError(error): Observable<staffGetMemberActions.SendCellphoneNumberFailAction> {
    if (error.name === 'TimeoutError') {
      console.warn('Timeout error while sending SGM message', error);
      return observableOf(new staffGetMemberActions.SendCellphoneNumberFailAction(error));
    }

    const errorMsg = 'Error sending SGM';
    console.error(errorMsg, error);

    const err: any = new Error(errorMsg);
    err.innerException = error;
    err.error = error.error;
    err.headers = error.headers;
    err.JSON = JSON.stringify(error);
    Sentry.captureException(err);

    const returnError = new Error();
    returnError.name = 'SGMError';
    if (error.error) {
      returnError.message = error.error.error;
    } else {
      returnError.message = 'Error sending SGM to backend';
    }

    return observableOf(new staffGetMemberActions.SendCellphoneNumberFailAction(returnError));
  }
}
