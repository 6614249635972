import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared/shared.module';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { ReportEffect } from './effects/report/report.effect';
import { reportReducer } from './reducers/report/report.reducer';
import { ReportComponent } from './report.component';
import { CustomDatePickerI18nService } from './services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { NgbBRDateParserService } from './services/ngb-br-date-parser/ngb-br-date-parser.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgbModule,
    StoreModule.forFeature('report', reportReducer),
    EffectsModule.forFeature([ReportEffect]),
    PerfectScrollbarModule,
    FontAwesomeModule,
  ],
  declarations: [ReportComponent, ReportViewComponent, ReportTableComponent],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbBRDateParserService },
    { provide: NgbDatepickerI18n, useClass: CustomDatePickerI18nService },
  ],
  exports: [ReportComponent],
})
export class ReportModule {}
