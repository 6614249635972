import { Action } from '@ngrx/store';

import { ReportData } from '../model/report-data.model';
import { RequestStatus } from './../../shared/model/request-status.model';

export const LOAD_REPORT = 'load-report';
export const LOAD_REPORT_SUCCESS = 'load-report-success';
export const LOAD_REPORT_FAIL = 'load-report-fail';
export const LOAD_CSV_REPORT = 'load-csv-report';
export const LOAD_CSV_REPORT_SUCCESS = 'load-csv-report-success';
export const LOAD_CSV_REPORT_FAIL = 'load-csv-report-fail';
export const SET_REPORT_STATUS = 'set-report-status';

/**
 * Tries to load the report data.
 * Payload: start and end date of the report.
 */
export class LoadReportAction implements Action {
  readonly type = LOAD_REPORT;
  constructor(public payload: { startDate: Date; endDate: Date }) {}
}

/**
 * This action is called when the report data is successfully loaded.
 * Payload: array of ReportData object.
 */
export class LoadReportSuccessAction implements Action {
  readonly type = LOAD_REPORT_SUCCESS;
  constructor(public payload: ReportData) {}
}

/**
 * This action is called when there is a problem loading the report data.
 * Paylaod: Error.
 */
export class LoadReportFailAction implements Action {
  readonly type = LOAD_REPORT_FAIL;
  constructor(public payload: Error) {}
}

/**
 * Tries to load the CSV report data.
 * Payload: start and end date of the report.
 */
export class LoadCSVReportAction implements Action {
  readonly type = LOAD_CSV_REPORT;
  constructor(public payload: { startDate: Date; endDate: Date }) {}
}

/**
 * This action is called when the report data is successfully loaded.
 * Payload: csv data.
 */
export class LoadCSVReportSuccessAction implements Action {
  readonly type = LOAD_CSV_REPORT_SUCCESS;
  constructor(public payload: string) {}
}

/**
 * This action is called when there is a problem loading the report data.
 * Paylaod: Error.
 */
export class LoadCSVReportFailAction implements Action {
  readonly type = LOAD_CSV_REPORT_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action sets the report status.
 * Payload: RequestStatus
 */
export class SetReportStatusAction implements Action {
  readonly type = SET_REPORT_STATUS;
  constructor(public payload: RequestStatus) {}
}

export type Actions =
  | LoadReportAction
  | LoadReportSuccessAction
  | LoadReportFailAction
  | LoadCSVReportAction
  | LoadCSVReportSuccessAction
  | LoadCSVReportFailAction
  | SetReportStatusAction;
