import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { MixpanelService } from '../shared/services/mixpanel/mixpanel.service';
import * as companyActions from './actions/company.action';
import * as orderActions from './actions/order.action';
import * as state from './reducers/app.state';
import { NotificationService } from './services/notification/notification.service';
import { QueryStringService } from './services/query-string/query-string.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  navigationEnd: Observable<NavigationEnd>;

  constructor(
    private store$: Store<state.AppState>,
    private mixpanel: MixpanelService,
    private notificationService: NotificationService,
    private queryStringService: QueryStringService
  ) {}

  ngOnInit() {
    this.mixpanel.init();
    this.notificationService.requestPermission();

    const isEmbedded = this.queryStringService.parseBoolean('embedded', location.search);
    if (!isEmbedded) {
      this.store$.dispatch(new companyActions.LoadCompanyFromCookieAction());
    }

    this.store$.dispatch(new orderActions.LoadAllOrdersAction());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
