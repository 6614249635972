import { Action } from '@ngrx/store';

export const LOGIN_USER = 'login-user';
export const LOGIN_USER_SUCCESS = 'login-user-success';
export const LOGIN_USER_FAIL = 'login-user-fail';
export const DISPLAY_LOGIN_COMPONENT = 'display-login-component';

/**
 * Tries to login a given user.
 * Payload: string - username, string - password.
 */
export class LoginUserAction implements Action {
  readonly type = LOGIN_USER;
  constructor(public payload: { username: string; password: string }) {}
}

/**
 * This action is called when the login is sucessful.
 * Payload: string - token - contains the user token that is returned by the backend.
 */
export class LoginUserSuccessAction implements Action {
  readonly type = LOGIN_USER_SUCCESS;
  constructor(public payload: { token: string }) {}
}

/**
 * This action is called when the login fails.
 * Payload: Error.
 */
export class LoginUserFailAction implements Action {
  readonly type = LOGIN_USER_FAIL;
  constructor(public payload: Error) {}
}

export class DisplayLoginComponentAction implements Action {
  readonly type = DISPLAY_LOGIN_COMPONENT;
  constructor() {}
}

export type Actions = LoginUserAction | LoginUserSuccessAction | LoginUserFailAction | DisplayLoginComponentAction;
