import { Product } from './product.model';

/**
 * This class is used as a model to generate a JSON that is sent to the API to
 * in order to change a product's availability.
 */
export class ProductAvailability {
  product: number;
  unavailable: boolean;

  constructor(product?: Product) {
    if (product) {
      this.product = product.numericalId;
      this.unavailable = product.unavailable;
    }
  }
}
