import { environment } from '../../../environments/environment';
import { User } from '../../model/user.model';
import * as loginActions from '../../actions/login.action';
import { validateUser } from './login.validate';

export interface LoginState {
  user: User;
  timeout: number;
  authenticated: boolean;
  errorMsg: string;
}

const initialState: LoginState = {
  user: {
    username: '',
    token: '',
    isOnyoca: false,
  },
  timeout: environment.defaultTimeout,
  authenticated: false,
  errorMsg: undefined,
};

/**
 * This reducer handles the login of the user.
 */
export function loginReducer(state: LoginState = initialState, action: loginActions.Actions): LoginState {
  switch (action.type) {
    case loginActions.LOGIN_USER:
      return {
        ...state,
        user: {
          username: action.payload.username,
          token: '',
          isOnyoca: validateUser(action.payload.username, ['@onyo.com', '@dextra-sw.com', '@pedepronto.com.br']),
        },
        errorMsg: '',
      };

    case loginActions.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.token,
        },
        authenticated: true,
        errorMsg: '',
      };

    case loginActions.LOGIN_USER_FAIL:
      let msg: string;
      const error = action.payload;

      // User firendly error message.
      if (error.message.includes('onyo.user.invalid-data')) {
        msg = 'Erro ao realizar login. Usuário ou senha inválidos';
      } else {
        msg = 'Erro ao realizar login. Por favor tente novamente mais tarde.';
      }

      return { ...state, authenticated: false, errorMsg: msg };

    default:
      return state;
  }
}
