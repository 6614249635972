import { Action } from '@ngrx/store';

import { OrderDetails } from './../../shared/model/order-details.model';
import { Order } from './../../shared/model/order.model';
import { OpenOrderActionPopupModel } from './../model/open-order-action-popup.model';

export const LOAD_ALL_ORDERS = 'load-all-orders';
export const LOAD_ALL_ORDERS_COMPLETE = 'load-all-orders-complete';
export const LOAD_ALL_ORDERS_FAIL = 'load-all-orders-fail';
export const UPDATE_ORDER_STATUS = 'update-order-status';
export const UPDATE_ORDER_STATUS_SUCCESS = 'update-order-status-success';
export const UPDATE_ORDER_STATUS_FAIL = 'update-order-status-fail';
export const RESEND_ORDER_READY_PUSH = 'resend-order-read-push';
export const RESEND_ORDER_READY_PUSH_SUCCESS = 'resend-order-read-push-success';
export const RESEND_ORDER_READY_PUSH_FAIL = 'resend-order-read-push-fail';
export const OPEN_ORDER_ACTION_POPUP = 'open-order-action-popup';
export const SET_POLLING_INTERVAL = 'set-polling-interval';

/**
 * Loads all orders since certain date.
 * Payload: N/A
 */
export class LoadAllOrdersAction implements Action {
  readonly type = LOAD_ALL_ORDERS;
  constructor() {}
}

/**
 * This action is called when all the orders have been lodaded successfully.
 * Payload: Orders - all orders that were retrieved from the backend indexed by their status.
 */
export class LoadAllOrdersCompleteAction implements Action {
  readonly type = LOAD_ALL_ORDERS_COMPLETE;
  constructor(public payload: Map<number, any>) {}
}

/**
 * This action is called when the load orders fails.
 * Paylod: Error
 */
export class LoadAllOrdersFailAction implements Action {
  readonly type = LOAD_ALL_ORDERS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action is called to update the status of a given order.
 * Payload: { nextStatus: number, order: Order } - contains the next status and the
 * order that will be updated.
 */
export class UpdateOrderStatusAction implements Action {
  readonly type = UPDATE_ORDER_STATUS;
  constructor(public payload: { nextStatus: number; order: Order | OrderDetails }) {}
}

/**
 * This action is called when the status update of an order is successful.
 * Payload: Order - the updated order
 */
export class UpdateOrderStatusSuccessAction implements Action {
  readonly type = UPDATE_ORDER_STATUS_SUCCESS;
  constructor(public payload: Order) {}
}

/**
 * This action is called when the status update of an order fails.
 * Payload: error
 */
export class UpdateOrderStatusFailAction implements Action {
  readonly type = UPDATE_ORDER_STATUS_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action tries to resend the order ready push for a given order.
 * Payload: number - order ID.
 */
export class ResendOrderReadyPushAction implements Action {
  readonly type = RESEND_ORDER_READY_PUSH;
  constructor(public payload: number) {}
}

/**
 * This action is called when the resend order ready push succeeds.
 */
export class ResendOrderReadyPushSuccessAction implements Action {
  readonly type = RESEND_ORDER_READY_PUSH_SUCCESS;
  constructor() {}
}

/**
 * This action is called when the resend order ready push fails.
 */
export class ResendOrderReadyPushFailAction implements Action {
  readonly type = RESEND_ORDER_READY_PUSH_FAIL;
  constructor(public payload: Error) {}
}

/**
 * This action opens an order action popup.
 * Payload: OpenOrderActionPopopupModel
 */
export class OpenOrderActionPopupAction implements Action {
  readonly type = OPEN_ORDER_ACTION_POPUP;
  constructor(public payload: OpenOrderActionPopupModel) {}
}

/**
 * Sets the polling interval.
 * Payload: number - the polling interval.
 */
export class SetPollingIntervalAction implements Action {
  readonly type = SET_POLLING_INTERVAL;
  constructor(public payload: number) {}
}

export type Actions =
  | LoadAllOrdersAction
  | LoadAllOrdersCompleteAction
  | LoadAllOrdersFailAction
  | UpdateOrderStatusAction
  | UpdateOrderStatusSuccessAction
  | UpdateOrderStatusFailAction
  | SetPollingIntervalAction
  | ResendOrderReadyPushAction
  | ResendOrderReadyPushSuccessAction
  | ResendOrderReadyPushFailAction
  | OpenOrderActionPopupAction;
